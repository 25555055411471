import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../components/slick/main/topBtnSlick/slickSlider.css";
import { getCookie } from "../../../../util/cookie/cookies";
export interface Props {onClose : Function}
const TopSlickSlider:React.FC<Props> = ({onClose}) => {
    const lang = getCookie('MARINELANG')
    const setting = {
        dots : false,
        infinte : true,
        speed : 500,
        slidesToShow: 1,
        // centerMode: true,
        arrow : false,
        autoplay : true, 
        autoplaySpeed : 3000,
    }

    return(
        <>
        <div className="top_header">
            <div className="carousel">
                <Slider {...setting}>
                    <div>
                        <div className="header_top_slider" style={{position : 'relative'}}>
                        {/* <!-- 반복구간 --> */}
                            {/* <button className="top_header_closeBtn">
                                <img src="/assets/images/top_header_closeBtn.png" alt="close button"/>
                            </button> */}
                            <div className="top_slider">
                                <a href="">
                                    {lang === 'kr' ? 
                                    <>마린플라자 신규 회원가입 시 <span>초경량 웹.3D 모델링 무료 컨버전</span></>:
                                    <><span>Lightweight web-based 3D modeling conversion</span> for free upon new registration at Marine Plaza.</>
                                    }
                                </a>
                            </div>
                        {/* <!-- 반복구간 Fin --> */}
                        </div>
                    </div>
                    <div>
                        <div className="header_top_slider" style={{position : 'relative'}}>
                        {/* <!-- 반복구간 --> */}
                            <div className="top_slider">
                                <a href="">
                                    {lang === 'kr' ? 
                                    <>마린플라자 신규 회원가입 시 <span>초경량 웹.3D 모델링 무료 컨버전</span></>:
                                    <><span>Lightweight web-based 3D modeling conversion</span> for free upon new registration at Marine Plaza.</>
                                    }
                                </a>
                            </div>
                        {/* <!-- 반복구간 Fin --> */}
                        </div>
                    </div>
                </Slider>

                <button className="top_header_closeBtn" onClick={()=>onClose()}>
                    <img src="/assets/images/top_header_closeBtn.png" alt="close button"/>
                </button>
                
            </div>
        </div>
        </>
    )
}

export default TopSlickSlider;