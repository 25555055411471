import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export interface Props {type : any, count:number , responsive : boolean}
const Filter:React.FC<Props> = ({type,count, responsive}) => {

    const [title, setTitle] = useState<{first : string, second : string}>({
        first : '' , second : ''
    });
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search)
    const keyword : any = searchParam.get('keyword')||'';
    const pathname = location.pathname;
    const path = pathname.split('/')
    const {contentsType} = useParams()
    const [isSortBoxOpen, setIsSortBoxOpen] = useState<boolean>(false)
    const handleSortBoxOpen = () => {
        if(responsive === true){
            setIsSortBoxOpen(prev => !prev)
        }
    }
    console.log(contentsType)
    useLayoutEffect(() => {
        if(path[1]==='contents'&&path[2]===contentsType) {setTitle((prevState) => ({ ...prevState, first: 'Showing' }));}
        if(path[2]==='collection'&&path[3]===contentsType) {setTitle((prevState) => ({...prevState, first : 'Saved'}))}
        
        if(contentsType === '206' || path[2]==='products'){ setTitle((prevState) => ({ ...prevState, second: '3D Product & Components' })); }
        if(contentsType === '207' || path[2]==='news'){ setTitle((prevState) => ({ ...prevState, second: 'Marine News' })); }
        if(contentsType === '208'){ setTitle((prevState) => ({ ...prevState, second: 'Catalogue' })); }
        if(contentsType === '209'){ setTitle((prevState) => ({ ...prevState, second: 'Video' })); }
        if(contentsType === '210' || path[2]==='techDoc'){ setTitle((prevState) => ({ ...prevState, second: 'Tech. Document' })); }
        if(contentsType === '211' || path[2]==='supplier'){ setTitle((prevState) => ({ ...prevState, second: 'Supplier' })); }
        
    }, [location]);

    return(
        <>
        <div className="sortWrap">
            <h2>{title.first} {title.second} {keyword !== '' || keyword === undefined ? 'for' : ''} {keyword !=='' ? <strong>{keyword}</strong> : ''}</h2>
            <div>
                {location.pathname === `/contents/${contentsType}`? <span>{count} Total</span> : ''}
                <span>Sort by:</span>
                <div className={isSortBoxOpen===true?'sortBox active' : 'sortBox'} onClick={handleSortBoxOpen}>
                    <span>Latest first</span>
                    <div className="sortHiddenBox">
                        <span>Sorter</span>
                        <ul>
                            <li>
                                <input type="checkbox" name="" id="latest_first" className="blueInput"/>
                                <label htmlFor="latest_first" className="blueLabel">Latest first</label>
                            </li>
                            <li>
                                <input type="checkbox" name="" id="oldest_first" className="blueInput"/>
                                <label htmlFor="oldest_first" className="blueLabel">Oldest_first</label>
                            </li>
                            <li>
                                <input type="checkbox" name="" id="view_count" className="blueInput"/>
                                <label htmlFor="view_count" className="blueLabel">View count</label>
                            </li>
                            <li>
                                <input type="checkbox" name="" id="random" className="blueInput"/>
                                <label htmlFor="random" className="blueLabel">Random</label>
                            </li>
                        </ul>
                        <div className="toggleBtn_wrap">
                            <span className="toggleSwitch">
                                <input type="checkbox" id="toggle_sample" className="toggleIpt" hidden/> 
                                <label htmlFor="toggle_sample" className="toggleSwitch">
                                    <span className="toggleButton"></span>
                                </label>
                            </span>
                            <label htmlFor="toggle_sample">Sorting saved content first</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Filter;