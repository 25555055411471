import React, { useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const FindId = () => {

    const nameRef = useRef<any>()
    const phoneRef = useRef<any>()

    const findId = (e:React.MouseEvent<HTMLButtonElement>) => {
        if(!nameRef.current.value || !phoneRef.current.value){
            toast.error("이름 및 휴대전화를 확인해 주세요.")
        } else {
            // Find Id Api
            const param ={
                name : nameRef.current?.value, phone : phoneRef.current?.value
            }
        }
    }

    return(
        <>
        <Toaster position="bottom-right" toastOptions={{duration: 2000, style : {backgroundColor : 'black', color : 'white', fontSize : '15px', top : '580px'}}}/>
        <div className="mobileContentBox mobile_common mobile_signUp">
            <div>
                <div className="titleBox">
                    <h2>아이디 찾기</h2>
                    <p>회원가입 시 등록하신 정보를 입력해 주세요.</p>
                </div>
                <div className="formBox">
                    <div>
                        <div className="inputList">
                            <label htmlFor="">이름 <span>*</span></label>
                            <input type="text" ref={nameRef} name="" id="" placeholder="이름을 입력해 주세요."/>
                        </div>
                        <div className="inputList">
                            <label htmlFor="">휴대전화 <span>*</span></label>
                            <input type="text" ref={phoneRef} name="" id="" placeholder="하이픈(-)을 제외한 전화번호를 입력해 주세요."/>
                        </div>
                    </div>
                    <button className="findId_completeBtn blue_completeBtn" onClick={findId}>확인</button>
                </div>
            </div>
        </div>

        {/* <div className="findIdSuccess_popUp findSuccess_popUp">
            <div>
                <p>
                    <span>입력하신 정보로 조회된 아이디는</span>
                    <strong className="blue">aa98**@bmcnet.kr</strong> 입니다.
                </p>
                <div className="btnBox_twin">
                    <button className="gray">비밀번호 찾기</button>
                    <button className="blue">로그인</button>
                </div>
            </div>
        </div> */}
        {/* <!-- 아이디 찾기 성공했을 때 --> */}

        {/* <div className="findIdFail_popUp findSuccess_popUp">
            <div>
                <p>
                    <span>입력하신 정보와 일치하는 아이디가 없습니다.</span>
                    <strong className="black">가입 시 입력하신 정보가 맞는지 다시 한 번 확인해 주세요.</strong>
                </p>
                <div className="btnBox_single">
                    <button className="blue">확인</button>
                </div>
            </div>
        </div> */}
        {/* <!-- 아이디 찾기 실패했을 때 --> */}
        </>
    )
}

export default FindId;