import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TagBox } from "../../../../../../components/contents/tagBox";
import VideoView from "../../../../view/videoView";
import PageStyles from '../../../css/page.module.css'
export interface Props {data: any; name : string}
export const NewsAndVideo:React.FC<Props> = ({data, name}) => {
    const [viewData, setViewData] = useState<any>({
        isActive : false, videoUrlKr : '', videoUrlEn : '', videoSubjectEn : ''
    })
    const navigate = useNavigate()
    const handleTransView = (data:any) => {
        if(name==='news'){navigate(`/contents/news/207/${data.promIdx}`)}
        else {setViewData({
            ...viewData, isActive : true, videoUrlKr: data?.promVideoUrlKr, videoUrlEn: data?.promVideoUrlEn, videoSubjectEn : data?.promSubjectEn
        })}// 비디오
    }
    const viewClose = () => {setViewData({isActive : false, videoUrlKr : '' , videoUrlEn : '', subjectKr : ''})}
    return(
    <>
    <div className={[PageStyles.content, PageStyles.content_1, PageStyles.active].join(" ")}>
        <ul className={[PageStyles.listType_all, PageStyles.listType_2equalParts, PageStyles.listType_b].join(" ")}>
            {data?.map((data:any) => (
            <li>
                {/* <!-- 리스트 우측 상단 글씨(현재는 없어도 됨. 광고가 붙을 시 생김) --> */}
                <div>
                    <div className={PageStyles.scaleImgBox} onClick={()=>handleTransView(data)}>
                        <img src={`https://marineplaza.org${data?.promThumnailImage}`} alt="contents_list"/>
                    </div>
                    {/* <!-- hover시 커지는 이미지 박스 --> */}

                    <div className={PageStyles.bottomBox}>
                        <div className={PageStyles.contentSaveBox_circle}>
                            <button className={PageStyles.contentSaveBtn}>
                                <span></span>
                            </button>
                            <span>콘텐츠 저장하기</span>
                            {/* <!-- hover 시 보이는 버튼, 툴팁 --> */}
                        </div>
                        {/* <!-- 콘텐츠저장 버튼 박스 --> */}
                        <a onClick={()=>handleTransView(data)}>{data?.promSubjectEn}</a>
                        {/* <!-- 제목 - 말줄임처리(css) --> */}
                        <div className={PageStyles.hashTagBox}>
                            <TagBox type={'207'} tags={data?.promTags}/>
                        </div>
                        {/* <!-- 해시태그 박스 --> */}
                        <div className={PageStyles.dateBox}>
                            <div>
                                <span className={PageStyles.icon} style={{backgroundColor:data?.companyBrandColor}}>
                                    <img src={`https://marineplaza.org${data?.companyLogoImage}`} alt=""/>
                                </span>
                                <span>{data?.companyNameEn}</span>
                            </div>
                            {/* <!-- 로고, 회사명 박스 --> */}
                            <span className={PageStyles.date}>{data?.promCreationDate}</span>
                            {/* <!-- 날짜 --> */}
                        </div>
                        {/* <!-- 로고, 날짜 박스 --> */}
                    </div>
                    {/* <!-- 이미지 아래 흰배경 박스 --> */}
                </div>
            </li>
            ))}
        </ul>
    </div>
    {viewData.isActive ? 
        <VideoView videoUrlkr={viewData.videoUrlKr} subjectKr={viewData.videoSubjectEn} onClose={viewClose}/> 
    : ''}
    </>
    )
}