import React, { useEffect, useRef } from "react";
import PageStyles from '../css/page.module.css';
export interface Props {menuList:any;onClose:Function, setPageIdx:any}
export const PopupHeader:React.FC<Props> = ({menuList,onClose,setPageIdx}) => {
    const listRef = useRef<any>([])
    const handleSelect = (list:any) => {
        Object.values(listRef?.current).forEach((ref:any) => {ref?.classList.remove(PageStyles.active)})
        listRef.current?.[list.idx].classList.add(PageStyles.active); setPageIdx(list.idx)
    }
    useEffect(()=>{
        const initActive = menuList.find((active:any) => active.idx === 1);
        if(initActive){listRef.current?.[initActive.idx].classList.add(PageStyles.active)}
    }, [])

    return(
        <>
        <div>
            <ul className={PageStyles.iconBox}>
                {menuList?.map((list:{idx:number,title:string})=>(
                <li ref={(e)=>listRef.current[list.idx]=e} className={[PageStyles[`iconList_${list.idx}`]].join(" ")} key={list.idx} onClick={()=>handleSelect(list)}>
                    <div><span className={PageStyles.icon}></span></div>
                    <span className={PageStyles.txt}>{list.title}</span>
                </li>
                ))}
            </ul>
        </div>
        <button className={PageStyles.popUpClose_btn} onClick={()=>onClose()}> 
            <img src="/assets/images/modelingCtrl/popUpClose_btn.png" alt="close btn"/>
        </button>
        </>
    )
}