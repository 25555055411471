import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { CatalogueView } from "../../../pages/contents/view/catalogueView";
import { FlipBook } from "../../flipbook/flipBook";
import { MoreBtn } from "./more-btn";
export interface Props {name:string; data :any}
export const HeightUnit:React.FC<Props> = ({name, data}) => {
    const navigate = useNavigate()
    const [flipBook, setFlipBook] = useState<any>({
        isActive : false, id : ''
    })
    const handleTransView = (idx:string) => {
        if(name==='Tech. Document'){navigate(`/contents/techDoc/210/${idx}`)}
        else {
            setFlipBook({isActive : true, id : idx})
        } // 카탈로그 뷰 페이지로 이동
    }
    const handleClose = () => {setFlipBook({isActive :false, id : ''})}
    return(
        <>
        <div>
            {data?.length === 0 ? '' : <MoreBtn name={name} data={data} displayCnt={3}/>}
            <ul className="listType_all listType_3equalParts listType_c">
                {data?.map((list:any) => (
                <li key={list?.promIdx} onClick={()=>handleTransView(list?.promIdx)}>
                    <a></a>
                    <div>
                        <div className="scaleImgBox">
                            <img src={`https://marineplaza.org${list?.promThumnailImage}`} alt="contents_list"/>
                        </div>

                        <div className="bottomBox">
                            <div className="contentSaveBox_circle">
                                <button className="contentSaveBtn">
                                    <span></span>
                                </button>
                                <span>콘텐츠 저장하기</span>
                            </div>
                            <p>
                                <span>{list?.companyNameKr}</span>
                                {list?.promSubjectKr}
                            </p>
                        </div>
                    </div>
                </li>
                ))}
            </ul>
            {flipBook.isActive && <CatalogueView contentId={flipBook.id} onClose={handleClose}/>}
        </div>
        </>
    )
}
export interface Props2 {data : any; name:string}
export const HeightMoreUnit:React.FC<Props2> = ({data,name}) => {
    const navigate = useNavigate()
    const [flipBook, setFlipBook] = useState<any>({
        isActive : false, id : ''
    })
    const handleTransView = (idx:string) => {
        if(name==='Tech. Document'){navigate(`/contents/techDoc/210/${idx}`)}
        else {setFlipBook({isActive : true, id : idx})} // 카탈로그 뷰 페이지로 이동
    }
    const handleClose = () => {setFlipBook({isActive :false, id : ''})}
    return(
        <>
        <div>
            <div>
                <h4>{name}</h4>
            </div>
            <ul className="listType_all listType_3equalParts listType_c">
                {data?.map((data:any) => (
                <li key={data?.promIdx} onClick={()=>handleTransView(data?.promIdx)}>
                    <div>
                        <div className="scaleImgBox">
                            <img src={`https://marineplaza.org${data?.promThumnailImage}`} alt="contents_list"/>
                        </div>

                        <div className="bottomBox">
                            <div className="contentSaveBox_circle">
                                <button className="contentSaveBtn">
                                    <span></span>
                                </button>
                                <span>콘텐츠 저장하기</span>
                            </div>
                            <p>
                                <span>{data?.companyNameEn}</span>
                                {data?.promSubjectEn}
                            </p>
                        </div>
                    </div>
                </li>
                ))}
            </ul>
            {flipBook.isActive && <CatalogueView contentId={flipBook.id} onClose={handleClose}/>}
        </div>
        </>
    )
}