import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { GoogleMaps } from "../../../components/google-map/googleMaps";
import { styles } from "../../../components/select/mainSectionSelector";
import { getCookie } from "../../../util/cookie/cookies";

const Section1 = () => {
    const {t} = useTranslation()
    const lang = getCookie('MARINELANG')
    const searchRef = useRef<any>();
    const navi = useNavigate()
    const [isCancleBtn, setIsCancleBtn] = useState<boolean>(false);
    const [contentsType, setContentsType] = useState<any>('')
    const options = [
        {value : '206', label : '3D Product & Component'},
        {value : '207', label : 'Marine News'},
        {value : '208', label : 'Catalogue'},
        {value : '209', label : 'Video'},
        {value : '210', label : 'Tech. Document'},
        {value : '211', label : 'Supplier'},
    ]
    // onChange Evnet
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = searchRef.current.value;
        if(value.length > 0){setIsCancleBtn(true)}
        else{setIsCancleBtn(false)}
    }
    const handleSelect = (e:any) => {
        console.log(e.value)
        setContentsType(e.value)
    }
    // Click Event
    const handleSearch = (e : any) => {
        if(contentsType === ''){
            alert(lang === 'kr' ? '검색할 콘텐츠를 선택해 주시기 바랍니다.' : 'Please select the content you want to search for.')
        }else {
            navi(`/contents/${contentsType}?page=1&keyword=${searchRef.current.value}&sortOrder=desc&sortColumn=date&tags=`)
        }
    }
    // Enter Event
    const handleSearchEnter = (e : React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter'){
            handleSearch(e)
        }
    }

    const handleCancle = (e:React.MouseEvent<HTMLButtonElement>) => {
        searchRef.current.value = "";
        setIsCancleBtn(false)
    }

    return(
        <>
        <section className="main_section main_section1 colorBG">
            <div>
                <div>
                    <h2>Look for data related to the <span>shipbuilding!</span></h2>
                    <p>
                        <strong>{t("main_section_1_1")}</strong>
                        {t("main_section_1_2")}
                    </p>
                    <div className="main_searchWrap">

                        <Select 
                            onChange={handleSelect}
                            options={options}
                            placeholder={lang === 'kr' ? '콘텐츠 선택' : 'Select Contents'}
                            isSearchable={false}
                            styles={styles}
                        />

                        <div className="searchBox">
                            <input type="text" ref={searchRef} onKeyPress={handleSearchEnter} onChange={handleChange} placeholder="Search"/>
                            <div className="search_btnBox">
                                {isCancleBtn && (
                                <button type="button" className="search_closeBtn" onClick={handleCancle}>
                                    <img src="/assets/images/search_closeBtn.png" alt="닫기버튼"/>
                                </button>
                                )}
                                
                                <button type="button" className="search_btn" onClick={handleSearch}>
                                    <img src="/assets/images/search_icon.png" alt="검색아이콘"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Section1;