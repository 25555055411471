import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TagBox } from "../../../components/contents/tagBox";
import Paging from "../../../components/pagination/pagination";
import { getCookie } from "../../../util/cookie/cookies";
export interface Props {newsList:any}
const MarinNews:React.FC<Props> = ({newsList}) => {
    const lang = getCookie("MARINELANG");
    const navi = useNavigate();
    const location = useLocation()
    const {contentsType} = useParams();
    const pathname = location.pathname;

    const [imageLoad, setImageLoad] = useState<boolean>(false)
    const handleImageLoad = () => {
        setImageLoad(true)
    }
    // Transfer Detail Page
    const goView = (e:React.MouseEvent, id : number) => {
        e.preventDefault()
        if(pathname === `/contents/${contentsType}`){
            navi(`/contents/news/${contentsType}/${id}`)
        } else {
            navi(`/mypage/collection/news`)
        }
    }
    
    return(
        <>
        <ul className="listType_all listType_3equalParts listType_b">
            {newsList?.map((data : any) => {
                return(
                <li key={data.promIdx}>
                    {/* <span>AD</span> */}
                    <div>
                        <div className="scaleImgBox" onClick={(e)=>goView(e, data.promIdx)}>
                            {newsList && <img src={`https://marineplaza.org${data.promThumnailImage}`} alt="contents_list"/>}
                        </div>

                        <div className="bottomBox">
                            <div className="contentSaveBox_circle">
                                <button className="contentSaveBtn">
                                    <span></span>
                                </button>
                                <span>콘텐츠 저장하기</span>
                            </div>
                            <a href="" onClick={(e)=>goView(e, data.promIdx)}>{lang==='kr'?data.promSubjectKr:data.promSubjectEn}</a>
                            <div className="hashTagBox">
                                <p>
                                    <TagBox type={'207'} tags={data?.promTags}/>
                                </p>
                                
                            </div>
                            <div className="dateBox">
                                <div>
                                    <span className="icon" style={{backgroundColor:data?.companyBrandColor}}>
                                        <img src={`https://marineplaza.org${data.companyLogoImage}`} alt=""/>
                                    </span>
                                    {lang === 'kr' ? 
                                    <span>{data?.companyNameKr?.length <= 27 ? data?.companyNameKr : data?.companyNameKr?.slice(0,27)+"..."}</span> :
                                    <span>{data?.companyNameEn?.length <= 27 ? data?.companyNameEn : data?.companyNameEn?.slice(0,27)+"..."}</span>
                                    }
                                </div>
                                <span className="date">{data?.promCreationDate}</span>
                            </div>
                        </div>
                    </div>
                </li>
                )
            })}
        </ul>
        </>
    )
}

export default MarinNews;