import React from "react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const userApi : any = createApi({
    reducerPath : 'userApi',
    baseQuery : fetchBaseQuery({baseUrl : '',}),

    endpoints : (builder : any) => ({

        loginUser : builder.mutation({
            query : (body : any) => {
                return{
                    url : 'http://localhost:3000/api/controller/user/login.php',
                    method : 'post',
                    body
                }
            }
        })
        
    })
})

export const {useLoginUserMutation} = userApi;