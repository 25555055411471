import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GoogleMaps } from "../../../../../components/google-map/googleMaps";
import { useGetSupplierDetailMutation } from "../../../../../store/slices/contents/contentsApiSlice";
import PageStyles from '../../css/page.module.css'
export interface Props {onClose:Function}
export const ModelingSupplier:React.FC<Props> = ({onClose}) => {
    const {companyId}:any = useParams()
    const navigate = useNavigate()
    const [getSupplierDetailApi, {data , isSuccess, isError}] = useGetSupplierDetailMutation()

    useEffect(()=>{getSupplierDetailApi({supplierId : companyId})}, [])
    return(
        <>
        <div className={[PageStyles.companyInformation_popUp, PageStyles.active].join(' ')}>
				<div className={PageStyles.titleBox}>
					<span className={PageStyles.active}>업체정보</span>
				</div>
				<button className={PageStyles.popUpClose_btn}> 
					<img src="/assets/images/modelingCtrl/popUpClose_btn.png" alt="close btn" onClick={()=>onClose()}/>
				</button>

				<div className={PageStyles.contentBox}>
					<div>
						<div style={{backgroundColor: data?.list[0]?.companyBrandColor}}>
							<img src={`https://marineplaza.org${data?.list[0]?.companyLogoImage}`} alt="BMC logo"/>
						</div>
						<div>
							<span>{data?.list[0]?.companyNameEn}</span>
							<div>
								<span>업종</span>
								<span>{data?.list[0]?.businessAreaEn}</span>
							</div>
							<div>
								<span>설립일</span>
								<span>{data?.list[0]?.companyYear} Year</span>
							</div>
						</div>
					</div>
					
					<div>
						{data?.list[0]?.companyAddressEn1 === 'null' ? '' : <GoogleMaps address={data?.list[0]?.companyAddressEn1} google={''}/>}
					</div>

					<div>
						<div>
							<span>Contact</span>
							<div>
                                {data?.list[0]?.companyEmail === '' ? '' : <span>{data?.list[0]?.companyEmail}</span>}
                                {data?.list[0]?.companyTel === '' ? '' : <span>{data?.list[0]?.companyTel}</span>}
                                {data?.list[0]?.companyWebsite === '' ? '' : <span>{data?.list[0]?.companyWebsite}</span>}
							</div>
						</div>
						<div>
							<span>본사</span>
							<div>
								<span>
                                    {data?.list[0]?.companyAddressEn1 === 'null' ? '주소 없음 (No Address)' : data?.list[0]?.companyAddressEn1}
                                </span>
							</div>
						</div>
					</div>
				</div>
				
				<button className={PageStyles.moreViewBtn} onClick={()=>navigate(`/contents/supplier/211/${data?.list[0]?.companyId}/I`)}>
                    more view
                <i className="fa-regular fa-chevron-right"></i></button>
			</div>
        </>
    )
}