import React from "react";

const TopButton = () => {
    const topBtn = () => { window.scrollTo({ top: 0, behavior: 'smooth' }) }
    return(
        <>
        <button className="topBtn" onClick={topBtn}>
            <span></span>
        </button>
        </>
    )
}

export default TopButton;