import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export interface Props {data: any}
const Section6:React.FC<Props> = ({data}) => {
    const navi = useNavigate()
    const [supplerList, setSupplierList] = useState<any>([])
    
    useLayoutEffect(()=>{
        setSupplierList(data.suppliers)
    }, [data.suppliers])
    
    return(
        <>
         <section className="main_section main_section6">
            <div className="titleBox">
                <h2>Experience a variety of <span>Suppliers</span></h2>
                <a onClick={()=>navi(`/contents/211`)} style={{cursor:'pointer'}}>more suppliers</a>
            </div>

            <div className="marquee_container marquee_container_left">
                <div className="slide_container">
                    {supplerList?.map((data:any, index : number)=>{
                        if(index >=0 && index <=4){
                            return(
                            <div className="slide_image" style={{backgroundColor : data.companyBrandColor}} key={index}>
                                <img src={`https://marineplaza.org${data.companyLogoImage}`} alt="흐르는 배너" />
                            </div>
                            )
                        }
                    })}
                </div>
                
                <div className="slide_container">
                    {supplerList?.map((data:any, index : number)=>{
                        if(index >=5 && index <=9){
                            return(
                            <div className="slide_image" style={{backgroundColor : data.companyBrandColor}} key={index}>
                                <img src={`https://marineplaza.org${data.companyLogoImage}`} alt="흐르는 배너" />
                            </div>
                            )
                        }
                    })}
                </div>
                <div className="slide_container_bottom"></div>
            </div>

            
            <div className="marquee_container marquee_container_right">
                <div className="slide_container">
                    {supplerList?.map((data:any, index : number)=>{
                        if(index >=10 && index <=14){
                            return(
                            <div className="slide_image" style={{backgroundColor : data.companyBrandColor}} key={index}>
                                <img src={`https://marineplaza.org${data.companyLogoImage}`} alt="흐르는 배너"/>
                            </div>
                            )
                        }
                    })}
                </div>
                
                <div className="slide_container">
                    {supplerList?.map((data:any, index : number)=>{
                        if(index >=15 && index <=19){
                            return(
                            <div className="slide_image" style={{backgroundColor : data.companyBrandColor}} key={index}>
                                <img src={`https://marineplaza.org${data.companyLogoImage}`} alt="흐르는 배너"/>
                            </div>
                            )
                        }
                    })}
                </div>
                <div className="slide_container_bottom"></div>
            </div>
        </section>
        </>
    )
}

export default Section6;