import React, { useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TagBox } from "../../../components/contents/tagBox";
import Paging from "../../../components/pagination/pagination";
import { getCookie } from "../../../util/cookie/cookies";
export interface Props {supplierList:any}
const Supplier:React.FC<Props> = ({supplierList}) => {
    const lang = getCookie("MARINELANG")
    const location = useLocation()
    const navi = useNavigate();
    const activeRef = useRef<any[]>([])
    const {contentsType} = useParams()
    const [contentsHover, setContentsHover] = useState(false);
    
    const goView = (e:React.MouseEvent, id: number) => {
        e.preventDefault();
        
        if (location.pathname === `/contents/${contentsType}`) {
            navi(`/contents/supplier/${contentsType}/${id}/I`);
        } else {
            navi('/mypage/collection/supplier', { state: { type: 6 } });
        }
    }
    const handleOver = (companyId:any) => {
        activeRef.current?.[companyId].classList.add('active');
    }
    const handleLeave = (companyId:any) => {
        activeRef.current?.[companyId].classList.remove('active');
    }

    return(
        <>
        <ul className="listType_all listType_3equalParts listType_d">
            {supplierList?.map((data : any) => {
                return(
                    <>
                    <li key={data?.companyId}>
                        <div className='supplier_listBox' ref={(e)=>activeRef.current[data.companyId]=e} onMouseOver={()=>handleOver(data.companyId)} onMouseLeave={()=>handleLeave(data.companyId)}>
                            {/* supplier_listBox를 hover했을 때 이 박스에 active 클래스 추가 */}
                            <div className="hoverTextBox" onClick={(e:any)=>goView(e,data.companyId)} style={{backgroundColor: data.companyBrandColor===null ? 'black' : `${data.companyBrandColor}`}}
                            >
                                {/* <a href="supplier_view.html"></a> */}
                                <div className="imgBox">
                                    <img src={`https://marineplaza.org${data.companyLogoImage}`} alt="contents_list"/>
                                </div>
                                <div className="txtBox">
                                    <p style={{color: /^#f|^#e/.test(data.companyBrandColor) ? 'black' : 'white'}}>
                                        {lang === 'kr' ?
                                        <strong>{data.businessAreaKr?.length <= 35 ? data.businessAreaKr : data.businessAreaKr?.slice(0,35)+"..."}</strong>:
                                        <strong>{data.businessAreaEn?.length <= 35 ? data.businessAreaEn : data.businessAreaEn?.slice(0,35)+"..."}</strong>
                                        }
                                    </p>
                                    {/* <div>
                                        <p style={{color: /^#f|^#e/.test(data.companyBrandColor) ? 'black' : 'white'}}>
                                            <TagBox tags={data?.companyTags}/>
                                        </p>
                                    </div> */}
                                </div>
                            </div>

                            <div className='hashTagBox'>
                                <p style={{color: /^#f|^#e/.test(data.companyBrandColor) ? 'black' : 'white'}}>
                                    <TagBox type={'211'} tags={data?.companyTags}/>
                                </p>
                            </div>

                            <div className="bottomBox">
                                <div className="contentSaveBox_circle">
                                    <button className="contentSaveBtn">
                                        <span></span>
                                    </button>
                                    <span>콘텐츠 저장하기</span>
                                </div>
                                <p onClick={(e:any)=>goView(e,data.companyId)} style={{cursor:'pointer'}}>
                                    {lang==='kr' ? data?.companyNameKr : data.companyNameEn}
                                </p>
                                <div className="companyInfoBox">
                                    {data?.companyEmail === '' ? '' : <a href={`mailto:${data.companyEmail}`}>{data?.companyEmail}</a>}
                                    {data?.companyTelNo === '' ? '' : <a href={`mailto:${data.companyTelNo}`}>{data?.companyTelNo}</a>}
                                    {data?.companyWebsite === '' ? '' : <a href={data?.companyWebsite} target="_blank">{data?.companyWebsite}</a>}
                                </div>
                            </div>
                        </div>
                    </li>
                    </>
                )
            })}
        </ul>

        
        </>
    )
}

export default Supplier;