import React from "react";

const TermOfUse = () => {

    return(
        <>
        <div className="termsOfUse etc active">
            <h3>이용약관</h3>
            <div>
                <h4>제1조 (목적)</h4>
                <p>이 약관은 주식회사 비엠씨(이하 “회사”)가 제공하는 웹사이트 서비스(이하 “서비스”)에 대한 이용계약을 체결한 이용자(이하 “회원”)의 서비스 이용조건 및 절차, 회사와 회원의
                    권리와 의무 및 책임사항을 규정함을 목적으로 합니다.</p>
            </div>
            <div>
                <h4>제2조 (정의)</h4>
                <div>
                    <p>
                        <span>01</span>
                        아이디란 회원 식별 및 서비스 이용 등을 위해 이용자가 직접 설정, 입력한 이메일주소를 뜻합니다.
                    </p>
                    <p>
                        <span>02</span>
                        패스워드란 회원 정보 보호 및 회원 별 정보 제공 서비스 등을 위해 이용자가 직접 설정, 입력하며 문자와 숫자로 이뤄진 조합을 뜻합니다.
                    </p>
                    <p>
                        <span>03</span>
                        관리자란 서비스의 전반적 관리 및 운영을 담당하는 회사의 직원을 뜻합니다.
                    </p>
                </div>
            </div>
            <div>
                <h4>제3조 (회원 가입 신청 및 허가)</h4>
                <p>회원 가입은 회원이 회사가 제시하는 양식에 자기 정보를 입력, 신청하고 회사는 이를 허가함으로써 이루어집니다.</p>
            </div>
            <div>
                <h4>제4조 (회원 가입 신청 시 기재 항목)</h4>
                <p>회원은 회원 가입 신청 시 다음 항목은 필수적으로 입력해야 합니다.</p>
                <div>
                    <p>
                        <span>01</span>
                        <mark>이메일주소 / 패스워드</mark>
                    </p>
                    <p>
                        <span>02</span>
                        <mark>이름</mark>
                    </p>
                    <p>
                        <span>03</span>
                        <mark>휴대폰번호</mark>
                    </p>
                </div>
            </div>
            <div>
                <h4>제5조 (회원 가입 허가 및 유보 등)</h4>
                <p>
                    <strong>(1) 회사는 제4조에 규정된 각 항목을 성실히 기재하고 본 약관에 동의하는 이용자에 한해 서비스 이용을 허가합니다.</strong>
                </p>
                <p>
                    <strong>(2) 회사는 다음 각 호의 경우 허가를 유보할 수 있습니다.</strong>
                </p>
                <div>
                    <p>
                        <span>01</span>
                        <mark>서비스를 제공하지 못할 기술적 장애가 발생한 경우</mark>
                    </p>
                    <p>
                        <span>02</span>
                        <mark>기타 회사가 필요하다고 판단한 경우</mark>
                    </p>
                </div>
                <p>
                    <strong>(3) 회사는 다음 각 호의 경우 허가를 철회하거나 거절할 수 있습니다.</strong>
                </p>
                <div>
                    <p>
                        <span>01</span>
                        <mark>제4조에 규정된 항목을 허위로 기재한 경우</mark>
                    </p>
                    <p>
                        <span>02</span>
                        <mark>다른 사람의 명의를 도용, 회원가입을 신청한 경우</mark>
                    </p>
                    <p>
                        <span>03</span>
                        <mark>사회 공공질서 또는 건전한 미풍양속을 저해할 우려가 있다고 충분히 인정되는 경우</mark>
                    </p>
                    <p>
                        <span>04</span>
                        <mark>기타 회원으로서 부적절한 행위를 할 우려가 있다고 충분히 인정되는 경우</mark>
                    </p>
                </div>
            </div>
        </div>
        </>
    )
}

export default TermOfUse;