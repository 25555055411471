import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CatalogueView } from "../../../../view/catalogueView";
import PageStyles from '../../../css/page.module.css'
export interface Props {data : any; name:string}
export const TechAndCatalogue:React.FC<Props> = ({data, name}) => {

    const navigate = useNavigate()
    const [flip, setFlip] = useState<any>({isActive : false, id : ''})
    const handleTransView = (data:any) => {
        if(name==='tech'){navigate(`/contents/techDoc/210/${data.promIdx}`)}
        else{
            setFlip({isActive : true, id : data?.promIdx})
        }
    }
    const handleClose = () => {
        setFlip({isActive :false, id : ''})
    }

    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_2, PageStyles.active].join(" ")}>
            <ul className={[PageStyles.listType_all, PageStyles.listType_3equalParts, PageStyles.listType_c].join(" ")}>
                {data?.map((data:any) => (
                <li onClick={()=>handleTransView(data)}>
                    {/* <!-- <a href=""></a> --> */}
                    <div>
                        <div className={PageStyles.scaleImgBox}>
                            <img src={`https://marineplaza.org${data?.promThumnailImage}`} alt="contents_list"/>
                        </div>
                        {/* <!-- hover시 커지는 이미지 박스 --> */}

                        <div className={PageStyles.bottomBox}>
                            <div className={PageStyles.contentSaveBox_circle}>
                                <button className={PageStyles.contentSaveBtn}>
                                    <span></span>
                                </button>
                                <span>콘텐츠 저장하기</span>
                                {/* <!-- hover 시 보이는 버튼, 툴팁 --> */}
                            </div>
                            {/* <!-- 콘텐츠저장 버튼 박스 --> */}
                            <p>
                                <span>{data?.companyNameEn}</span>
                                {data?.promSubjectEn}
                            </p>
                            {/* <!-- 제목 - 말줄임처리(css) --> */}
                        </div>
                        {/* <!-- 이미지 아래 흰배경 박스 --> */}
                    </div>
                </li>
                ))}
            </ul>
            {flip?.isActive && <CatalogueView contentId={flip.id} onClose={handleClose}/>}
        </div>
        </>
    )
}