import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {toast, Toaster} from 'react-hot-toast';
import BackDrop from "../../components/backDrop/backDrop";
import { useLoginUserMutation } from "../../store/slices/user/userApiSlice";
import { getCookie } from "../../util/cookie/cookies";
import { useTranslation } from "react-i18next";

const Login = () => {
    const {t} = useTranslation()
    const lang = getCookie('MARINELANG')
    const [login] = useLoginUserMutation();
    const navi = useNavigate();
    const idRef = useRef<any>();
    const pwRef = useRef<any>();
    const [progress, setProgress] = useState<boolean>(false)
    const testAcc = {id: 'test01@test.com' , pw : '1234'}
    const [param, setParam] = useState({
        id :'' , pw : '', id_save : false
    })

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name , value, type ,checked} = e.target;
        if(type === 'checkbox') {setParam({...param, [name] : checked})}
        else {setParam({...param, [name] : value})}
    }

    const handleLogin = () => {
        // setProgress(true)
        if(!idRef.current.value || !pwRef.current.value){
            toast.error(lang === 'kr' ? "아이디 및 비밀번호를 확인해 주세요." : 'Check your Id or Password') 
        } else {
            // Login Api
            
        }
    }
    
    return(
        <>
        <Toaster position="bottom-right" toastOptions={{duration: 2000, style : {backgroundColor : 'black', color : 'white', fontSize : '15px', top : '580px'}}}/>
        <div className="mobileContentBox mobile_login">
            <div>
                <div className="titleBox">
                    <h2>Login</h2>
                    <p>{lang === 'kr'?'로그인 후 더욱 다양한 서비스를 이용해 보세요.' : "Try more diverse services after logging in."}</p>
                </div>
                <div className="formBox">
                    <div className="inputList">
                        <label htmlFor="">
                            <img src="/assets/images/form_id.png" alt="login"/>
                        </label>
                        <input type="text" ref={idRef} name="id" id="id" placeholder={t("login_1")} onChange={handleChange}/>
                    </div>
                    <div className="inputList">
                        <label htmlFor="">
                            <img src="/assets/images/form_pw.png" alt="password"/>
                        </label>
                        <input type="password" ref={pwRef} name="pw" id="pw" placeholder={t("login_2")} onChange={handleChange}/>
                    </div>
                    <div className="idSaveBox">
                        <div className="inputCheckBox">
                            <input type="checkbox" name="id_save" id="id_save" onChange={handleChange}/>
                            <label htmlFor="id_save">{t("login_3")}</label>
                        </div>
                        <div className="findBtnBox">
                            <button className="find_id" onClick={()=>navi('/findId')}>{t("login_4")}</button>
                            <button className="find_pw" onClick={()=>navi('/findPw')}>{t("login_5")}</button>
                        </div>
                    </div>
                    <button type="submit" className="loginBtn" onClick={handleLogin}>{t("login_6")}</button>
                    <button className="signUpBtn" onClick={()=>navi('/join')} >{t("login_7")}</button>
                </div>
                <div className="adBox">
                    <span>OPEN EVENT</span>
                    <div>
                        <div>
                            <h3>
                                <span>{t("login_8")}</span>
                                {t("login_9")}
                            </h3>
                            <span className="adTxt">AD</span>
                        </div>
                        <div className="imgBox">
                            <img src="/assets/images/ad_balloon.png" alt="balloon"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BackDrop progress={progress}/>
        </>
    )
}

export default Login;