import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import TopSlickSlider from "../../components/slick/main/topBtnSlick/top_slick";
import TopButton from "../../components/topBtn/topBtn";
import { getCookie } from "../../util/cookie/cookies";
import Contents from "../contents";
import Faq from "../etc/faq";
import ServiceTerms from "../etc/serviceTerms";
import Inquriy from "../inpuiry";
import MobileLayOut from "../layout_mobile";
import Main from "../main";
import MyPage from "../user/myPage";
import Footer from "./footer/footer";
import Header from "./header/header";

const LayOut = () => {
    const lang = getCookie('MARINELANG')
    const location = useLocation();
    const topBtn = location.pathname === '/' ? '' : 'subPage';
    const [isShowTopBtn, setIsShowTopBtn] = useState<boolean>(false)
    const {contentsType, newsId, supplierId, techId, tab, supplierContents, catalogueId} : any = useParams();

    const [isScrolledToTop, setIsScrolledToTop] = useState<boolean>(false);
    const [isMobileLayout, setIsMobileLayout] = useState<boolean>(false);
    const [isMobileLayout2, setIsMobileLayout2] = useState<boolean>(false);
    const handleCloseTopBtn = () => {
        setIsShowTopBtn(true)
    }
    const handleScroll = () => {
        setIsScrolledToTop(window.pageYOffset === 0);
    }
    const handleResize = () => {
        if (window.innerWidth <= 1023) {setIsMobileLayout(true);} 
        else {setIsMobileLayout(false);}
    }
    const handleResize2 = () => {
        if(window.innerWidth <= 748) {setIsMobileLayout2(true)}
        else {setIsMobileLayout2(false)}
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        window.addEventListener('resize', handleResize2);
        if (window.innerWidth <= 1023) {setIsMobileLayout(true);}
        if (window.innerWidth <= 748) {setIsMobileLayout2(true);}
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('resize', handleResize2);
        };
    }, []);
    
    

    return(
        <>
        <div id={`wrap ${lang}`} className={`${topBtn} ${lang}`}>

            {topBtn === 'subPage' || isShowTopBtn ? <></> : <TopSlickSlider onClose={handleCloseTopBtn}/>}
            {isMobileLayout ? <MobileLayOut/> : <Header/>}
            {isMobileLayout2 || !isScrolledToTop ? <TopButton /> : null}

            {location.pathname === '/' ? <Main responsive={isMobileLayout} onClose={setIsShowTopBtn}/> : ''}
            {location.pathname === `/contents/${contentsType}` ? <Contents/> : ''}
            {location.pathname === `/contents/news/${contentsType}/${newsId}` ? <Contents/> : ''}
            {location.pathname === `/contents/supplier/${contentsType}/${supplierId}/${tab}` ? <Contents/> : ''}
            {location.pathname === `/contents/supplier/${contentsType}/${supplierId}/${tab}/${supplierContents}` ? <Contents/> : ''}
            {location.pathname === `/contents/techDoc/${contentsType}/${techId}` ? <Contents/> : ''}
            {location.pathname === `/contents/catalogue/${contentsType}/${catalogueId}` ? <Contents/> : ''}
            
            {location.pathname === '/mypage/modify' ? <MyPage/> : ''}

            {location.pathname === '/inquriy' ? <Inquriy/> : ''}
            {location.pathname === '/faq' ? <Faq/> : ''}
            {location.pathname === '/serviceTerm' ? <ServiceTerms/> : ''}
            {location.pathname === `/mypage/collection/${contentsType}` ? <MyPage/> : ''}
            {location.pathname === `/mypage/downloads/${contentsType}` ? <MyPage/> : ''}
            {location.pathname === `/mypage/inquirys` ? <MyPage/> : ''}
        </div>
        <Footer/>
        </>
    )
}

export default LayOut;