import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FileDownLoad } from "../../../../../../components/modeling/file_download";
import { useGetAssembleCatalogueMutation } from "../../../../../../store/slices/modeling/productApiSlice";
import PageStyles from '../../../css/page.module.css'

export const Catalogue = () => {
    const {modelingId} = useParams();
    const [getAssembleCatalogue, {data:catalogueData, isSuccess:success}] = useGetAssembleCatalogueMutation()
    const [catalogue, setCatalogue] = useState<any>({kr:[],en:[]})
    const handleDownload = (file:string) => {
        const fileUrl: any = file;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    useEffect(()=>{getAssembleCatalogue({modelingId})},[])
    useEffect(()=>{if(success){
        setCatalogue({...catalogue, kr:catalogueData?.list?.filter((item:any)=>item.brouchure_lang==='KR'), en:catalogueData?.list?.filter((item:any)=>item.brouchure_lang==='EN')})
    }}, [success])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_3, PageStyles.active].join(" ")}>
            <div className={PageStyles.downloadBox}>
                <div className={PageStyles.catalog_kr}>

                    {catalogue?.kr?.map((catalogue : {idx:string;brochure_lang:string;brouchure_pdsFile:string;brouchure_pdsFilename:string})=>(
                    <div className={PageStyles.catalogDown} key={catalogue.idx}>
                        <FileDownLoad fileUrl={catalogue.brouchure_pdsFilename} fileName={catalogue.brouchure_pdsFile}/>
                    </div>
                    ))}

                </div>

                {/* <div className={PageStyles.catalogDown}>
                    <div>
                        <span><img src="/assets/images/modelingCtrl/popUp1_download1.png" alt="download"/></span>
                        <a target="_blank"><input id="brouchureKr_after" name="brouchure_title" value="" placeholder="등록할 트러블슈팅 첨부 파일을 업로드하시기 바랍니다."/></a>
                    </div>
                </div> */}

            </div>
        </div>
        </>
    )
}