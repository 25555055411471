import React from "react";
import { useNavigate } from "react-router-dom";

const Hamburger = () => {

    const navi = useNavigate();

    const contentsList = [
        {idx : 1, title : '3D Product & Component', type : 206},
        {idx : 2, title : 'Marine News', type : 207},
        {idx : 3, title : 'Catalogue', type : 208},
        {idx : 4, title : 'Video', type : 209},
        {idx : 5, title : 'Tech. Document', type : 210},
        {idx : 6, title : 'Supplier', type : 211},
    ]

    const goContents = (type : number) => {
        navi(`/contents/${type}`)
    }

    return(
        <>
        <div className="mobileNav">
            <div>
                <div>
                    <span>Contents</span>
                    <ul>
                        {contentsList.map((data:{idx:number,title:string,type:number})=>(
                            <li key={data.idx} onClick={()=>goContents(data.type)}>
                                {data.title}
                            </li>
                        ))}
                    </ul>
                </div>

                <div>
                    <span>Forums</span>
                    <ul>
                        <li onClick={()=>alert("준비 중입니다.")}>Marine Plaza Notice</li>
                        <li onClick={()=>alert("준비 중입니다.")}>Buyer</li>
                        <li onClick={()=>alert("준비 중입니다.")}>Products</li>
                    </ul>
                </div>

                <div className="singleMenuBox">
                    <span onClick={()=>navi('/inquriy')}>Inquiry</span>
                </div>

                <div className="singleMenuBox">
                    <span onClick={()=>alert("준비 중입니다.")}>FAQ</span>
                </div>
                
                <div>
                    <span>My Page</span>
                    <ul>
                        <li onClick={()=>alert("준비 중입니다.")}>Collections</li>
                        <li onClick={()=>alert("준비 중입니다.")}>Downloads</li>
                        <li onClick={()=>alert("준비 중입니다.")}>Inquiry</li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}

export default Hamburger;