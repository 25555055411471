import React from "react";
import { useNavigate } from "react-router-dom";
import { TagBox } from "../../../components/contents/tagBox";
import { useGoContents } from "../../../components/navigate/navi";
export interface Props {data : any}
const Section5:React.FC<Props> = ({data}) => {
    const navi = useNavigate()
    return(
        <>
        <section className="main_section main_section5 colorBG">
            <div>
                <div>
                    <div className="titleBox">
                        <h2>FEATURED <span>TECH. DOCUMENTS</span></h2>
                        <a style={{cursor:'pointer'}} onClick={()=>navi(`/contents/210`)}>more view</a>
                    </div>
                </div>
                <ul className="techDocumentsList">
                    {data?.techNews?.map((data : any, index : number) => {
                        const formatTag = data.promTags.split(',')
                        return(
                    <li key={index}>
                        <span>{index+1}</span>
                        {/* <!-- 3까지만 띄워줌 --> */}
                        <div>
                            <div className="scaleImgBox" onClick={()=>navi(`/contents/techDoc/210/${data?.promIdx}`)}>
                                {/* <img src="/assets/images/main_section5_1.png" alt="contents_list"/> */}
                                <img src={`https://marineplaza.org${data.promThumnailImage}`} alt="contents_list"/>
                            </div>
                            {/* <!-- hover시 커지는 이미지 박스 --> */}
    
                            <div className="bottomBox">
                                <div className="hashTagBox">
                                    <p>
                                    <TagBox tags={data?.promTags} type={'210'}/>
                                    </p>
                                </div>
                                <a onClick={()=>navi(`/contents/techDoc/210/${data?.promIdx}`)}>{data.promSubjectKr}</a>
                                {/* <!-- 제목 - 말줄임처리(css) --> */}
                            </div>
                            {/* <!-- 이미지 아래 흰배경 박스 --> */}
                        </div>
                    </li>
                        )
                    })}
                </ul>
            </div>
        </section>
        </>
    )
}

export default Section5;