import React from "react";
import { useTranslation } from "react-i18next";

const UserModify = () => {
    const {t} = useTranslation()
    return(
        <>
        <section className="myProfile">
            <div className="skin"></div>

            <div className="wrap">
                <div className="left">
                    <div className="profile_editBox">
                        <div className="profile_img" style={{backgroundImage: "url('/assets/images/myPage_profile.png')"}}>
                            <div className="editTxt">
                                <span>{t("modify_1")}</span>
                            </div>
                        </div>
                        
                        <button className="profile_editBtn"></button>
                    </div>
                    <div className="user_infoBox">
                        <span className="userName">Baek Jihee</span>
                        <span className="userPosition">UX/UI디자이너</span>
                    </div>
                </div>

                <div className="right">
                    <ul>
                        <li>
                            <span>{t("modify_2")}</span>
                            <p>aa9810@bmcnet.kr</p>
                        </li>
                        <li>
                            <span>{t("modify_3")}</span>
                            <p>************</p>
                        </li>
                        <li>
                            <span>{t("modify_4")}</span>
                            <p>Baek Jihee</p>
                        </li>
                        <li>
                            <span>{t("modify_5")}</span>
                            <p>01012345678</p>
                        </li>
                        <li>
                            <span>{t("modify_6")}</span>
                            <p>주식회사 비엠씨</p>
                        </li>
                        <li>
                            <span>{t("modify_7")}</span>
                            <p>업무 분야를 추가해주세요.</p>
                        </li>
                        <li>
                            <span>{t("modify_8")}</span>
                            <p>직책을 추가해주세요.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        </>
    )
}

export default UserModify;