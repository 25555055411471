import React from "react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useLocation } from "react-router-dom";

export interface Props {page : any; size : number, setPage : Function; totalPage : number}
const Paging:React.FC<Props> = ({ page, size, setPage, totalPage}) => {
    
    const handlePage = (e : any, pageNumber : number) => {
        setPage(pageNumber)
    }
    return(
        <>
            <Stack spacing={2}>
                <Pagination page={parseInt(page)} count={Math.ceil(totalPage/size)} shape="rounded" onChange={handlePage}/>
            </Stack>
        </>
    )
}

export default Paging;
