import React from "react";

const PrivacyPolicy = () => {

    return(
        <>
        <div className="privacy_policy etc active">
            <section>
                <h3>개인정보처리방침</h3>
                <div className="txtBox">
                    <p>
                        마린플라자(이하 "회사"라 합니다)는 이용자들의 개인정보보호를 매우 중요시하며, 이용자가 회사의 (https://marineplaza.org) 서비스(이하 "서비스"라 합니다)를
                        이용함과 동시에 온라인상에서 회사에 제공한 개인정보가 보호받을 수 있도록 최선을 다하고 있습니다.
                    </p>
                    <p>
                        이에 회사는 개인정보보호법에 따라 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정을 준수하고 있습니다. 회사는 개인정보 보호정책을
                        통하여 이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
                    </p>
                    <p>회사는 개인정보 보호정책을 홈페이지 첫 화면에 공개함으로써 이용자들이 언제나 용이하게 보실 수 있도록 조치하고 있습니다.</p>
                    <p>마린플라자 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.</p>
                </div>

                <ul>
                    <li>
                        <p>
                            <strong>가.</strong>
                            <span>개인정보의 수집 및 이용목적</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>나.</strong>
                            <span>개인정보 보유 및 이용기간</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>다.</strong>
                            <span>개인정보의 처리위탁</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>라.</strong>
                            <span>수집한 개인정보의 공유 및 제공</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>마.</strong>
                            <span>이용자의 권리(열람, 정정, 삭제 등)와 행사방법</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>바.</strong>
                            <span>개인정보 자동수집 장치의 설치/운영 및 거부에 관한 사항</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>사.</strong>
                            <span>행태정보의 수집·이용 및 거부 등에 관한 사항</span>
                        </p>
                    </li>
                </ul>

                <div id="paragraph_1" className="paragraph_1 paragraph">
                    <h4>
                        <span>가.</span>
                        개인정보의 수집 및 이용목적
                    </h4>
                    <p>
                        <strong>서비스 이용시 필수 수집 항목</strong>
                        아이디(이메일), 패스워드, 회사명, 업종, 직급, 이름, 연락처, 내/외국인정보, 본인인증결과
                    </p>
                    <p>
                        <strong>탈퇴 요청 5일 후 지체없이 파기</strong>
                    </p>
                    <p>
                        <strong>내부 방침에 의해 서비스 부정이용 기록은 부정 가입 및 이용방지를 위하여 회원탈퇴 시점으로 1년간 보관 후 파기</strong>
                        단, 관계법령에 따라 일정기간 보존해야 하는 경우 해당 기간 보관 후 파기
                    </p>
                    <p>
                        <strong>회사 회원관리 (마케팅 활용)</strong>
                        https://bmcnet.kr의 문의하기 서비스를 이용하는 경우 회원으로 관리됩니다.
                    </p>
                    <p>
                        <strong>변동 및 약관 변경 등의 고지를 위한 안내</strong>
                    </p>
                    <p>
                        <strong>회사 서비스 이용</strong>
                        당사의 웹사이트에는 별도의 로그인 설정이 없으며 문의하기를 통해 수집된 개인 정보에 대한 관리를 위해 정보를 수집합니다.
                    </p>
                    <p>
                        <strong>서비스 이용 과정에서 자동 수집 정보</strong>
                        IP정보, 디바이스 또는 브라우저 정보, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록
                    </p>
                    <p className="marginNone">
                        <strong>마케팅 및 광고에 활용</strong>
                        개인정보 수집 및 이용목적이 달성되면 지체없이 파기합니다.
                    </p>
                    <ol>
                        <li>
                            신규 서비스(제품) 개발 및 맞춤 서비스 제공
                        </li>
                        <li>
                            이벤트 및 광고성 정보 제공 및 참여기회 제공
                        </li>
                        <li>
                            인구통계학적 특성에 따른 서비스 제공 및 광고 게재
                        </li>
                        <li>
                            서비스의 유효성 확인
                        </li>
                        <li>
                            접속빈도 파악 또는 회원의 서비스 이용에 대한 통계
                        </li>
                        <li>
                            개인식별정보: 성명, 연락처, e-mail, 접속 일시, IP주소
                            <span>(수집∙이용항목은 마케팅 및 분석 목적에 따라 달라질 수 있으며 수집 시점에 안내 후 동의 여부를 확인함)</span>
                        </li>
                    </ol>
                    <p>
                        <strong>회사는 아래와 같은 방법으로 개인정보를 수집합니다.</strong>
                    </p>
                    <ol>
                        <li>
                            웹페이지, 휴대폰 어플리케이션, 서면양식, 팩스, 전화, 고객센터, 상담게시판, 이메일, 이벤트 응모
                        </li>
                        <li>
                            생성정보 수집 툴을 통한 수집
                        </li>
                    </ol>
                    <p>
                        <strong>만 14세 미만 회원의 개인정보를 수집하지 않습니다.</strong>
                    </p>
                    <p>
                        <mark>개인정보 제공은 회사 서비스 이용을 위해 필요한 사항입니다. 개인정보 제공을 거부할 경우 서비스 이용에 제한될 수 있습니다.</mark>
                    </p>
                </div>

                <div id="paragraph_2" className="paragraph_2 paragraph">
                    <h4>
                        <span>나.</span>
                        개인정보 보유 및 이용 기간
                    </h4>
                    <p>
                        <strong>
                            이용자의 개인정보는 <mark>'바, 개인정보파기절차 및 방법'</mark>의 기준으로 마케팅 광고 수신 동의 거부의사를 밝힌 날로부터 5일 후 개인정보 수집 및 이용목적이 달성되면
                            지체없이 파기합니다. 다만, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 회사는 관계
                            법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 법률이 정한 기간으로 합니다.
                        </strong>
                    </p>
                </div>
        
                <div id="paragraph_3" className="paragraph_3 paragraph">
                    <h4>
                        <span>다.</span>
                        수집한 개인정보의 공유 및 제공
                    </h4>
                    <p>
                        <strong>이용자의 개인정보는 <mark>'가, 개인정보의 수집 및 이용목적'</mark>에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동의 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다.</strong>
                    </p>
                    <p>
                        <strong>다만, 아래의 경우에는 예외로 합니다.</strong>
                    </p>

                    <p>
                        <span>첫째,</span>
                        이용자들이 사전에 공개에 동의한 경우
                    </p>
                    <p>
                        <span>둘째,</span>
                        기타 법에 의해 요구된다고 선의로 판단되는 경우 (ex. 관련법에 의거하여 적법한 절차에 의한 정부기관의 요청이 있는 경우 등)
                    </p>
                    <p>
                        <strong>
                            이용자가 개인정보 제공에 사전에 동의한 경우에 한하여 개인정보를 제공하고 있으며, 개인정보의 제3자 제공에 대한 동의를 거부하시는 경우에도 기본 서비스를
                            이용하실 수 있으나 제3자 제공에 기반한 서비스의 이용 및 제공이 제한될 수 있습니다.
                        </strong>
                    </p>
                </div>
        
                <div id="paragraph_4" className="paragraph_4 paragraph">
                    <h4>
                        <span>라.</span>
                        개인정보 자동수집 장치의 설치/운영 및 거부에 관한 사항
                    </h4>
                    <p>
                        <strong>1. 회사의 쿠키 사용 목적</strong>
                        <span className="common">회원과 비회원의 접속 빈도나 방문 시간 등을 분석</span>
                        <span className="common">이용자의 취향과 관신분야 파악 및 자취 추적</span>
                    </p>
                    <p>
                        <strong>2. 쿠키의 설치/운영 및 거부</strong>
                        <span>이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</span>
                        <span>다만, 쿠키의 저장을 거부할 경우에는 일부 서비스 이용에 어려움이 있을 수 있습니다.</span>
                    </p>

                    <div>
                        <div>
                            <p>쿠키 설치 허용 여부를 지정하는 방법은 다음과 같습니다.</p>
                            <p>
                                <strong>웹 브라우저</strong>
                                <span>Internet Explorer: 메뉴 → 도구 → 인터넷 옵션 → 검색 기록 → '삭제' 및 '설정'</span>
                                <span>Chrome: 메뉴 → 설정 → '개인정보 및 보안' → '쿠키 및 기타 사이트 데이터' → 쿠키 차단 및 데이터 삭제</span>
                            </p>
                        </div>
                        <div>
                            <p>설치된 쿠키를 삭제 및 차단하는 방법은 다음과 같습니다.</p>
                            <p>
                                <strong>웹 브라우저</strong>
                                <span>Internet Explorer: 메뉴 → 도구 → 인터넷 옵션 → 검색 기록 → '삭제' 및 '설정'</span>
                                <span>Chrome: 메뉴 → 설정 → '개인정보 및 보안' → '쿠키 및 기타 사이트 데이터' → 쿠키 차단 및 데이터 삭제</span>
                                <span>Safari: 환경설정 → '크로스 사이트 추적방지' 및 '모든 쿠키 차단'</span>
                            </p>
                            <p>
                                <strong>스마트폰</strong>
                                <span>Android: 홈 → 설정 → Google → 광고 → 광고 맞춤 설정 선택 해제 (ON)</span>
                                <span>iPhone: 홈 → 설정 → 개인정보보호 → 추적 → 앱이 추적을 허용하도록 허용 (OFF)</span>
                            </p>
                        </div>
                    </div>

                    <p>
                        <span>이용자 피해 구제 방법: 아래 연락처로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해신고 접수 등을 문의할 수 있습니다.</span>
                    </p>

                    <ul className="common">
                        <li><span>성명:</span>이창근</li>
                        <li><span>민원(고충) 처리부서:</span>마린플라자 고객센터</li>
                        <li><span>연락처:</span>032-837-5388</li>
                        <li><span>이메일:</span>admin@bmcnet.kr</li>
                    </ul>

                </div>

                <div id="paragraph_5" className="paragraph_5 paragraph">
                    <h4>
                        <span>마.</span>
                        개인정보보호를 위한 기술적-관리적 보호 대책
                    </h4>
                    <p>회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난,유출, 변조 또는 훼손되지 않도록 안정성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.</p>
                    <div>
                        <p>
                            <strong>1. 개인정보 암호화</strong>
                            이용자의 중요 개인정보(계좌번호 등)는 암호화하여 저장/관리되고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
                        </p>
                        <p>
                            <strong>2. 해킹 등에 대비한 대책</strong>
                            회사는 해킹이나 악성코드 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 통제된 구역에 침입차단/침입탐지/침입방지/웹방화벽 등을 운영하여 24시간 감시하고 있습니다.<br/>
                            최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있습니다. 기술적 보안 수준을 강화하기 위해 가능한 모든 기술적 장치를 갖추려고 노력하고 있습니다.
                        </p>
                        <p>
                            <strong>3. 개인정보 취급자의 최소화 및 교육실시</strong>
                            개인정보를 처리하는 직원을 최소화하며, 개인정보 유출에 대한 위험을 줄이고 있습니다. 또한 개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는
                            권한에 대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다. 또한 개인정보를 처리하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에 대한 정기적인 교육과 캠페인을 실시하고 있습니다.
                        </p>
                    </div>
                </div>
        
                <div id="paragraph_6" className="paragraph_6 paragraph">
                    <h4>
                        <span>바.</span>
                        개인정보 파기절차 및 방법
                    </h4>
                    <p>이용자의 개인정보는 수집 및 이용목적이 달성되면 지체 없이 파기되며, 파기 절차 및 방법은 아래의 기준에 의해 관리됩니다.</p>
                    <ol>
                        <li>
                            <p>
                                회사는 이용자의 회원 탈퇴 요청 시 이용자의 실수 및 변심으로 인한 탈퇴 요청을 취소할 수 있도록 요청일로부터 최대 5일 이후 지체없이 파기합니다.
                                <span>단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                회사는 개인정보 유효기간제에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.
                            </p>
                        </li>
                        <li>
                            <p>
                                회원탈퇴, 서비스 종료, 이용자에게 동의받은 개인정보 보유기간의 도래와 같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다.<br/>
                                법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후 지체없이 재생이 불가능한 방법으로 파기합니다.<br/>
                                전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.
                            </p>
                        </li>
                        <li>
                            <p>파기방법</p>
                            <span>전자적 파일 형태의 정보: 기록을 재생할 수 없는 기술적 방법을 사용하여 파기</span>
                            <span>종이에 출력된 개인 정보: 분쇄기로 분쇄하거나 소각을 통하여 파기</span>
                        </li>
                    </ol>
                </div>

                <div id="paragraph_7" className="paragraph_7 paragraph">
                    <h4>
                        <span>사.</span>
                        개인정보 보호책임자 및 담당부서 안내
                    </h4>
                    <p>회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 고충 처리를 위하여 다음과 같이 책임자와 담당부서를 운영하고 있습니다.</p>
                    <ul className="common">
                        <li><span>개인정보 보호책임자:</span>이창근</li>
                        <li><span>고충 처리부서:</span>(주)마린플라자 고객센터</li>
                        <li><span>연락처:</span>032-837-5388</li>
                        <li><span>이메일:</span>admin@bmcnet.kr</li>
                    </ul>
                    <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
                    <ul className="common">
                        <li>개인정보침해신고센터: <span className="link">http://privacy.kisa.or.kr</span> (국번없이)118</li>
                        <li>대검찰청 사이버수사과: <span className="link">http://spo.go.kr</span> (국번없이) 1301</li>
                        <li>경찰청 사이버수사국: <span className="link">https://ecrm.cyber.go.kr/minwon/main</span> (국번없이) 182</li>
                        <li>개인정보분쟁조정위원회: <span className="link">http://kopico.go.kr</span> 1833-6972</li>
                    </ul>
                </div>

                <div className="paragraph_8 paragraph">
                    <h4>
                        <span>부칙</span>
                    </h4>
                    <p>이 개인정보처리방침은 2023년 07월 19일에 공고되었으며 정부의 정책 및 정보보호 유관법령 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 공지사항을 통해 고지할 것입니다.</p>
                    <span className="common">개인정보처리방침 공고일자: 2023년 07월 19일</span>
                    <span className="common">개인정보처리방침 시행일자: 2023년 07월 19일</span>
                </div>
            </section>
        </div>
        </>
    )
}

export default PrivacyPolicy;