import React, { useState } from "react";
import { useCookies } from "react-cookie";
import i18n from "../../../locale/i18n";
import { getCookie } from "../../../util/cookie/cookies";

export const LangBox = () => {
    const lang = getCookie("MARINELANG");
    const [cookie, setCookie] = useCookies(['MARINELANG']) 
    const [state, setState] = useState<boolean>(false);
    const handleChangeLang = (lang:string) => {
        setCookie('MARINELANG', lang, {path : '/'}); i18n.changeLanguage(lang)
    }
    return(
        <>
        <div className="langBox">
            <button></button>
            <div>
                <span className={lang==='en'?'active':''} onClick={()=>handleChangeLang('en')}>ENG</span>
                <span className={lang==='kr'?'active':''} onClick={()=>handleChangeLang('kr')}>KOR</span>
            </div>
        </div>
        </>
    )
}