import React, { useState } from "react";
import { GoogleMaps } from "../../../../components/google-map/googleMaps";
export interface Props{supplier:any}
export const SupplierInfo:React.FC<Props> = ({supplier}) => {
    const [google, setGoogle] = useState<any>(null)
    
    return(
        <>
        <table>
            <caption>기본정보</caption>
            <tbody>
                <tr>
                    <th>대표자</th>
                    <td>{supplier?.ceoNameEn}</td>
                </tr>
                <tr>
                    <th>업종</th>
                    <td>{supplier?.businessAreaEn}</td>
                </tr>
                <tr>
                    <th>설립일</th>
                    <td>{supplier?.companyYear}</td>
                </tr>
                <tr>
                    <th>규모</th>
                    <td>{supplier?.companySize} people</td>
                </tr>
                <tr>
                    <th>본사</th>
                    <td>{supplier?.companyAddressEn1==='null'?'주소 없음(No Address)':supplier?.companyAddressEn1}</td>
                </tr>
                <tr>
                    <th></th>
                    <td>
                        {supplier?.companyAddressEn1 === 'null' ? '' : 
                        <GoogleMaps address={supplier?.companyAddressEn1} google={google}/>}
                    </td>
                </tr>
                <tr>
                    <th>Contact</th>
                    <td>
                        <a href="">{supplier?.companyEmail}</a>
                        <a href="">{supplier?.companyTel}</a>
                        <a href={`${supplier?.companyWebsite}`} target="_blank">{supplier?.companyWebsite}</a>
                    </td>
                </tr>
            </tbody>
        </table>
        </>
    )
}