import React from "react";
import PageStyles from '../../css/page.module.css'
export interface Props {onClose : Function}
export const IotInformation:React.FC<Props> = ({onClose}) => {

    return(
        <>
        <div className={[PageStyles.popUp_4, PageStyles.active].join(" ")}>
            <iframe src={'http://alink3d.com:3000/d/f4c5cfac-b9bb-4b03-946b-e2885adddf6f/bmc-iot-demo?orgId=1&refresh=auto&from=1698040666595&to=1698044266595'} style={{width : '100%', height:'100%'}}/>
        </div>
        </>
    )
}