import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TagBox } from "../../../components/contents/tagBox";
import { FlipBook } from "../../../components/flipbook/flipBook";
import PdfViewer from "../../../components/pdfView/pdf_viewer";
import { useGetPromotionDetailMutation } from "../../../store/slices/contents/contentsApiSlice";
import Embed from 'react-embed';
export interface Props{onClose : Function; contentId:string}
export const CatalogueView:React.FC<Props> = ({contentId,onClose}) => {
    const navigate = useNavigate()
    const iframeRef = useRef<any>(null)
    const [getContentsDetail, {data, isSuccess, isError}] = useGetPromotionDetailMutation();
    const [previousURL, setPreviousURL] = useState<string | null>(null);
    const handleDownload = (file:any) => {
        const filePath = `https://marineplaza.org${file}`;
        const downloadLink = document.createElement("a");
        downloadLink.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        downloadLink.href = filePath;
        downloadLink.target = "_blank"; // "_self"로 설정하여 현재 창에서 다운로드
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    
    useEffect(()=>{getContentsDetail(contentId)},[])
    
    return(
        <>
        <div className="catalogue_modal active">
            
            <div className="fileBox" onClick={()=>handleDownload(data?.list[0]?.promContentFile)}>
                <a>
                    <span></span>
                </a>
                <span>다운로드</span>
            </div>
            <div className="contentSaveBox">
                <button className="contentSaveBtn">
                    <span></span>
                </button>
                <span>콘텐츠 저장하기</span>
            </div>
            
            <div className="BG" onClick={()=>onClose()}></div>
            <div className="catalogueBox">
                <button className="modal_closeBtn" onClick={()=>onClose()}>
                    <img src="/assets/images/popUp_closeBtn.png" alt="popup close button"/>
                </button>
                <div>
                    <FlipBook embedded={data?.list[0]?.promFlip}/>
                </div>
                <p>{data?.list[0]?.promSubjectEn}</p>
            </div>
        </div>
        </>
    )
}