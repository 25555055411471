import React, { useState } from "react";
import { InquiryInput } from "../../../../../components/modeling/inquiry-input";
import PageStyles from '../../css/page.module.css'
export interface Props{onClose:Function}
export const ModelingInquiry:React.FC<Props> = ({onClose}) => {
    const [data, setData] = useState<any>({
        companyName : '', name : '', email : '', phone : ''
    })
    const handleChange = (e:any) => {
        const {name, type, value} = e.target;
        if(type==='textarea'){setData((prev:any)=>({...prev, [name]:value}))}
        else {setData((prev:any)=>({...prev, [name]:value}))}
    }
    const handleSubmit = (e:any) => {
        e.preventDefault()
        console.log(data)
    }

    return(
        <>
        <div className={[PageStyles.popUp_3, PageStyles.active].join(" ")}>
            <div className={PageStyles.titleBox}>
                <span className={PageStyles.active}>문의하기</span>
            </div>
            <button className={PageStyles.popUpClose_btn}> 
                <img src="/assets/images/modelingCtrl/popUpClose_btn.png" alt="close btn" onClick={()=>onClose()}/>
            </button>
            <form action="" className={PageStyles.captchaForm}>
                <fieldset>
                    <legend>문의하기</legend>		
                    <ul>
                        {Text.map((text:{idx:number,title:string,name:string,placeHolder:string}) => (
                            <InquiryInput key={text.idx} title={text.title} name={text.name} placeHolder={text.placeHolder} onChange={(e:any)=>handleChange(e)}/>
                        ))}
                    </ul>
                    <div>
                        <label htmlFor="">문의내용</label>
                        <textarea className={PageStyles.customInput} name="contents" id=""></textarea>
                    </div>
                    {/* <!-- <div className="captcha"></div> --> */}
                    <button className={PageStyles.contactUs_submitBtn} onClick={handleSubmit}>SUBMIT</button>
                </fieldset>
            </form>
            
        </div>
        </>
    )
}

export const Text = [
    {idx:1, title:'회사명', name:'companyName', placeHolder:'회사명을 입력해 주세요.'},
    {idx:2, title:'이름', name:'name', placeHolder:'이름을 입력해 주세요.'},
    {idx:3, title:'이메일 주소', name:'email', placeHolder:'sample@naver.com'},
    {idx:4, title:'연락처', name:'phone', placeHolder:'000-0000-0000'},
]