import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Hamburger from "../../components/moblie/hamburger";
import FindId from "../user/findId";
import FindPw from "../user/findPw";
import Join from "../user/join";
import Login from "../user/login";
import MobileHeader from "./header_mobile/header";

const MobileLayOut = () => {

    const location = useLocation();

    const loginBtn = location.pathname === '/login' ? false : true;
    const [showhamburger, setShowHamburger] = useState<boolean>(false);

    const handleHamburger = () => {
        setShowHamburger(prev => !prev)
    }
   
    // useEffect(() => {
    //     if (showhamburger) {
    //         document.body.style.overflow = "hidden";
    //     } else {
    //         document.body.style.overflow = "auto";
    //     }
    // }, [showhamburger]);

    useEffect(()=>{
        setShowHamburger(false)
    }, [location])

    return(
        <>
        {/* <div className={showhamburger ? 'mobileBox on' : 'mobileBox'}> */}
        <div className={showhamburger===true?'mobileBox on active':'mobileBox on'} >

            <MobileHeader showBtn={loginBtn} onHamburger={handleHamburger}/>
            {/* {showhamburger && <Hamburger/>} */}
            <Hamburger/>
            {location.pathname === '/login' ? <Login/> : ''}
            {location.pathname === '/join' ? <Join/> : ''}
            {location.pathname === '/findId' ? <FindId/> : ''}
            {location.pathname === '/findPw' ? <FindPw/> : ''}

        </div>
        </>
    )
}

export default MobileLayOut;