import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from "@reduxjs/toolkit/dist/query/react";
import { contentsApi } from './slices/contents/contentsApiSlice';
import { mainContentsApi } from './slices/main/mainContents';
import { modelingApi } from './slices/modeling/modelingApiSlice';
import { productApi } from './slices/modeling/productApiSlice';
import { userApi } from './slices/user/userApiSlice';
import { supplierContentsApi } from './slices/contents/supplierContentsApiSlice';
const reducer = combineReducers({})

export const store = configureStore({
    reducer : {
        [userApi.reducerPath] : userApi.reducer,
        [mainContentsApi.reducerPath] : mainContentsApi.reducer,
        [contentsApi.reducerPath] : contentsApi.reducer,
        [productApi.reducerPath] : productApi.reducer,
        [modelingApi.reducerPath] : modelingApi.reducer,
        [supplierContentsApi.reducerPath] : supplierContentsApi.reducer
    },
    middleware : getMiddleware => getMiddleware().concat(userApi.middleware, mainContentsApi.middleware, contentsApi.middleware, productApi.middleware, modelingApi.middleware, supplierContentsApi.middleware),
    devTools : process.env.NODE_ENV === 'development'
});

setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;