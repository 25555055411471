import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LegalNotice from "../../components/term/legalNotice";
import PrivacyPolicy from "../../components/term/privacyPolicy";
import TermOfUse from "../../components/term/termOfUse";
import { getCookie } from "../../util/cookie/cookies";

const ServiceTerms = () => {
    const {t} = useTranslation()
    const lang = getCookie('MARINELANG')
    const tab  = [{title : '이용약관', type : 'termOfUse'}, {title : '개인정보처리방침', type : 'privacyPolicy'},{title : '책임의 한계와 법적고지', type : 'legalNotice'}]
    const tabRef = useRef<any>([]);
    const [tabType, setTabType] = useState<string>('termOfUse');
    const showTab = (e:React.MouseEvent<HTMLLIElement>, data : {title:string,type:string}) => {
        setTabType(data.type)
        Object.values(tabRef.current).forEach((tabRef: any) => {
            tabRef?.classList.remove('active');
        });
        tabRef.current?.[data.type].classList.add('active');
    }
    useEffect(()=>{
        tabRef.current?.['termOfUse'].classList.add('active')
    },[])
    return(
        <>
        <div className="contentWrap">
            <section className="flexWrap_reverse">
                <div className="reverse_leftBox">
                    <div className="titleBox">
                        <h2>{lang === 'kr' ? '서비스 약관' : 'Terms of Service'}</h2>
                        {lang === 'kr' ? 
                        <p>
                            <span>추가로 궁금한 점이 있으시다면</span>
                            문의탭 이용을 부탁드립니다.
                        </p>:
                        <p>
                            If you have any further questions, <span>please use the 'Inquiry' menu.</span>
                        </p>
                        }
                    </div>
                </div>
                {/* <!-- leftBox --> */}

                <div className="reverse_rightBox">
                    <ul className="categoryTab">
                        {tab.map((data:{title:string,type:string})=>(
                            <li className="" ref={(e)=>tabRef.current[data.type]=e} key={data.type} onClick={(e)=>showTab(e,data)}>
                                {data.title}
                            </li>
                        ))}
                    </ul>

                    {tabType === 'termOfUse' ? <TermOfUse/> : ''}
                    {tabType === 'privacyPolicy' ? <PrivacyPolicy/> : ''}
                    {tabType === 'legalNotice' ? <LegalNotice/> : ''}
                </div>
                {/* <!-- rightBox --> */}
            </section>
        </div>
        </>
    )
}

export default ServiceTerms;