import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoContents } from "../../../components/navigate/navi";
import { getCookie } from "../../../util/cookie/cookies";
import MobileHeader from "../../layout_mobile/header_mobile/header";
import { LangBox } from "./langBox";

const Header = () => {
    const menuList = [
        {idx : 1, title : '3D Product & Component', subTitle : '업체에서 제공하는 설비들의 정보를 확인할 수 있다.', subTitle_en : 'Web-based 3D modeling linked to all content.', type : 206},
        {idx : 2, title : 'Marine News', subTitle : '업체에서 제공하는 설비들의 정보를 확인할 수 있다.' ,subTitle_en : 'The latest news written by major media and suppliers.', type : 207},
        {idx : 3, title : 'Catalog', subTitle : '업체에서 제공하는 설비들의 정보를 확인할 수 있다.' ,subTitle_en : 'Various company profiles, product catalogs, brochures.', type : 208},
        {idx : 4, title : 'Video', subTitle : '업체에서 제공하는 설비들의 정보를 확인할 수 있다.' ,subTitle_en : 'Promotional videos, product & company introductions.', type : 209},
        {idx : 5, title : 'Tech. Document', subTitle : '업체에서 제공하는 설비들의 정보를 확인할 수 있다.' ,subTitle_en : 'Technical documents, theses related to marine technology.', type : 210},
        {idx : 6, title : 'Supplier', subTitle : '업체에서 제공하는 설비들의 정보를 확인할 수 있다.' ,subTitle_en : 'Supplier information, email, phone, address.', type : 211},
    ]
    const {t} = useTranslation()
    const lang = getCookie('MARINELANG')
    const navi = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const path = pathname.split('/');
    const goHome = (e: React.MouseEvent) => { e.preventDefault(); navi('/'); }
    const goInquriy = (e : React.MouseEvent) => { e.preventDefault(); navi('/inquriy'); }
    const goContents = (e:React.MouseEvent, type : number) => {e.preventDefault(); navi(`/contents/${type}`); localStorage.setItem('currentPage', '1');}
    const goLogin = (e:React.MouseEvent) => {e.preventDefault(); navi('/login')}
    const goJoin = (e:React.MouseEvent) => {e.preventDefault(); navi('/join')}
    const goMyPage = (e:React.MouseEvent) => {e.preventDefault(); navi('/mypage/modify')}
    const goMyCollection = (e:React.MouseEvent) => {e.preventDefault(); navi('/mypage/collection/1')}
    const goMyDownLoads = (e:React.MouseEvent) => {e.preventDefault(); navi('/mypage/downloads/3')}
    const goMyInquirys = (e:React.MouseEvent) => {e.preventDefault(); navi('/mypage/inquirys')}
    const goFaq = (e:React.MouseEvent) => {e.preventDefault(); navi('/faq')}
    const goServiceTerms = (e:React.MouseEvent) => {e.preventDefault(); navi('/serviceTerm')}
    return(
        <>
        <header>
            <div className="header_pc">
                <h1>
                    <a href="" onClick={goHome}>
                        logo
                    </a>
                </h1>
                
                <button className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div>
                    <nav>
                        <ul>
                            <li className={path[1] === 'contents' ? 'active' : ''}>
                                <a href="" className="disableLink disableLink1" onClick={useGoContents(206)}><span className="active">CONTENTS<img src={process.env.PUBLIC_URL+"/assets/images/header_arrow_down.png"} alt="arrow"/></span></a>
                                <ul className="sub_menu">
                                   {menuList.map((data:{idx:number,title:string,subTitle:string,subTitle_en:string,type:number}) => (
                                    <li key={data.idx}>
                                        <a href="" onClick={(e)=>goContents(e,data.type)}></a>
                                        <div>
                                            <div className="iconBox">
                                                <span></span>
                                            </div>
                                            <dl>
                                                <dt>{data.title}</dt>
                                                <dd>{lang === 'kr' ? data.subTitle : data.subTitle_en}</dd>
                                            </dl>
                                        </div>
                                    </li>
                                   ))}
                                </ul>
                            </li>
                            <li className={path[1] === 'forums' ? 'active' : ''}>
                                <a href=""><span>FORUMS</span></a>
                            </li>
                            <li className={path[1] === 'inquriy' ? 'active' : ''}>
                                <a href="" onClick={goInquriy}>
                                    <span>INQUIRY</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="rightBox">
                        <div className="header_rightBox_subWrap header_moreWrap">
                            <button className="header_rightBox_btn header_moreBtn">
                                <span></span>
                            </button>
                        
                            <div className="header_rightBox_subBox header_moreBox">
                                {/* <div className="langWrap">
                                    <span className="langChangeBtn">언어변경<span className="arrow_right"></span></span>
                                    <div className="langBox">
                                        <div>
                                            <span className="active">KOR</span>
                                            <span>ENG</span>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <!-- 언어변경 --> */}
    
                                <a href="" onClick={goFaq}>{lang === 'kr' ? '자주 묻는 질문' : 'FAQ'}<span className="arrow_right"></span></a>
                                {/* <!-- 자주 묻는 질문 --> */}
    
                                <a href="" onClick={goServiceTerms}>{lang === 'kr' ? '서비스 약관' : 'Terms of Service'}<span className="arrow_right"></span></a>
                                {/* <!-- 서비스 약관 --> */}
    
                                <div className="header_slider">
                                    {/* <!-- 반복구간 --> */}
                                    <div className="header_slider_logoList">
                                        <a href=""></a>
                                        <img src="/assets/images/famility_site_logo_BMC.png" alt="BMC_logo"/>
                                    </div>
                                </div>
                                <ul className="custom_dots">
                                    <li className="active">
                                        <button></button>
                                    </li>
                                    <li>
                                        <button></button>
                                    </li>
                                    <li>
                                        <button></button>
                                    </li>
                                </ul>
                                {/* <!-- 로고 슬라이더 --> */}
                            </div>
                        </div>
                        {/* <!-- 더보기 박스 --> */}

                        <LangBox/>
    
                        <div className="header_rightBox_subWrap header_userWrap">
                            
                            {/* <button className="header_rightBox_btn header_userBtn login_before">
                                <span></span>
                            </button>
                            
                            <div className="header_rightBox_subBox header_userBox login_before">
                                <a href="" onClick={goLogin}>{lang === 'kr' ? '저장한 콘텐츠' : 'Saved Contents'}<span className="arrow_right"></span></a>
                                
                                <a href="" onClick={goLogin}>{lang === 'kr' ? '다운로드' : 'Download'}<span className="arrow_right"></span></a>
    
                                <a href="" onClick={goLogin}>{lang === 'kr' ? '문의리스트' : 'Inquiry List'}<span className="arrow_right"></span></a>    
                                <div className="loginBox">
                                    <a href="" onClick={goLogin}>{lang === 'kr' ? '로그인' : 'Login'}</a>
                                    <a href="" onClick={goJoin}>{lang === 'kr' ? '회원가입' : 'Join'}</a>
                                </div>
                            </div> */}

                            <button className="header_rightBox_btn header_userBtn login_after">
                                <span style={{backgroundImage : 'url("/assets/images/user_profile_sample.png")'}}></span>
                            </button>

                            <div className="header_rightBox_subBox header_userBox login_after">
                                <a href="" onClick={goMyPage}>{lang === 'kr' ? '내 정보 수정' : 'Modify'}<span className="arrow_right"></span></a>

                                <a href="" onClick={goMyCollection}>{lang === 'kr' ? '저장한 콘텐츠' : 'Saved Contents'}<span className="arrow_right"></span></a>
                                
                                <a href="" onClick={goMyDownLoads}>{lang === 'kr' ? '다운로드' : 'Download'}<span className="arrow_right"></span></a>
    
                                <a href="" onClick={goMyInquirys}>{lang === 'kr' ? '문의리스트' : 'Inquiry List'}<span className="arrow_right"></span></a>
    
                                <div className="loginBox">
                                    <a href="">{lang === 'kr' ? '로그아웃' : 'Logout'}</a>
                                </div>
                            </div>  
                           
                        </div>
                        {/* <!-- 사용자 박스 --> */}
                    </div>
                </div>
            </div>
        </header>
        </>
    )
}

export default Header;