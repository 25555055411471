import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetAssembleSpecMutation } from "../../../../../../store/slices/modeling/productApiSlice";
import PageStyles from '../../../css/page.module.css'
export const Spec = () => {
    const {modelingId, modeling} = useParams()
    const [getAssembleSpec, {data:specData, isSuccess : success, isError}] = useGetAssembleSpecMutation()
    useEffect(()=>{getAssembleSpec({modelingId})}, [])
    
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_1, PageStyles.active].join(" ")}>
            <div className={PageStyles.titleBox} >
                <span></span>
                <p>{modeling?.split('_')?.slice(2).join('_')}</p>
            </div>
            <div className={PageStyles.txtBox}>
                <div dangerouslySetInnerHTML={{__html: specData?.list?.modeling_specification_kr}}></div>
            </div>
        </div>
        </>
    )
}