import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VideoPlayer from "../../../../../../components/youtube/react-youtube";
import { useGetAssembleVideoMutation } from "../../../../../../store/slices/modeling/productApiSlice";
import PageStyles from '../../../css/page.module.css'

export const Video = () => {
    const {modelingId} = useParams()
    const [getAssembleVideo, {data:videoData, isSuccess:success, isError:error}] = useGetAssembleVideoMutation()
    const [videoInfo, setVideoInfo] = useState<any>({kr:[], en:[]})
    useEffect(()=>{getAssembleVideo({modelingId})},[])
    useEffect(()=>{
        if(success){
            setVideoInfo({...videoInfo,
                kr:videoData?.list?.filter((item:any)=>item.video_lang==='KR'), en:videoData?.list?.filter((item:any)=>item.video_lang==='EN')
            })
        }
    }, [success])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_2, PageStyles.active].join(" ")}>
            <ul className={PageStyles.videoWrap}>

                {videoInfo?.kr?.map((video:{idx:string;video_lang:string;video_title:string;video_url:string}) => (
                <li key={video.idx}>
                    <div className={PageStyles.videoArea}>
                        <VideoPlayer url={video?.video_url}/>
                    </div>
                    <div className={PageStyles.videoTitleBox}>
                        <div>
                            <p className={PageStyles.video_title}>{video?.video_title}</p>
                            {/* <p className={PageStyles.video_text}>홍보영상에 대한 영상 소개 문구입니다.</p> */}
                        </div>
                    </div>
                </li>
                ))}
            </ul> 

            {/* <div className={PageStyles.none_content}>
                <p>
                    <span>아직 등록된 콘텐츠가 없습니다.</span>
                    해당 정보가 필요할 경우 문의주세요.
                </p>
                <button>문의하기</button>
            </div> */}
            

        </div>
        </>
    )
}