import React from "react";
import YoutubePlayer from "../../../components/youtube/react-youtube";

export interface Props { videoUrlkr : string, subjectKr : string , onClose : Function }

const VideoView:React.FC<Props> = ({videoUrlkr, subjectKr, onClose}) => {
    const videoOption = {
        height: "3900",
        width: "640",
        playerVars: {
            autoplay: 0,
        },
    }
    return(
        <>
        <div className="video_modal active">
            <div className="BG" onClick={()=>onClose()}></div>
            <div className="videoBox">
                <button className="modal_closeBtn" onClick={()=>onClose()}>
                    <img src="/assets/images/popUp_closeBtn.png" alt="popup close button"/>
                </button>
                <div>
                    {videoUrlkr&&
                    <YoutubePlayer url={videoUrlkr}/>}
                </div>
                {/* <iframe src={videoUrlkr} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                <p>{subjectKr}</p>
            </div>
        </div>
        </>
    )
}

export default VideoView;