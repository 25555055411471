import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import LayOut from './pages/layout';
import MobileLayOut from './pages/layout_mobile';
import ModelingCtrl from './pages/contents/view/3DmodelingView';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { getCookie } from './util/cookie/cookies';
import './locale/i18n'

function App() {
  const [cookies, setCookies] = useCookies(['MARINELANG']);
  const {t,i18n} = useTranslation();
  const lang = getCookie('MARINELANG')
  const location = useLocation()
  useEffect(()=>{
    if(lang === undefined){setCookies('MARINELANG', 'en', {path : '/'}); i18n.changeLanguage('en')}
    if(lang === 'kr'){i18n.changeLanguage('kr')} 
    else {i18n.changeLanguage('en')}
  }, [lang, setCookies])
  return (
    <>
      <Routes>
        <Route path='/' element={<LayOut/>}></Route>
        <Route path='/contents/:contentsType' element={<LayOut/>}></Route>
        <Route path='/contents/news/:contentsType/:newsId' element={<LayOut/>}></Route>
        <Route path='/contents/supplier/:contentsType/:supplierId/:tab' element={<LayOut/>}></Route>
        <Route path='/contents/supplier/:contentsType/:supplierId/:tab/:supplierContents' element={<LayOut/>}></Route>
        <Route path='/contents/techDoc/:contentsType/:techId' element={<LayOut/>}></Route>
        <Route path='/contents/catalogue/:contentsType/:catalogueId' element={<LayOut/>}></Route>
        <Route path='/inquriy' element={<LayOut/>}></Route>
        <Route path='/faq' element={<LayOut/>}></Route>
        <Route path='/serviceTerm' element={<LayOut/>}></Route>

        <Route path='/login' element={<MobileLayOut/>}></Route>
        <Route path='/join' element={<MobileLayOut/>}></Route>
        <Route path='/findId' element={<MobileLayOut/>}></Route>
        <Route path='/findPw' element={<MobileLayOut/>}></Route>

        <Route path='/mypage/modify' element={<LayOut/>}></Route>
        <Route path='/mypage/collection/:contentsType' element={<LayOut/>}></Route>
        <Route path='/mypage/downloads/:contentsType' element={<LayOut/>}></Route>
        <Route path='/mypage/inquirys' element={<LayOut/>}></Route>

        <Route path='/modeling/:contentsType/:idx/:modelingId/:companyId/:modeling' element={<ModelingCtrl/>}></Route>
      </Routes>
    </>
  );
}



export default App;
