import React, { useRef, useState } from "react";
import AnimateHeight from "react-animate-height";

const UserInquirys = () => {
    // sample Data
    const inquriyData = [
        {idx : 1, title : '콘텐츠 건', company : '주식회사 비엠씨', name : '백지희', email : 'adrian1@bmcnet.kr', phone : '010.111.1111', inquriyType : ['콘텐츠 업로드 요청2','콘텐츠 수정요청'], contentsType : 'Catalogue', contents : '안녕하세요.', file : '수정자료', date : '2023.07.21'},
        {idx : 2, title : '디케이락 건', company : '디케이락', name : '김윤진', email : 'adrian2@bmcnet.kr', phone : '010.222.2222', inquriyType : ['콘텐츠 업로드 요청1'], contentsType : 'Catalogue', contents : '안녕하세요.', file : '수정자료', date : '2023.07.23'},
        {idx : 3, title : '디케이락 건', company : '디케이락', name : '이창근', email : 'adrian2@bmcnet.kr', phone : '010.222.2222', inquriyType : ['콘텐츠 업로드 요청1'], contentsType : 'Catalogue', contents : '안녕하세요.', file : '수정자료', date : '2023.07.23'},
        {idx : 4, title : '디케이락 건', company : '디케이락', name : '이시모', email : 'adrian2@bmcnet.kr', phone : '010.222.2222', inquriyType : ['콘텐츠 업로드 요청1'], contentsType : 'Catalogue', contents : '안녕하세요.', file : '수정자료', date : '2023.07.23'},
        {idx : 5, title : '디케이락 건', company : '디케이락', name : '황선일', email : 'adrian2@bmcnet.kr', phone : '010.222.2222', inquriyType : ['콘텐츠 업로드 요청1'], contentsType : 'Catalogue', contents : '안녕하세요.', file : '수정자료', date : '2023.07.23'},
        {idx : 6, title : '디케이락 건', company : '디케이락', name : '고건희', email : 'adrian2@bmcnet.kr', phone : '010.222.2222', inquriyType : ['콘텐츠 업로드 요청1'], contentsType : 'Catalogue', contents : '안녕하세요.', file : '수정자료', date : '2023.07.23'}
    ]

    const [height, setHeight] = useState<any>(0);
    const isActiveRef = useRef<any>([]);
    const [activeItem, setActiveItem] = useState<number | null>(null);

    const handleSelList = (data : number) => {
        // slider animate
        if (activeItem === data) {
            setActiveItem(null);
          } else {
            setActiveItem(data);
          }
        // toggle 형태
        if(isActiveRef.current[data].classList.contains('active')){
            isActiveRef.current?.[data].classList.remove('active');
            return;
        }
        // 한쪽이 active 들어오면 다른 쪽은 active 제거
        Object.values(isActiveRef.current).forEach((isActiveRef: any) => {
            isActiveRef?.classList.remove('active');
        });
        isActiveRef.current?.[data].classList.add('active')
    }

    return(
        <>
        <section className="flexWrap myPage_common">
            <div className="inquirys">
                <div className="sortWrap">
                    <h2>Inquiry</h2>
                    <div>
                        <span>16 Total</span>
                        <span>Sort by:</span>
                        <div className="sortBox">
                            <span>Latest first</span>
                            <div className="sortHiddenBox">
                                <span>Sorter</span>
                                <ul>
                                    <li>
                                        <input type="checkbox" name="" id="latest_first" className="blueInput"/>
                                        <label htmlFor="latest_first" className="blueLabel">Latest first</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="" id="oldest_first" className="blueInput"/>
                                        <label htmlFor="oldest_first" className="blueLabel">Oldest_first</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="" id="view_count" className="blueInput"/>
                                        <label htmlFor="view_count" className="blueLabel">View count</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="" id="random" className="blueInput"/>
                                        <label htmlFor="random" className="blueLabel">Random</label>
                                    </li>
                                </ul>
                                <div className="toggleBtn_wrap">
                                    <span className="toggleSwitch">
                                        <input type="checkbox" id="toggle_sample" className="toggleIpt" hidden/> 
                                        <label htmlFor="toggle_sample" className="toggleSwitch">
                                            <span className="toggleButton"></span>
                                        </label>
                                    </span>
                                    <label htmlFor="toggle_sample">Sorting saved content first</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ul className="inquiryCategory_tab">
                    <li className="active">
                        <span>All</span>
                    </li>
                    <li>
                        <span>
                            Website
                            <span>(0)</span>
                        </span>
                    </li>
                    <li>
                        <span>
                            Products
                            <span>(0)</span>
                        </span>
                    </li>
                    <li>
                        <span>
                            Supplier
                            <span>(0)</span>
                        </span>
                    </li>
                    <li>
                        <span>
                            etc.
                            <span>(0)</span>
                        </span>
                    </li>
                </ul>

                <ul className="inquiryListBox">
                    {inquriyData && inquriyData.map((data : any) => (
                    <li key={data.idx} onClick={(e)=>handleSelList(data.idx)}>
                        <div className="titleBox" ref={(e)=>isActiveRef.current[data.idx]=e} aria-expanded={height !== 0} onClick={()=>{setHeight(height === 0 ? 'auto' : 0)}} style={{cursor : 'pointer'}}>
                            <span className="category">Website</span>
                            <p>{data.title}</p>
                            <span className="date">{data.date}</span>
                            <span className="arrow">
                                <i className="fa-sharp fa-regular fa-arrow-up"></i>
                            </span>
                        </div>
                        <AnimateHeight duration={500} height={activeItem === data.idx ? "auto" : 0} style={{marginTop : '20px'}}>
                        <div className="inquiry_contentBox">
                            <ul className="inquiry_writer">
                                <li>
                                    <p>
                                        <strong>회사명:</strong>
                                        <span>{data.company}</span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>이름 및 직책:</strong>
                                        <span>{data.name}</span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>이메일:</strong>
                                        <span>{data.email}</span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>연락 가능한 번호:</strong>
                                        <span>{data.phone}</span>
                                    </p>
                                </li>
                            </ul>
                            
                            <ul className="inquiry_content">
                                <li>
                                    <div>
                                        <span>문의유형</span>
                                    </div>
                                    <div>
                                        {data.inquriyType.map((type : any) => (
                                            <span className="blue" key={type}>
                                                {type}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span>콘텐츠 유형</span>
                                    </div>
                                    <div>
                                        <span className="black">{data.contentsType}</span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span>문의내용</span>
                                    </div>
                                    <div>
                                        <p>추가적으로 필요한 파일이 있을 경우 연락주세요.</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span>첨부파일</span>
                                    </div>
                                    <div className="fileBox">
                                        <p>{data.file}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        </AnimateHeight>
                    </li>
                    ))}
                </ul>
            </div>

        </section>
        </>
    )
}

export default UserInquirys;