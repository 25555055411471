import React from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../util/cookie/cookies";

const Faq = () => {
    const {t} = useTranslation()
    const lang = getCookie('MARINELANG')
    return(
        <>
        <section className="flexWrap_reverse">
            <div className="reverse_leftBox">
                <div className="titleBox">
                    <h2>{lang === 'kr' ? '자주 묻는 질문' : 'FAQ'}</h2>
                    {lang === 'kr' ? 
                    <p>
                        <span>추가로 궁금한 점이 있으시다면</span>
                        문의탭 이용을 부탁드립니다.
                    </p>:
                    <p>
                        If you have any further questions, <span>please use the 'Inquiry' menu.</span>
                    </p>
                    }
                    <button>{lang === 'kr' ? '문의하기' : 'INQUIRY'}</button>
                </div>

                <div className="qna_pager">
                    <button className="left"></button>
                    <div className="numbBox">
                        <span className="current">01</span>
                        <span className="dot"></span>
                        <span className="all">05</span>
                    </div>
                    <button className="right"></button>
                </div>
            </div>

            <div className="reverse_rightBox">
                <ul className="categoryTab">
                    <li className="active">All</li>
                    <li>Category01</li>
                    <li>Category02</li>
                    <li>Category03</li>
                    <li>Category04</li>
                    <li>Category05</li>
                </ul>
                <div className="qnaListBox">
                    <ul>
                        {/* <li className="active">
                            <div className="question">
                                <p>
                                    <span>Q</span>
                                    마린플라자에서 자주 하는 질문 리스트
                                </p>
                                <span className="arrow">
                                    <i className="fa-sharp fa-regular fa-arrow-up"></i>
                                </span>
                            </div>
                            <div className="answer">
                                <p>
                                    <span>A</span>
                                    마린플라자에서 자주 하는 질문 리스트에 대한 답변
                                    문의 리스트와 달리 border-bottom 색상이 다릅니다.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="question">
                                <p>
                                    <span>Q</span>
                                    마린플라자에서 자주 하는 질문 리스트
                                </p>
                                <span className="arrow">
                                    <i className="fa-sharp fa-regular fa-arrow-up"></i>
                                </span>
                            </div>
                            <div className="answer">
                                <p>
                                    <span>A</span>
                                    마린플라자에서 자주 하는 질문 리스트에 대한 답변
                                    문의 리스트와 달리 border-bottom 색상이 다릅니다.
                                </p>
                            </div>
                        </li> */}
                    </ul>
                </div>
            </div>
        </section>
        </>
    )
}

export default Faq;