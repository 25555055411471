import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../util/cookie/cookies";

const Inquriy = () => {

    const requestList = [{name : 'question1_1', value : '1', title : '콘텐츠 업로드 요청', title_en : 'Request for Content Upload'}, {name : 'question1_2', value : '2', title : '콘텐츠 수정 요청', title_en : 'Request for Content Modification'},
    {name : 'question1_3', value : '3', title : '콘텐츠 제작 문의', title_en : 'Inquiry about Content Creation'}, {name : 'question1_4', value : '4', title : '카테고리 추가 요청', title_en : 'Request for Adding Categories'}, {name : 'question1_5', value : '5', title : '포럼 주제 등록 요청', title_en : 'Request for Registering Forum Topics'},
    {name : 'question1_6', value : '6', title : '광고문의', title_en : 'Advertising Inquiry'}, {name : 'question1_7', value : '7', title : '기타문의', title_en : 'Other Inquiries'},]

    const contentsType = [{name : 'question2_1', value : '1', title : '3D Products & Component'}, {name : 'question2_2', value : '2', title : 'Marine News'},
    {name : 'question2_3', value : '3', title : 'Catalogue'},{name : 'question2_4', value : '4', title : 'Video'},
    {name : 'question2_5', value : '5', title : 'Tech. Document'},{name : 'question2_6', value : '6', title : 'Supplier'}]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const isActiveRef = useRef<any>()
    // inital State
    const [param, setParam] = useState<any>({
        question1 : [], question2 : [], file : [], 
        requestorCompany : '', requestorName : '', requestorEmail : '', requestorPhone : '',
        requestTitle : '', requestContents : '', 
        marketingAgree : false, privacyAgree : false
    })
    const lang = getCookie('MARINELANG')
    const {t} = useTranslation()
    // state ChangeEvent
    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked, type, value} = e.target;
        // type Checked
        if(type === 'checkbox') {
            const group = name.split('_')[0];
            if(name === 'marketingAgree' || name === 'privacyAgree'){
                setParam({
                    ...param, [name] : checked
                })
            } else {
                setParam((prev:any) => ({
                    ...prev,
                    [group] : checked ? [...prev[group], value] : prev[group].filter((item:any) => item !== value)
                }))
            }
        }
        // type Text
        else {setParam((prev:any) => ({
            ...prev, [name] : value
        }))};
    }
    // state ChangeEvnet : TextArea
    const handleTextAreaChange = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        setParam({
            ...param, requestContents : e.target.value
        })
    }
    // active handler
    useEffect(()=>{
        if(param.question1.length > 0 && param.question2.length > 0 &&
            param.requestorCompany !=='' && param.requestorName !== '' && param.requestorEmail !== '' &&
            param.requestorPhone !== '' && param.requestTitle !== '' && param.requestContents !== '' && param.privacyAgree == true){
            isActiveRef.current?.classList.add('active');
        } else { isActiveRef.current?.classList.remove('active'); }
    }, [param])
    return(
        <>
        <div className="contentWrap inquiry">
            <div>
                {lang === 'kr' ? 
                <h2>
                    <span>마린플라자에서는 플랫폼 이용에 필요한</span>
                    모든 것을 지원해드리고 있습니다.
                </h2>:
                <h2>
                    At Marine Plaza, we provide all the support
                    <span> you need for using the platform.</span>
                </h2>
                }
            </div>
            <fieldset className="flexWrap">
                <legend>{t("inquiry_title_3")}</legend>
                <div className="question_1">
                    <h3>
                        <span>01</span>
                        {t("inquiry_sub_title_1")}
                    </h3>
                    <ul className="checkList">
                        {requestList.map((data:{name:string, value : string, title : string, title_en : string}) => (
                            <li key={data.name}>
                                <input type="checkbox" name={data.name} id={data.name} value={data.value} onChange={(e)=>handleChange(e)}/>
                                <label htmlFor={data.name}>{lang === 'kr' ? data.title : data.title_en}</label>
                            </li>
                        ))}
                    </ul>
                </div>
                
                <div className="question_2">
                    <h3>
                        <span>02</span>
                        {t("inquiry_sub_title_2")}
                    </h3>
                    <ul className="checkList">
                        {contentsType.map((data:{name:string,value:string,title:string})=> (
                            <li key={data.name}>
                                <input type="checkbox" name={data.name} id={data.name} value={data.value} onChange={(e)=>handleChange(e)}/>
                                <label htmlFor={data.name}>{data.title}</label>
                            </li>
                        ))}
                    </ul>
                </div>
                
                <div className="question_3">
                    <h3>
                        <span>03</span>
                        {t("inquiry_sub_title_3")}
                    </h3>
                    <p>{t("inquiry_sub_title_4")}</p>
                    <div className="fileUploadBox active">
                        <div>
                            <span className="upload_name">Uploaded file name</span>
                            <button><img src="/assets/images/popUp_closeBtn.png" alt="closeBtn"/></button>
                        </div>
                        <label htmlFor="fileUpload" className="active"></label>
                        <input type="file" id="fileUpload" placeholder="압축파일 업로드"/>
                    </div>
                </div>

                <div className="question_4">
                    <h3>
                        <span>04</span>
                        {t("inquiry_sub_title_5")}
                    </h3>
                    <p>* {t("inquiry_sub_title_6")}</p>
                    <ul className="inputList">
                        <li>
                            <label htmlFor="question4_1" className="inquiry_label">
                                <img src="/assets/images/inquiry_inputList1.png" alt="회사명"/>
                            </label>
                            <input type="text" name="requestorCompany" id="question4_1" className="inquiry_input" placeholder={lang === 'kr' ? "회사명 *" : "Company Name *"} onChange={handleChange}/>
                        </li>
                        <li>
                            <label htmlFor="question4_2" className="inquiry_label">
                                <img src="/assets/images/inquiry_inputList2.png" alt="이름 및 직책"/>
                            </label>
                            <input type="text" name="requestorName" id="question4_2" className="inquiry_input" placeholder={lang === 'kr' ? "이름 및 직책 *" : "Name and Title *"} onChange={handleChange}/>
                        </li>
                        <li>
                            <label htmlFor="question4_3" className="inquiry_label">
                                <img src="/assets/images/inquiry_inputList3.png" alt="이메일"/>
                            </label>
                            <input type="text" name="requestorEmail" id="question4_3" className="inquiry_input" placeholder={lang === 'kr' ? "이메일 *" : "Email *"} onChange={handleChange}/>
                        </li>
                        <li>
                            <label htmlFor="question4_4" className="inquiry_label">
                                <img src="/assets/images/inquiry_inputList4.png" alt="연락 가능한 번호"/>
                            </label>
                            <input type="text" name="requestorPhone" id="question4_4" className="inquiry_input" placeholder={lang === 'kr' ? "연락 가능한 번호 *":"Contact Number *"} onChange={handleChange}/>
                        </li>
                    </ul>
                </div>

                <div className="question_5">
                    <h3>
                        <span>05</span>
                        {t("inquiry_sub_title_7")}
                    </h3>
                    <div>
                        <div className="inquiryTitle">
                            <label htmlFor="inquiry_title" className="inquiry_label">
                                <img src="/assets/images/inquiry_title.png" alt="inquiry title"/>
                            </label>
                            <input type="text" name="requestTitle" id="inquiry_title" className="inquiry_input" placeholder={lang === 'kr' ? '문의제목 *':'Title of Inquiry'} onChange={handleChange}/>
                        </div>
                        <div className="inquiryTxt">
                            <label htmlFor="inquiry_txt" className="inquiry_label">
                                <img src="/assets/images/inquiry_txt.png" alt="inquiry text"/>
                            </label>
                            <textarea name="requestContents" id="inquiry_txt" placeholder={lang === 'kr' ? '문의내용 *':'Inquiry Text'} onChange={handleTextAreaChange}></textarea>
                        </div>
                    </div>
                </div>

                <div className="question_6">
                    <h3>
                        <span>06</span>
                        {t("inquiry_sub_title_8")}
                    </h3>
                    <div className="checkList">
                        <div>
                            <input type="checkbox" name="privacyAgree" id="inquiry_agree1" onChange={handleChange}/>
                            <label htmlFor="inquiry_agree1">{t("inquiry_sub_title_9")} <span>({t("inquiry_sub_title_10")})</span></label>
                        </div>
                        <div>
                            <input type="checkbox" name="marketingAgree" id="inquiry_agree2" onChange={handleChange}/>
                            <label htmlFor="inquiry_agree2">{t("inquiry_sub_title_11")} <span>({t("inquiry_sub_title_12")})</span></label>
                        </div>
                    </div>
                </div>
                
                <div className="inquiry_submitBox">
                    <button type="button" ref={isActiveRef} className="inquiry_submit">
                        <img src="/assets/images/inquiry_submit.png" alt="summit button"/>
                    </button>
                </div>
            </fieldset>
        </div>
        </>
    )
}

export default Inquriy;