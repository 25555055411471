import React from "react";
import { useNavigate } from "react-router-dom";

export interface Props {
    showBtn : boolean
    onHamburger : Function
}
const MobileHeader:React.FC<Props> = ({showBtn, onHamburger}) => {
    const navi = useNavigate()
    const loginBtn = showBtn ? <button className="mobile_loginBtn" onClick={()=>navi('/login')}>로그인</button> : <></> ;
    const goHome = (e:React.MouseEvent) => {e.preventDefault(); navi('/')}
    return(
        <>
        <div className="mobile_header">
            <h1>
                <a href="" onClick={goHome}>
                    logo
                </a>
            </h1>

            <button className="hamburger" onClick={()=>onHamburger()}>
                <span></span>
                <span></span>
                <span></span>
            </button>
            
            {loginBtn}
        </div>
        </>
    )
}

export default MobileHeader;