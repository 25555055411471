import React from "react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const modelingApi : any = createApi({
    reducerPath : 'modelingApi',
    baseQuery : fetchBaseQuery({baseUrl : 'https://marineplaza.org/api/controller/users',}),

    endpoints : (builder : any) => ({
        // 회사 로고 가져오기
        getCompanyLogo : builder.mutation({
            query : (body : {companyId : string}) => {
                return{
                    url:`/contents/getCompanyLogo.php?companyId=${body.companyId}`
                }
            },
        }),
        // 해당 모델링 & 회사 컨텐츠 리스트 불러오기
        getContentsBySupplier : builder.mutation({
            query : (param:{companyId : string}) => {
                return{url:`/alink3d/getContentsBySupplier.php?companyId=${param.companyId}`}
            }
        })
    })
})

export const {useGetCompanyLogoMutation, useGetContentsBySupplierMutation} = modelingApi;