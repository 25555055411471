import React from "react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const productApi : any = createApi({
    reducerPath : 'productApi',
    baseQuery : fetchBaseQuery({baseUrl : 'http://akadmin.alink3d.com/api/controller/admin',}),

    endpoints : (builder : any) => ({
        getAssembleSpec : builder.mutation({
            query : (body : {modelingId : string}) => {
                return{url : `/getAssembleSpec.php?AssetModelingId=${body.modelingId}`, method: 'get'}
            },
        }),
        getAssembleVideo : builder.mutation({
            query:(param:{modelingId:string})=>{
                return{url:`/getAssembleVideo.php?AssetModelingId=${param.modelingId}`}
            }
        }),
        getAssembleCatalogue : builder.mutation({
            query:(param:{modelingId:string})=>{
                return{url:`/getAssembleBrouchure.php?AssetModelingId=${param.modelingId}`}
            }
        }),
        getAssemblePerformance : builder.mutation({
            query:(param:{modelingId:string})=>{
                return{url:`/getAssemblePerformance.php?AssetModelingId=${param.modelingId}`}
            }
        }),
        getAssembleTrouble : builder.mutation({
            query:(param:{modelingId:string})=>{
                return{url:`/getAssembleTrouble.php?AssetModelingId=${param.modelingId}`}
            }
        }),
        getAssembleMemo : builder.mutation({
            query:(param:{modelingId:string})=>{
                return{url:`/getAssembleMemo.php?AssetModelingId=${param.modelingId}`}
            }
        })
    })
})

export const {useGetAssembleSpecMutation, useGetAssembleVideoMutation , useGetAssembleCatalogueMutation, useGetAssemblePerformanceMutation,
useGetAssembleTroubleMutation, useGetAssembleMemoMutation} = productApi;