import React from "react";
export interface Props {fileUrl : string;fileName:string;}
export const FileDownLoad:React.FC<Props> = ({fileUrl,fileName}) => {
    const handleDownload = (file:string) => {
        const fileUrl: any = file;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    return(
        <>
        <div onClick={()=>handleDownload(fileUrl)}>
            <span><img src="/assets/images/modelingCtrl/popUp1_download1.png" alt="download"/></span>
            <a target="_blank"><input name="" value={fileName} placeholder="등록할 트러블슈팅 첨부 파일을 업로드하시기 바랍니다."/></a>
        </div>
        <div onClick={()=>handleDownload(fileUrl)}>
            <a><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
        </div>
        </>
    )
}