import i18n from 'i18next';
import languageKr from './kr/language.json'
import languageEn from './en/language.json'
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    lng: "en",
    resources: {
      kr: {
        translation: languageKr
      },
      en: {
        translation: languageEn
      },
    },
    fallbackLng: "kr",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
