import React from "react";
import { getCookie } from "../../../util/cookie/cookies";

const Footer = () => {
    const lang = getCookie('MARINELANG')
    return(
        <>
        <footer>
            <div> 
                <div>
                    <div>
                        <a>
                            <img src={process.env.PUBLIC_URL + '/assets/images/marinePlaza_footer_logo.png'} alt="footer logo"/>
                        </a>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p><strong>MARINE PLAZA</strong></p>
                            </li>
                            <li>
                                <p><strong>{lang === 'kr' ? '대표이사' : 'CEO'}</strong>{lang === 'kr' ? '황선일':'Cecilia, Hwang'}</p>
                            </li>
                            <li>
                                <p><strong>{lang === 'kr' ? '개인정보담당자' : 'CPO'}</strong>{lang === 'kr' ? '이창근' : 'Adrian, Lee'}</p>
                            </li>
                            <li>
                                <p><strong>{lang === 'kr' ? '사업자등록번호' : 'Company Registration Number'}</strong>883-88-01284</p>
                            </li>
                        </ul>
                        <address>[21990] {lang === 'kr' ? '인천 연수구 송도미래로 30, 송도스마트밸리 E동 2305호' : '30, Songdomirae-ro, Yeonsu-gu, Incheon, Republic of Korea'}</address>
                        <div>
                            <p><strong>Email</strong><a href="mailto:admin@bmcnet.kr">admin@bmcnet.kr</a></p>
                            <p><strong>Tel</strong><a href="tel:+0328375388">032.837.5388</a></p>
                            <p><strong>Fax</strong>032.837.5387</p>
                        </div>
                        
                    </div>
                    <div>
                        {/* <!-- <ul> */}
                            {/* <li>
                                <a href=""></a>
                            </li>
                            <li>
                                <a href=""></a>
                            </li>
                            <li>
                                <a href=""></a>
                            </li>
                            <li>
                                <a href=""></a>
                            </li>
                        </ul> --> */}
                        <p>Copyright © BMC All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}

export default Footer;