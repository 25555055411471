import React, { useEffect, useState } from "react";
export interface Props { url : string}
const extractVideoId1 = (url : string) => {
    const regex = /(?:\/(?:embed|watch|v|vi)|youtu\.be)\/([A-Za-z0-9_-]+)/;
    const match = url.match(regex);
    return match ? match[1] : '';
};
const extractVideoId2 = (url: string) => {
    const regex = /[?&]v=([^?&]+)/;
    const match = url.match(regex);
    return match ? match[1] : '';
};
const VideoPlayer:React.FC<Props> = ({url}) => {
    
    const [videoId, setVideoId] = useState<string>('')
    useEffect(()=>{
        if (url.includes("youtube.com/watch?v=")){
            const fomatUrlId = extractVideoId2(url)
            setVideoId(fomatUrlId)
        } else {
            const fomatUrlId = extractVideoId1(url)
            setVideoId(fomatUrlId)
        }
    }, [url])

    return(
        <>
            <iframe src={`https://www.youtube.com/embed/${videoId}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </>
    )
}

export default VideoPlayer;

