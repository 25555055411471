export const styles= {
    menu : (provided : any) => ({
        ...provided, backgroundColor : 'white', marginTop : '0px'
    }),
    control : (baseStyle : any, state : any) => ({
        ...baseStyle,
        backgroundColor : 'none', width : '240px', height : '60px', borderWidth : '0', borderBottom : state.isFocused ? '2px solid #1b6add' : '2px solid #1b6add', borderRadius : '0%',
        '&:hover': {
            borderColor: '#1b6add'
        }, 
        boxShadow : '0', cursor : 'pointer'
    }),
    indicatorSeparator : (baseStyle : any, state : any) => ({
        ...baseStyle,
        display : 'none', fill : '#1b6add'
    }),
    indicatorsContainer : (baseStyle : any, state : any) => ({
        ...baseStyle, 
    }),
    dropdownIndicator : (base : any, state : any) => ({
        ...base,
        color: '#1b6add'
    }),
    placeholder : () => ({
        color : '#1b6add', paddingBottom : '15px', paddingLeft : '10px', fontSize : '16px'
    }),
    menuList : (base : any, state : any) => ({
        cursor : 'pointer', fontSize : '14px', 
    }),
    singleValue : (base : any, props : any) => ({
        color : '#1b6add', paddingBottom : '15px', paddingLeft : '10px', fontSize : '15px'
    }),
    // option : (base, state) => ({
    //     ...base,
    //      fontSize : '14px', padding : '15px, 15px, 20px, 0'
    // }),
}