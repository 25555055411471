import React from "react";
import { MoreBtn } from "./more-btn";
import { useGetSupplierContentsMoreMutation } from "../../../store/slices/contents/supplierContentsApiSlice";
import { TagBox } from "../tagBox";
import { useParams } from "react-router-dom";
export interface Props1 {
    name : string, data : any
}
export const ModelingInitUnit:React.FC<Props1> = ({name, data}) => {
    console.log(data)
    const {supplierId} = useParams()
    const goModelingView = (list : any) => {
        const formUrl = list?.promModelingPath?.split('/')
        const urlName = formUrl[formUrl?.length - 1].split('.')[0]
        console.log(list?.promModelingPath)
        // modelingId : ALINK3D에서도 공용으로 쓰이는 모델링 아이디 , modeling : 추후 Ifame을 띄우기 위한 파일 명
        window.open(`/modeling/206/${list?.promIdx}/${list?.promModelingId}/${supplierId}/${urlName}`, '_blank');
    }
    return(
        <>
        <div>
            {data?.length === 0 ? 
            '' : <MoreBtn data={data} displayCnt={6} name={name}/>
            }
            <ul className="listType_all listType_quartered listType_a">
                {data?.map((list : any)=> (
                    <li key={list.promIdx}>
                        {/* onClcik 이벤트 작동 시, 모델링을 띄우기 위한 모델링 정보가 있어야함. */}
                        <a onClick={(e)=>goModelingView(list)}></a>
                        <div className="modelingNameWrap">
                            <div className="contentSaveBox">
                                <button className="contentSaveBtn">
                                    <span></span>
                                </button>
                                <span>콘텐츠 저장하기</span>
                            </div>
                            <div className="modelingNameBox">
                                <span>{list?.companyNameEn}</span>
                                <div className="hashTagBox">
                                    <p><TagBox tags={list?.promTags} type={'206'}/></p>
                                </div>
                            </div>
                        </div>
                        <img src={`https://marineplaza.org${list?.promThumnailImage}`} alt="contents_list"/>
                    </li>
                ))}
        </ul>
        </div>
        </>
    )
}
export interface Props2 {}
export const ModelingMoreUnit:React.FC<Props2> = () => {
    return(
        <>
        <div>
            <div>
                <h4>3D Products & Components</h4>
            </div>
            <ul className="listType_all listType_3equalParts listType_a">
                <li>
                    <a href=""></a>
                    <div className="modelingNameWrap">
                        <div className="contentSaveBox">
                            <button className="contentSaveBtn">
                                <span></span>
                            </button>
                            <span>콘텐츠 저장하기</span>
                        </div>
                        <div className="modelingNameBox">
                            <span>ALINK3D</span>
                            <p>Alink3d Modeling Name</p>
                        </div>
                    </div>
                    <img src="/assets/images/contents_list1.png" alt="contents_list"/>
                </li>
            </ul>
        </div>
        </>
    )
}