import React, { useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const Join = () => {

    const pwCheckRef = useRef<any>();

    const [param, setParam] = useState<any>({
        email : '', pw : '', name : '', phone : '', marketingAgree : false, termsUseAgree : false, privacyAgree : false
    })

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, type, value, checked} = e.target;
        if(name === 'checkall') 
        {setParam({...param, marketingAgree : checked, termsUseAgree : checked, privacyAgree : checked})}
        else if(type === 'checkbox') 
        {setParam({...param, [name] : checked})}
        else 
        {setParam({...param, [name] : value})}
    }

    const checkPw = () => {
        if(param.pw !== pwCheckRef.current.value && pwCheckRef.current?.value !== ''){
            alert("비밀번호를 다시 확인해 주십시오."); pwCheckRef.current.value = ''; pwCheckRef.current?.focus(); return;
        }
    }

    const handleJoin = () => {
        const {email, pw, name, phone, termsUseAgree, privacyAgree} = param;
        if(!(email&&pw&&name&&phone&&termsUseAgree&&privacyAgree)){
            toast.error("필수 기입 항목을 확인해 주세요.")
        } else {
            // Join Api
            console.log(param)
        }
    }

    return(
        <>
        <Toaster position="bottom-right" toastOptions={{duration: 2000, style : {backgroundColor : 'black', color : 'white', fontSize : '15px', top : '580px'}}}/>
        <div className="mobileContentBox mobile_common mobile_signUp">
            <div>
                <div className="titleBox">
                    <h2>Sign Up</h2>
                </div>
                <div className="formBox">
                    <div>
                        <div className="inputList">
                            <label htmlFor="">이메일 <span>*</span></label>
                            <input type="text" name="email" id="" placeholder="이메일을 입력해 주세요." onChange={handleChange}/>
                        </div>
                        <div className="inputList">
                            <label htmlFor="">비밀번호 <span>*</span></label>
                            <input type="password" name="pw" id="" placeholder="10~20자의 영문, 숫자, 특수문자 조합" onChange={handleChange}/>
                            <input type="password" name="pwConfirm" id="" placeholder="비밀번호 확인" ref={pwCheckRef} onChange={handleChange} onBlur={checkPw}/>
                        </div>
                        <div className="inputList">
                            <label htmlFor="">이름 <span>*</span></label>
                            <input type="text" name="name" id="" placeholder="이름을 입력해 주세요." onChange={handleChange}/>
                        </div>
                        <div className="inputList">
                            <label htmlFor="">휴대전화 <span>*</span></label>
                            <input type="text" name="phone" id="" inputMode="numeric" placeholder="하이픈(-)을 제외한 전화번호를 입력해 주세요." onChange={handleChange}/>
                        </div>
                        <div className="agree_checkBox">
                            <div className="inputCheckBox">
                                <input type="checkbox" name="checkall" id="checkall" onChange={handleChange}/>
                                <label htmlFor="checkall">모두 동의합니다.</label>
                            </div>
                            <div>
                                <div className="inputCheckBox">
                                    <input type="checkbox" name="termsUseAgree" id="check1" checked={param.termsUseAgree} onChange={handleChange}/>
                                    <label htmlFor="check1">이용약관 동의(필수)</label>
                                </div>
                                <div className="inputCheckBox">
                                    <input type="checkbox" name="privacyAgree" id="check2" checked={param.privacyAgree} onChange={handleChange}/>
                                    <label htmlFor="check2">개인정보 수집 및 이용 동의 (필수)</label>
                                </div>
                                <div className="inputCheckBox">
                                    <input type="checkbox" name="marketingAgree" id="check3" checked={param.marketingAgree} onChange={handleChange}/>
                                    <label htmlFor="check3">마케팅 활용 동의 (선택)</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="signUp_completeBtn blue_completeBtn" onClick={handleJoin}>이메일 인증받고 회원가입 완료하기</button>
        </div>
        </>
    )
}

export default Join;