import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TagBox } from "../../../components/contents/tagBox";
import { useGetPromotionDetailMutation } from "../../../store/slices/contents/contentsApiSlice";

const NewsView = () => {
    const navi = useNavigate();
    const location = useLocation()
    const {newsId} = useParams()
    const [detailInfo, setDetailInfo] = useState<any>([])
    const [getContentsDetail] = useGetPromotionDetailMutation();
    const getDetail = async() => {
        // const param = contentsId
        const result = await getContentsDetail(newsId);
        if(result.data.result === true){
            setDetailInfo(result.data.list[0])
        } else {
    
        }
    }
    const goBack = (e:React.MouseEvent) => {
        e.preventDefault()
        navi(-1)
    }
    useEffect(()=>{
        getDetail()
    }, [])
    return(
        <>
        <div className="board_wrap marineNewsBoard_wrap">
            <div className="top">
                <a href="" className="board_backBtn" onClick={goBack}>Back</a>
                <div>
                    <div className="dateBox">
                        <span className="date">{detailInfo?.promCreationDate}</span>
                        {/* <span className="date">October 28, 2023</span> */}
                        <span>written by:</span>
                        <span className="companyName">{detailInfo?.companyNameEn?.length <= 20 ? detailInfo?.companyNameEn : detailInfo?.companyNameEn?.slice(0,20)+"..."}</span>
                    </div>
                    <div className="contentSaveBox">
                        <button className="contentSaveBtn">
                            <span></span>
                        </button>
                        <span>콘텐츠 저장하기</span>
                    </div>
                </div>
            </div>
            <div className="subTop">
                <div style={{backgroundColor : detailInfo?.companyBrandColor}}>
                    <img src={`https://marineplaza.org${detailInfo?.companyLogoImage}`} alt="logo"/>
                </div>
                <p>
                    <span className="contentsView_companyName">{detailInfo?.companyNameEn}</span>
                    <span className="contentsView_companySubTitle">{detailInfo?.companyBusinessAreaEn}</span>
                </p>
            </div>

            <div className="contents">
                {/* <img src={`http://mpadmin.marineplaza.org${detailInfo.promThumnailImage}`} alt="contentsView"/> */}
                <h2 style={{fontSize : '32px' , lineHeight : '1.3'}}>
                    {detailInfo?.promSubjectEn}
                </h2>
                <div className="txtBox">
                    {/* 컨 텐 츠 영 역 */}
                    <div dangerouslySetInnerHTML={{__html: detailInfo?.promContentsEn}}></div>
                    <p>출처 : {detailInfo?.promSource}</p>
                </div>
            </div>

            <div className="bottom">
                <div className="hashTagBox">
                    <TagBox type={'207'} tags={detailInfo?.promTags}/>
                </div>
                <a className="black_backBtn" style={{cursor : 'pointer'}}  onClick={goBack}>Back</a>
            </div>
        </div>
        </>
    )
}

export default NewsView;