import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
export interface Props {embedded : any}
export const FlipBook:React.FC<Props> = ({embedded}) => {

    const location = useLocation()
    const navigate = useNavigate()
    
    return(
        <div className="flipBookBox">
            <iframe key={embedded} title='xvl frame' style={{width : '100%' , height : '100%'}} src={embedded} sandbox={"allow-same-origin allow-scripts"}/>
            {/* <PdfViewer pdfFile={`http://mpusers.marineplaza.org${data?.list[0]?.promContentFile}`}/> */}
            {/* <PdfViewer pdfFile={`/sample3.pdf`}/> */}
        </div>
    )
}