import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TagBox } from "../../../components/contents/tagBox";
import Paging from "../../../components/pagination/pagination";
import { getCookie } from "../../../util/cookie/cookies";
export interface Props{modelingList : any}
const Product3D:React.FC<Props> = ({modelingList}) => {
    const lang = getCookie("MARINELANG");
    const location = useLocation();
    const navi = useNavigate()
    const {contentsType, modelingId, modeling} = useParams()
    // sample data 2개인 이유 : 상황에 따라 보여줘야할 데이터가 달라야 하기 때문.
    // sample data - user 
    // sample data - main
    const mainData = [
        {idx : 1, title : 'FGSS-LP-BUFFER-TANK-SKID', contents : 'test3', img : '/assets/images/contents_list3.png'},
        {idx : 2, title : 'ToyTractor-V10', contents : 'test4', img : '/assets/images/contents_list4.png'},
    ]

    const goModelingView = (e : React.MouseEvent, data : any) => {
        e.preventDefault();
        // navi('/contents/modeling', {state : data}, {target : '_black'});
        const param = {modelingId : 1, modeling : 'trnak'}
        const formUrl = data?.promModelingPath?.split('/')
        const urlName = formUrl[formUrl?.length - 1].split('.')[0]
        // modelingId : ALINK3D에서도 공용으로 쓰이는 모델링 아이디 , modeling : 추후 Ifame을 띄우기 위한 파일 명
        window.open(`/modeling/${contentsType}/${data?.promIdx}/${data?.promModelingId}/${data?.companyId}/${urlName}`, '_blank');
    }
    
    return(
        <>
        <ul className="listType_all listType_quartered listType_a">
            {modelingList?.map((data : any)=> (
                <li key={data.promIdx}>
                    {/* onClcik 이벤트 작동 시, 모델링을 띄우기 위한 모델링 정보가 있어야함. */}
                    <a href="#" onClick={(e)=>goModelingView(e,data)}></a>
                    <div className="modelingNameWrap">
                        <div className="contentSaveBox">
                            <button className="contentSaveBtn">
                                <span></span>
                            </button>
                            <span>콘텐츠 저장하기</span>
                        </div>
                        <div className="modelingNameBox">
                            <span>{lang === 'kr'? data?.companyNameKr : data?.companyNameEn}</span>
                            <div className="hashTagBox">
                                <p><TagBox tags={data?.promTags} type={'206'}/></p>
                            </div>
                        </div>
                    </div>
                    <img src={`https://marineplaza.org${data?.promThumnailImage}`} alt="contents_list"/>
                </li>
            ))}
        </ul>

        </>
    )
}

export default Product3D;