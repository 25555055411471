import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FlipBook } from "../../../components/flipbook/flipBook";
import { useGetPromotionDetailMutation } from "../../../store/slices/contents/contentsApiSlice";
import { CatalogueView } from "../../contents/view/catalogueView";
export interface Props {data : any; onClose:Function}
const Section7:React.FC<Props> = ({data, onClose}) => {
    const navi = useNavigate()
    const [getContentsDetail, {data: getData, isSuccess, isError}] = useGetPromotionDetailMutation();
    const [flipBook, setFlipBook] = useState<any>({
        isActive : false, id : ''
    })
    const [pickList,setPickList] = useState<any>([])
    const [slideNum, setSlideNum] = useState<number>(0);
    const handleSlier = (direction : string) => {
        if(direction === 'prev'){
            if(slideNum === 0){setSlideNum(pickList.length-1)}
            else{setSlideNum(slideNum-1)}
        }
        else if(direction==='next'){
            if(slideNum === (pickList.length-1)){setSlideNum(0)}
            else {setSlideNum(slideNum+1)}
        }
    }
    
    const handleDownload = (file:any) => {
        const filePath = `https://marineplaza.org${file}`;
        const downloadLink = document.createElement("a");
        downloadLink.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        downloadLink.href = filePath;
        downloadLink.target = "_blank"; // "_self"로 설정하여 현재 창에서 다운로드
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    const handleOpen = async(id : string) => {
        setFlipBook({...flipBook, isActive : true, id: id})
        onClose(true)
    }
    const handleClose = () => {
        setFlipBook({...flipBook, isActive : false, id: ''})
        onClose(false)
    }
    useEffect(()=>{
        if(data.cataloguePicks?.length > 0){
            setPickList(data.cataloguePicks)
        }
    }, [data])

    

    return(
        <>
        <section className="main_section main_section7">
            <div className="titleBox">
                <h2>RECOMMENDED <span>CATALOGUE</span></h2>
                <a onClick={()=>navi(`/contents/208`)} style={{cursor:'pointer'}}>more view</a>
            </div>

            <div className="main_catalogueWrap">
                <div className="todayPickWrap">
                    <div className="todayPickBox">
                        <span className="pick">Today's Pick</span>
                        <div className="scaleImgBox">
                            {pickList?.length > 0 && <img src={`https://marineplaza.org${pickList[slideNum]?.promThumnailImage}`} alt="today's Pick" onClick={()=>handleOpen(pickList[slideNum]?.promIdx)}/>}
                        </div>
                        <div className="today_txtBox">
                            <h3 onClick={()=>handleOpen(pickList[slideNum]?.promIdx)}>
                                {pickList.length > 0 && <span>{pickList[slideNum]?.companyNameKr}</span>}
                                {pickList.length > 0 && pickList[slideNum]?.promSubjectKr}
                            </h3>
                            <p onClick={()=>handleOpen(pickList[slideNum]?.promIdx)} style={{cursor:'pointer'}}>
                                {pickList.length > 0 && pickList[slideNum]?.promExcerptKr}
                            </p>
                            <div className="todayPick_pager">
                                <button className="prev" onClick={(e)=>handleSlier('prev')}>
                                    <span></span>
                                </button>
                                <div className="pager_numb">
                                    <span>{slideNum+1}</span>
                                    <span>/</span>
                                    <span>{data.cataloguePicks?.length}</span>
                                </div>
                                <button className="next" onClick={(e)=>handleSlier('next')}>
                                    <span></span>
                                </button>
                            </div>

                            <div className="btnBox">
                                <div className="fileBox" onClick={()=>handleDownload(pickList[slideNum]?.promCatalogueFile)}>
                                    <a>
                                        <span></span>
                                    </a>
                                    <span >다운로드</span>
                                </div>
                                <div>
                                    <div className="contentSaveBox">
                                        <button className="contentSaveBtn">
                                            <span></span>
                                        </button>
                                        <span>콘텐츠 저장하기</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <ul className="listType_all listType_c">
                    {data.catalogueRandom?.map((data : any, index : number) => {
                        return(
                        <>
                        <li key={index}>
                            <div>
                                <div className="scaleImgBox" onClick={()=>handleOpen(data?.promIdx)}>
                                    {/* <img src="/assets/images/catalogue_list5.png" alt="contents_list"/> */}
                                    <img src={`https://marineplaza.org${data.promThumnailImage}`} alt="contents_list"/>
                                </div>

                                <div className="bottomBox">
                                    <div className="contentSaveBox_circle">
                                        <button className="contentSaveBtn">
                                            <span></span>
                                        </button>
                                        <span>콘텐츠 저장하기</span>
                                    </div>
                                    <p onClick={()=>handleOpen(data?.promIdx)} style={{cursor:'pointer'}}>
                                        <span>{data.companyNameKr}</span>
                                        {data.promSubjectKr}
                                    </p>
                                </div>
                            </div>
                        </li>
                        </>
                        )
                    })}
                </ul>
            </div>
            {flipBook?.isActive && <CatalogueView contentId={flipBook?.id} onClose={handleClose}/>}
        </section>
        </>
    )
}

export default Section7;