import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TagBox } from "../../../components/contents/tagBox";
import PdfViewer from "../../../components/pdfView/pdf_viewer";
import { useGetPromotionDetailMutation } from "../../../store/slices/contents/contentsApiSlice";

const TechDocView = () => {
    const location = useLocation();
    const {techId} = useParams()
    const navi = useNavigate();
    const [detailInfo, setDetailInfo] = useState<any>([])
    const [getContentsDetail] = useGetPromotionDetailMutation();
    const getDetail = async() => {
        // const param = contentsId
        const result = await getContentsDetail(techId);
        if(result.data.result === true){
            setDetailInfo(result.data.list[0])
        } else {
    
        }
    }
    console.log(detailInfo)
    const handleDownload = (contentFile: any) => {
        console.log(contentFile);
        const filePath = `https://marineplaza.org${contentFile}`;
        const downloadLink = document.createElement("a");
        downloadLink.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        downloadLink.href = filePath;
        downloadLink.target = "_blank"; // "_self"로 설정하여 현재 창에서 다운로드
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    const goBack = (e:React.MouseEvent) => {
        e.preventDefault();
        navi(-1)
    }
    useEffect(()=>{
        getDetail()
    },[])

    return(
        <>
        <div className="board_wrap catalogueBoard_wrap">
            <div className="top">
                <a href="" className="board_backBtn" onClick={goBack}>Back</a>
                <div>
                    <div className="fileBox" onClick={()=>handleDownload(detailInfo?.promContentFile)}>
                        <a>
                            <span></span>
                        </a>
                        <span>다운로드</span>
                    </div>
                    <div className="contentSaveBox">
                        <button className="contentSaveBtn">
                            <span></span>
                        </button>
                        <span>콘텐츠 저장하기</span>
                    </div>
                </div>
            </div>

            <div className="contents">
            
                <PdfViewer pdfFile={`https://marineplaza.org${detailInfo?.promContentFile}`} fileUrl={detailInfo?.promContentFile} onDownload={handleDownload}/>

                <h2>
                    {detailInfo?.promSubjectKr}
                </h2>
                <div className="txtBox">
                    <div dangerouslySetInnerHTML={{__html: detailInfo?.promContentsKr}}>
                    </div> 
                </div>
            </div>

            <div className="bottom">
                <div>
                    <TagBox type={'210'} tags={detailInfo?.promTags}/>
                </div>
                <div>
                    <button className="black_backBtn">Back</button>
                    <button className="downloadBtn" onClick={()=>handleDownload(detailInfo?.promContentFile)}>Download</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default TechDocView;