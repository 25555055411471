import React, { useRef } from "react";
import toast, { Toaster } from "react-hot-toast";

const FindPw = () => {

    const emailRef = useRef<any>();
    const nameRef = useRef<any>();
    const phoneRef = useRef<any>();

    const handleFindPw = (e:React.MouseEvent<HTMLButtonElement>) => {
        if(!emailRef.current.value || !nameRef.current.value || !phoneRef.current.value){
            toast.error("필수 기입 항목을 확인해 주세요.");
        } else {
            // Find Pw Api
            const param = {
                email : emailRef.current.value, name : nameRef.current.value, phone : phoneRef.current.value
            }
        }
    }

    return(
        <>
        <Toaster position="bottom-right" toastOptions={{duration: 2000, style : {backgroundColor : 'black', color : 'white', fontSize : '15px', top : '580px'}}}/>
        <div className="mobileContentBox mobile_common mobile_signUp">
            <div>
                <div className="titleBox">
                    <h2>비밀번호 찾기</h2>
                    <p>
                        <span>회원가입 시 등록하신 정보 및 이메일을 입력하여</span>
                        임시 비밀번호를 발급받으세요.
                    </p>
                </div>
                <div className="formBox">
                    <div>
                        <div className="inputList">
                            <label htmlFor="">이메일 <span>*</span></label>
                            <input type="text" ref={emailRef} name="email" id="email" placeholder="이메일을 입력해 주세요."/>
                        </div>
                        <div className="inputList">
                            <label htmlFor="">이름 <span>*</span></label>
                            <input type="text" ref={nameRef} name="name" id="name" placeholder="이름을 입력해 주세요."/>
                        </div>
                        <div className="inputList">
                            <label htmlFor="">휴대전화 <span>*</span></label>
                            <input type="text" ref={phoneRef} name="phone" id="phone" placeholder="하이픈(-)을 제외한 전화번호를 입력해 주세요."/>
                        </div>
                    </div>
                    <button className="findId_completeBtn blue_completeBtn" onClick={handleFindPw}>확인</button>
                </div>
            </div>
        </div>

        {/* <div className="findPwSuccess_popUp findSuccess_popUp">
            <div>
                <p>
                    <span>입력하신 이메일로 임시 비밀번호를 발송하였습니다.</span>
                    <strong className="black">로그인 후 비밀번호를 변경해 주시기 바랍니다.</strong>
                </p>
                <div className="btnBox_single">
                    <button className="blue">로그인 하러가기</button>
                </div>
            </div>
        </div> */}
        {/* <!-- 비밀번호 찾기 성공했을 때 --> */}

        {/* <div className="findIdFail_popUp findSuccess_popUp">
            <div>
                <p>
                    <span>입력하신 정보와 일치하는 비밀번호가 없습니다.</span>
                    <strong className="black">가입 시 입력하신 정보가 맞는지 다시 한 번 확인해 주세요.</strong>
                </p>
                <div className="btnBox_single">
                    <button className="blue">확인</button>
                </div>
            </div>
        </div> */}
        {/* <!-- 비밀번호 찾기 실패했을 때 --> */}
        </>
    )
}

export default FindPw;