import React from "react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const contentsApi : any = createApi({
    reducerPath : 'contentsApi',
    baseQuery : fetchBaseQuery({baseUrl : 'https://marineplaza.org/api/controller',}),

    endpoints : (builder : any) => ({
        // 컨텐츠 리스트 조회
        getContents : builder.mutation({
            query : (param : any) => {
                const {contentsType, page, size, sortOrder, sortColumn, keyword, tag} = param;
                return{
                    url : `/users/contents/getContentsListByUser.php?contentsType=${contentsType}&page=${page}&size=${size}&sortOrder=${sortOrder}&sortColumn=${sortColumn}&keyword=${keyword}&tags=${tag}`,
                    method : 'get',
                }
            }
        }),

        // 컨텐츠 리스트 상세보기
        getPromotionDetail : builder.mutation({
            query : (param : any) => {
                // const {contentsIdx} = param;
                return{
                    url : `/users/contents/getContentsDetailByUser.php?contentId=${param}`
                }
            }
        }),

        // 공급업체 상세보기
        getSupplierDetail : builder.mutation({
            query : (param : {supplierId:string}) => {
                return{
                    url : `/users/suppliers/getSupplierDetailByUser.php?companyId=${param.supplierId}`
                }
            }
        })
    })
})

export const {useGetContentsMutation, useGetPromotionDetailMutation, useGetSupplierDetailMutation} = contentsApi;