import React from "react";
import { HeightUnit } from "../../../../components/contents/supplier-contents/height-unit";
import { ModelingInitUnit } from "../../../../components/contents/supplier-contents/modeling-unit";
import { WidthUnit } from "../../../../components/contents/supplier-contents/width-unit";
export interface Props{data : any;}
export const SupplierContents:React.FC<Props> = ({data}) => {

    return(
        <>
        {data?.productList?.length !== 0 ? <ModelingInitUnit name={'3D Product & Components'} data={data?.productList}/> : ''}
        {data?.marineNewsList?.length !== 0 ? <WidthUnit name={'Marine News'} data={data?.marineNewsList}/> : ''}
        {data?.techList?.length !== 0 ? <HeightUnit name={'Tech. Document'} data={data?.techList}/> : ''}
        {data?.videosList?.length !== 0 ? <WidthUnit name={'Videos'} data={data?.videosList}/> : ''}
        {data?.catalogueList?.length !== 0 ? <HeightUnit name={'Catalogues'} data={data?.catalogueList}/> : ''}
        </>
    )
}