import React, { useRef } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PageStyles from '../../../pages/contents/modeling/css/page.module.css'
export interface Props {image : any}
export const Slick:React.FC<Props> = ({image}) => {
    console.log(image)
    const sliderRef = useRef<any>()
    const setting = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,  // 한 번에 보여지는 갯수
        slidesToScroll: 1, // 한 번에 스크롤되는 슬라이드 수
        vertical: false,
        arrows : true
    }
    return(
        <>
        <div className={PageStyles.page_carousel}>
            <Slider {...setting} ref={sliderRef}>
                {image?.map((data : any, index : number) => (
                    // <li className="active"
                    <div className={PageStyles.height_box}>
                        <img src={data.performance_image_pdsFilename} alt="thumbnail"/>
                    </div>
                ))}
            </Slider>
        </div>
        <div className={PageStyles.product_performance_pageCount}>
            {/* <span className={PageStyles.current}>00</span>
            <span>/</span>
            <span className={PageStyles.total}>{image?.length}</span> */}
        </div>
        </>
    )
}