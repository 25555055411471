import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
export interface Props {pdfFile : string; onDownload:Function; fileUrl : string}
const PdfViewer:React.FC<Props> = ({pdfFile, onDownload, fileUrl}) => {
    const [pdf , setPdf] = useState<any>('/sample3.pdf')
    const [wholePage, setWholePage] = useState<number>(0); // whole Page : display
    const [numPages, setNumPages] = useState<any>(); // whole Page : Handler
    const [pageNumber, setPageNumber] = useState(1); // current Page
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // LoadSuccess Event
    function onDocumentLoadSuccess({ numPages } : any) {
        setNumPages(numPages); setWholePage(numPages);
    }
    const handleChangePage = (offset : any) => {
        setPageNumber((prev:any) => prev + offset)
    }
    // Next Event
    const next = () => {
        if(pageNumber <= 3){
            handleChangePage(+1)
        }
    }
    // Previous Event
    const previous = () => {
        if(pageNumber === 1) { setPageNumber(1)}
        else { handleChangePage(-1) }
    }
    const handleDocumentLoading = () =>{
        return(
            <>
            
            </>
        )
    }
    const handlePageLoading = () => {
        return(
            <>
            
            </>
        )
    }
    const handleDocumentLoadError = () => {
        return(
            <>
            
            </>
        )
    }
    useEffect(() => { 
        if(pageNumber === 4){
            setIsOpen(true)
        } else {setIsOpen(false)}
    },[onDocumentLoadSuccess])
    
    return(
        <>
            <div className="catalogueBox">
                <Document file={`${pdfFile}`} onLoadSuccess={onDocumentLoadSuccess} error={handleDocumentLoadError} loading={handleDocumentLoading}>
                {/* <Document file={'/sample1.pdf'} onLoadSuccess={onDocumentLoadSuccess} loading={handleDocumentLoading}> */}
                    <div className="pdf-page-container">
                        <Page className="pdf-page" loading={handlePageLoading} pageNumber={pageNumber} scale={1.0}/>
                    </div>
                </Document>
                <button className="leftArrow" onClick={previous}></button>
                <button className="rightArrow" onClick={next}></button>
               
                {isOpen&&
                <div className="pdf_alertPopUp popUp_active active">
                    {/* <div>
                        <div>
                            <p>
                                본 문서를 계속 읽고 싶으시다면
                                <strong>로그인 후 서비스를 이용해 보세요.</strong>
                            </p>
                        </div>
                        <div>
                            <button>회원가입</button>
                            <button className="blue">로그인</button>
                        </div>
                    </div> */}

                    <div>
                        <div>
                            <p>
                                본 문서의 미리보기가 끝났습니다.
                                <strong>다운로드를 통해 원문을 확인해 보세요.</strong>
                            </p>
                        </div>
                        <div>
                            <button onClick={()=>previous()}>취소</button>
                            <button className="blue" onClick={()=>onDownload(fileUrl)}>다운로드</button>
                        </div>
                    </div>
                </div>
                }
            </div>
            <div className="pagerWrap">
                <p>총 {wholePage}페이지 중 3페이지 미리보기</p>
                <div>
                    <span>{pageNumber}</span>
                    <span> / </span>
                    <span>{numPages}</span>
                </div>
            </div>
        </>
    )
}

export default PdfViewer;