import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Contents from "../../contents";
import UserInquirys from "./pages/inquirys";
import UserModify from "./pages/modify";
import UserHeader from "./header";

const MyPage = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const {contentsType} = useParams()
    return(
        <>
        <div className={pathname === '/contents' ? 'contentWrap' : 'contentWrap'}>

            <UserHeader url={pathname}/>
            
            {location.pathname === '/mypage/modify' ? <UserModify/> : ''}
            {location.pathname === `/mypage/collection/${contentsType}` ? <Contents/> : ''}
            {location.pathname === '/mypage/collection/news' ? <Contents/> : ''}
            {location.pathname === '/mypage/collection/suppliers' ? <Contents/> : ''}
            {location.pathname === '/mypage/collection/techDoc' ? <Contents/> : ''}
            {location.pathname === `/mypage/downloads/${contentsType}` ? <Contents/> : ''}
            {location.pathname === '/mypage/inquirys' ? <UserInquirys/> : ''}
        </div>
        </>
    )
}

export default MyPage;