import React, { useEffect, useState } from "react";
import { useGetMainContentsMutation } from "../../store/slices/main/mainContents";
import Section1 from "./section/section_1";
import Section2 from "./section/section_2";
import Section3 from "./section/section_3";
import Section4 from "./section/section_4";
import Section5 from "./section/section_5";
import Section6 from "./section/section_6";
import Section7 from "./section/section_7";
export interface Props {responsive : boolean; onClose:Function}
const Main:React.FC<Props> = ({responsive, onClose}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [modelingCategory, setModelingCategory] = useState<number>(212)
    const [mainCotentsList, setMainContentsList] = useState<any>({newsPick : [], newsLatest : [], newsMostView : [], videoNews : [], techNews : [], cataloguePicks : [], catalogueRandom : [], suppliers:[], modeling : []});
    const [getMainContents] = useGetMainContentsMutation();
    const handleChangeCategoryNum = (e:number) => {
        setModelingCategory(e)
    }
    const getMainContentsList = async()=>{
        const result : any = await getMainContents({contentType: 206,categoryNum : modelingCategory})
        if(result?.data?.result===true){
            setMainContentsList((prev:any)=>({...prev, modeling : result?.data?.modelingList}))
            const result_1 : any = await getMainContents({contentType: 207, categoryNum : modelingCategory})
            if(result_1?.data?.result === true){
                setMainContentsList((prev:any)=>({...prev, newsPick : result_1?.data?.marineNewsPick,newsLatest : result_1?.data.marineNewsLatest, newsMostView : result_1?.data.marineNewsMostViews}))
                const result_2 : any = await getMainContents({contentType: 208, categoryNum : modelingCategory})
                if(result_2?.data?.result === true){
                    setMainContentsList((prev:any)=>({...prev, cataloguePicks : result_2?.data?.cataloguePicks, catalogueRandom : result_2?.data?.catalogueRandom}))
                    const result_3 : any = await getMainContents({contentType: 209, categoryNum : modelingCategory})
                    if(result_3?.data?.result===true){
                        setMainContentsList((prev:any)=>({...prev, videoNews : result_3?.data?.videoNews}))
                        const result_4 : any = await getMainContents({contentType: 210, categoryNum : modelingCategory})
                        if(result_4?.data?.result === true){
                            setMainContentsList((prev:any)=>({...prev, techNews : result_4?.data?.techNews}))
                            const result_5 : any = await getMainContents({contentType: 211, categoryNum : modelingCategory})
                            if(result_5?.data?.result === true){
                                setMainContentsList((prev:any)=>({...prev, suppliers : result_5?.data?.suppliers}))
                            }
                        }
                    }
                }
            }
        }
    }
    useEffect(()=>{
        getMainContentsList()
    },[modelingCategory])

    return(
        <>  
            <Section1/>
            <Section2 onChange={handleChangeCategoryNum} data={mainCotentsList}/>
            <Section3 data={mainCotentsList}/>
            <Section4 data={mainCotentsList} responsive={responsive}/>
            <Section5 data={mainCotentsList}/>
            <Section6 data={mainCotentsList}/>
            <Section7 data={mainCotentsList} onClose={onClose}/>
        </>
    )
}

export default Main;

// setMainContentsList({...mainCotentsList, modeling : result?.data?.modelingList,
//     newsPick : result?.data?.marineNewsPick[0], newsLatest : result?.data.marineNewsLatest, newsMostView : result?.data.marineNewsMostViews,
//     videoNews : result?.data?.videoNews, techNews : result?.data?.techNews, cataloguePicks : result?.data?.cataloguePicks, catalogueRandom : result?.data?.catalogueRandom,
//     suppliers : result?.data?.suppliers})