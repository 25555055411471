import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SearchBox from "../../components/contents/left_searchBox";
import TypeCategoryBox from "../../components/contents/right_typeBox";
import { Loading } from "../../components/loading/loading";
import Paging from "../../components/pagination/pagination";
import { useGetContentsMutation } from "../../store/slices/contents/contentsApiSlice";
import Product3D from "./contentType/3dProducts";
import Catalog from "./contentType/catalogue";
import MarinNews from "./contentType/marinNews";
import Supplier from "./contentType/supplier";
import TechDoc from "./contentType/techDocument";
import Video from "./contentType/video";
import Filter from "./headerFilter.tsx/headFilter";
import { CatalogueView } from "./view/catalogueView";
import NewsView from "./view/newsView";
import SupplierView from "./view/supplierView";
import TechDocView from "./view/tectDocumentView";
export interface Type{
    contentsType:any,page:number,size:number,sortOrder:string,sortColumn:string,keyword:string, tag:string
}
const Contents = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search)
    const page  :any = searchParam.get("page") || 1;
    const keyword : any = searchParam.get("keyword") || '';
    const tag : any = location.hash.replace("#",'');
    const navi = useNavigate()
    const path = location.pathname.split('/');
    const {contentsType, newsId, supplierId, techId, tab, supplierContents, catalogueId}:any = useParams()
    const [initType, setInittype] = useState<any>(0)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isMobileLayout, setIsMobileLayout] = useState<boolean>(false);
    const [getContents , {isLoading}] = useGetContentsMutation();
    const [contentsList, setContentsList] = useState<any>([])
    const [contentCount, setContentCount] = useState<number>(0)
    const [type, setType] = useState<any>(contentsType);
    const selType = (type : any) => {
        setType(type)
    }
    
    const [param, setParam] = useState<Type>({
        contentsType: contentsType, page: page, size:12, sortOrder:'desc', sortColumn:'date', keyword:keyword, tag: tag
    })
    // 페이지 이동
    const handlePage = (pageNum : number) => 
    { setParam({...param, page : pageNum}); navi(`/contents/${contentsType}?page=${pageNum}&keyword=${keyword}&sortOrder=desc&sortColumn=date&tags=${tag}`) }

    // Contents List Call Api 
    const getContentsList = async(param : any) => {
        const result:any = await getContents(param);
        try{
            if(result.data.result===true){
                setContentsList(result.data.list)
                setContentCount(result.data.totalCnt)
            } else {
                setContentsList([])
                setContentCount(0)
            }
        } catch(error : any) {
        } finally {
        }
    }
    const headerType = (path[1] === 'mypage' && path [3] === contentsType) ? <></> : <SearchBox getContentsList={getContentsList}/>;
    const handleResize = () => {
        if (window.innerWidth <= 1023) {setIsMobileLayout(true);} 
        else {setIsMobileLayout(false);}
    }
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        if (window.innerWidth <= 1023) {setIsMobileLayout(true);}
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        setParam((prevParam : any) => ({
          ...prevParam,
          contentsType: contentsType, page : 1, keyword : '', tag : ''
        }));
    }, [contentsType]);
    // contentsType이 변경될 때마다 currentPage를 1로 초기화한다.
    useEffect(() => {
        if (param.contentsType !== contentsType) {
            setParam((prevParam: any) => ({...prevParam, contentsType: contentsType, page: 1, keyword: '', tags : ''}));
        }
    }, [param.contentsType, contentsType, path[1]]);
    // ContenstType 이 변하지 않는 한, 모든 페이지 정보들 가지고 이동
    useEffect(()=>{setParam((prev:any)=>({...prev, page:page, keyword:keyword, tag : tag}))}, [page, keyword, tag])
    // ContentsType, Page 정보가 변경될때마다 리스트 조회
    useEffect(()=>{
        if(location.pathname===`/contents/${contentsType}`){getContentsList(param);window.scrollTo(0, 0);}
    }, [param.contentsType, param.page, param.keyword, param.tag])
    console.log('param.tag', param.tag)
    // useEffect(()=>{
    //     if(isLoading){alert("!")}
    // }, [isLoading])
    return(
        <>
        <div className={path[1] === 'contents' ? 'contentWrap' : ''}>
            {headerType}
            <section className="flexWrap">
                <div className="leftBox">

                    <Filter type={contentsType} count={contentCount} responsive={isMobileLayout}/>
                    {isLoading ? 

                    <Loading/> :
                    
                    <>
                    {(path[1] === 'contents' && path[2] === '206') || (path[1] === 'mypage' && path [3] === '206') ? <Product3D modelingList={contentsList}/> : ''}
                    {(path[1] === 'contents' && path[2] === '207') || (path[1] === 'mypage' && path [3] === '207') ? <MarinNews newsList={contentsList}/> : ''}
                    {(path[1] === 'contents' && path[2] === '208') || (path[1] === 'mypage' && path [3] === '208') ? <Catalog catalogueList={contentsList}/> : ''}
                    {(path[1] === 'contents' && path[2] === '209') || (path[1] === 'mypage' && path [3] === '209') ? <Video videoList={contentsList}/> : ''}
                    {(path[1] === 'contents' && path[2] === '210') || (path[1] === 'mypage' && path [3] === '210') ? <TechDoc techDocList={contentsList}/> : ''}
                    {(path[1] === 'contents' && path[2] === '211') || (path[1] === 'mypage' && path [3] === '211') ? <Supplier supplierList={contentsList}/> : ''}
                    

                    {location.pathname === `/contents/news/${contentsType}/${newsId}` ? <NewsView/> : ''}
                    {location.pathname === `/contents/supplier/${contentsType}/${supplierId}/${tab}` ? <SupplierView/> : ''}
                    {location.pathname === `/contents/supplier/${contentsType}/${supplierId}/${tab}/${supplierContents}` ? <SupplierView/> : ''}
                    {location.pathname === `/contents/techDoc/${contentsType}/${techId}` ? <TechDocView/> : ''}
                    
                    {location.pathname ===`/contents/${contentsType}` || location.pathname ===`/mypage/collection/${contentsType}` ? 
                    <div style={{position : 'relative', bottom : '35px'}}>
                        <Paging page={page} size={param.size} setPage={handlePage} totalPage={contentCount}/>
                    </div>
                    : ''
                    }
                    </>
                    }       
                </div>

                <TypeCategoryBox
                responsive={isMobileLayout}
                initType={type} 
                onSelect={selType}
                />

            </section>
        </div>
        </>
    )
}

export default Contents;