import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export interface Props{
    url : string
}

const UserHeader:React.FC<Props> = ({url}) => {
    const location= useLocation();
    const navi = useNavigate();
    const {contentsType} = useParams();
    const tab = [
        {url : '/mypage/modify', title : 'My Profile', type : ''},
        {url : `/mypage/collection/`, title : 'Collections', type : 206},
        {url : `/mypage/downloads/`, title : 'Downloads', type : 208},
        {url : '/mypage/inquirys', title : 'Inquirys', type : ''},
    ]

    return(
        <>
        <div className="myPageTab">
            <div className="flexWrap">
                <ul>
                    {tab.map((data:{url:string,title:string,type:any}) => (
                        <li
                        key={data.url}
                        className={url === data.url+contentsType ? 'active' : ''}
                        onClick={()=>navi(`${data.url}${data.type}`)}
                        >
                            {data.title}
                        </li>
                    ))}
                    
                </ul>
            </div>
        </div>
        </>
    )
}

export default UserHeader;