import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
export interface Props {
    name : string; data :any; displayCnt:number
}
export const MoreBtn:React.FC<Props> = ({name, data,displayCnt}) => {
    console.log(data?.length)
    const {contentsType, supplierId, tab} = useParams()
    const navigate = useNavigate()
    const handleMoreView = () => {
        if(name === '3D Product & Components'){navigate(`/contents/supplier/${contentsType}/${supplierId}/C?content=206`)}
        else if(name==='Marine News'){navigate(`/contents/supplier/${contentsType}/${supplierId}/C?content=207`)}
        else if(name==='Tech. Document'){navigate(`/contents/supplier/${contentsType}/${supplierId}/C?content=210`)}
        else if(name==='Videos'){navigate(`/contents/supplier/${contentsType}/${supplierId}/C?content=209`)}
        else if(name==='Catalogues'){navigate(`/contents/supplier/${contentsType}/${supplierId}/C?content=208`)}
    }
    return(
        <>
        <div>
            <h4>{name}</h4>
            {data?.length < displayCnt ? '' : <button className="moreViewBtn" onClick={handleMoreView}>more view</button>}
        </div>
        </>
    )
}