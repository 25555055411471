import React from "react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const supplierContentsApi : any = createApi({
    reducerPath : 'supplierContentsApi',
    baseQuery : fetchBaseQuery({baseUrl : 'https://marineplaza.org/api/controller',}),

    endpoints : (builder : any) => ({
        // 공급업체 별 컨텐츠 리스트
        getSupplierContentsMore : builder.mutation({
            query : (param : {companyId:string,contentsType:string,page:number,size:number}) => {
                const {companyId, contentsType, page, size} = param;
                return{
                    url : `/users/suppliers/getSupplierContentsMore.php?companyId=${companyId}&contentsType=${contentsType}&page=${page}&size=${size}`,
                }
            }
        }),
    })
})

export const {useGetSupplierContentsMoreMutation} = supplierContentsApi;