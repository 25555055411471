import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
export interface Props {getContentsList : any}
const SearchBox:React.FC<Props> = ({getContentsList}) => {
    const navi = useNavigate()
    const {contentsType} : any = useParams()
    const serachRef = useRef<any>(null)
    const [search, setSearch] = useState<boolean>(false)
    const [selectIdx, setSelectIdx] = useState<number>(209)
    const option = [{idx : 206, value : 'product', title : '3D Product & Component'}, {idx : 207, value : 'news', title : 'Marine News'}, {idx : 208, value : 'catalogue', title : 'Catalogue'},
    {idx : 209, value : 'video', title : 'Video'}, {idx : 210, value : 'document', title : 'Tech, Document'}, {idx : 211, value : 'supplier', title : 'Supplier'},]

    const goInquiry = (e:React.MouseEvent) => {
        e.preventDefault(); navi('/inquriy')
    }
    const handleChange = (e:any) => {
        const value = serachRef.current.value;
        if(value.length > 0) {setSearch(true)}
        else {setSearch(false)}
    }
    const handleDelSearch = () => {serachRef.current.value = ""; setSearch(false); navi(`/contents/${selectIdx}?page=1&keyword=&sortOrder=desc&sortColumn=date&tags=`)}
    const handleSelected = (e:any) => {
        setSelectIdx(e.target.value)
        if (serachRef.current) {serachRef.current.value = '';}
    }
    const handleSearchClick = () => {
        const param = {contentsType: selectIdx, page: 1,size:12,sortOrder:'desc',sortColumn:'date',keyword:serachRef.current.value, tag : ''}
        navi(`/contents/${selectIdx}?page=1&keyword=${param.keyword}&sortOrder=desc&sortColumn=date&tags=`)
        // getContentsList(param)
    }
    const handleSearch = (e:any) => {
        if(e.key === 'Enter'){
            handleSearchClick()
        }
    }
    
    useEffect(()=>{
        setSelectIdx(contentsType)
        if (serachRef.current) {serachRef.current.value = '';}
    }, [contentsType])

    return(
        <>
        <div className="grayBG">
            <div className="flexWrap">
                <div className="leftBox">
                    <fieldset>
                        <legend>searchBox</legend>
                        <div className="searchWrap">
                            <div className="selectBox">
                                <select name="" id="" value={selectIdx} className="select" onChange={handleSelected}>
                                    {option.map((data : {idx:number, value : string, title : string}) => (
                                        <option key={data.idx} value={data.idx}>
                                            {data.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="searchBox">
                                <input type="text" ref={serachRef} placeholder="Search" onChange={handleChange} onKeyDown={handleSearch}/>
                                <div className="search_btnBox">
                                    {search===false ? '' : 
                                    <button type="button" className="search_closeBtn" onClick={handleDelSearch}><img src="/assets/images/search_closeBtn.png" alt="닫기버튼"/></button>
                                    }
                                    <button type="button" className="search_btn">
                                        <img src="/assets/images/search_icon.png" alt="검색아이콘" onClick={handleSearchClick}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    {/* <!-- searchBox --> */}
                </div>
                {/* <!-- 왼쪽 컨텐츠 --> */}
                <div className="rightBox">
                    <a href="" className="blackBtn" onClick={goInquiry}>
                        <span>
                            <span></span>
                            <span>Requesting data registration</span>
                        </span>
                    </a>
                </div>
                {/* <!-- 오른쪽 컨텐츠 --> */}
            </div>
        </div>
        </>
    )
}

export default SearchBox;