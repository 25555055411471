import React from "react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const mainContentsApi : any = createApi({
    reducerPath : 'mainContentsApi',
    baseQuery : fetchBaseQuery({baseUrl : 'https://marineplaza.org/api/controller',}),

    endpoints : (builder : any) => ({

        getMainContents : builder.mutation({
            query : (body : {contentType: number,categoryNum : number}) => {
                return{
                    url : `/users/main/getMainContents.php?contentType=${body.contentType}&productCategory=${body.categoryNum}`,
                    method : 'get',
                }
            }
        })
        
    })
})

export const {useGetMainContentsMutation} = mainContentsApi;