import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Paging from "../../../components/pagination/pagination";
import { getCookie } from "../../../util/cookie/cookies";
export interface Props {techDocList : any}
const TechDoc:React.FC<Props> = ({techDocList}) => {
    const lang = getCookie("MARINELANG")
    const navi = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const {contentsType} = useParams()
    const goView = (e:React.MouseEvent, id : number) => {
        e.preventDefault();
        if(pathname === `/contents/${contentsType}`){
            navi(`/contents/techDoc/${contentsType}/${id}`)
        }
        if(pathname === '/mypage/collection'){
            navi('/mypage/collection/techDoc', {state : {type : 5}})
        }
    } 

    return(
        <>
        <ul className="listType_all listType_quartered listType_c">
            {techDocList?.map((data:any) => {
                return(
                    <>
                    <li key={data.promIdx}>
                        <a onClick={(e:any)=>goView(e, data.promIdx)}></a>
                        <div>
                            <div className="scaleImgBox">
                                <img src={`https://marineplaza.org${data.promThumnailImage}`} alt="contents_list"/>
                            </div>

                            <div className="bottomBox">
                                <div className="contentSaveBox_circle">
                                    <button className="contentSaveBtn">
                                        <span></span>
                                    </button>
                                    <span>콘텐츠 저장하기</span>
                                </div>
                                <p>
                                    {lang === 'kr'?
                                    <span>{data?.companyNameKr?.length <= 27 ? data?.companyNameKr : data?.companyNameKr?.slice(0,27)+"..."}</span> : 
                                    <span>{data?.companyNameEn?.length <= 27 ? data?.companyNameEn : data?.companyNameEn?.slice(0,27)+"..."}</span>
                                    }
                                    {lang === 'kr' ? data.promSubjectKr : data.promSubjectEn}
                                </p>
                            </div>
                        </div>
                    </li>
                    </>
                )
            })}
        </ul>

        
        </>
    )
}

export default TechDoc;