import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export interface Props {
    onSelect : Function
    initType : any
    responsive: boolean
}

const TypeCategoryBox:React.FC<Props> = ({onSelect, initType, responsive}) => {
    const location = useLocation()
    const path = location.pathname.split('/')
    const navi = useNavigate()
    const isActiveRef = useRef<any>([])
    const responsiveRef = useRef<any>(null)
    const {contentsType}:any = useParams()
    console.log(contentsType)
    const typeList = [{idx: 1, title : '3D Product & Component', type : 206},
    {idx: 2, title : 'Marine News', type : 207}, {idx: 3, title : 'Catalogue', type : 208},
    {idx: 4, title : 'Video', type : 209}, {idx: 5, title : 'Tech, Document', type : 210},
    {idx: 6, title : 'Supplier', type : 211},]

    const typeList_download = [
    {idx: 1, title : 'Catalogue', type : 208},
    {idx: 2, title : 'Tech, Document', type : 210},
    ]

    const type : any = location.pathname === `/mypage/downloads/${contentsType}` ? typeList_download : typeList;

    const handleSelType = (e:any, type : any) => {
        onSelect(type)
        Object.values(isActiveRef.current).forEach((isActiveRef: any) => {
            isActiveRef?.classList.remove('active');
        });
        isActiveRef.current?.[type].classList.add('active');
        if(path[1]==='contents'){
            navi(`/contents/${type}`);
        } else if(path[2]==='collection'){
            navi(`/mypage/collection/${type}`)
        } else if(path[2]==='downloads'){
            navi(`/mypage/downloads/${type}`)
        }
    }

    const handleResponsiveFilterOpen = (control : string) =>{
        if(control === 'open'){responsiveRef.current.classList.add('active')}
        else {responsiveRef.current.classList.remove('active')}
    }

    useEffect(()=>{
        Object.values(isActiveRef.current).forEach((isActiveRef: any) => {
            isActiveRef?.classList.remove('active');
        });
        if (isActiveRef.current?.[contentsType]) {
            isActiveRef.current?.[contentsType].classList.add("active");
        }
    }, [location, contentsType])
    return(
        <>
        {responsive && <button className="mobile_filterBtn" onClick={()=>handleResponsiveFilterOpen('open')}><span></span></button>}
        
        <div className="rightBox" ref={responsiveRef}>
            <button onClick={()=>handleResponsiveFilterOpen('close')}></button>
            <div className="contentTypeWrap">
                <h3>Content Type</h3>
                <ul>
                    {type.map((data:{idx:number,title:string,type:any})=> (
                        <li key={data.idx} ref={(e)=>isActiveRef.current[data.type]=e}>
                            <a
                            onClick={(e)=>handleSelType(e, data.type)}
                            style={{ cursor : 'pointer' }}
                            >
                                {data.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            {location.pathname === `/contents/${contentsType}` ? 
            <div className="categoryWrap">
                <div className="category_titleBox">
                    <h3>Category</h3>
                    <button>Clear all</button>
                </div>

                <div className="categoryBox">
                    <div>
                        <h4>Ship Hull</h4>
                        <div>
                            <span>0/5</span>
                            <button>
                                <img src="/assets/images/redCloseBtn.png" alt="close button"/>
                            </button>
                        </div>
                    </div>
                    {/* <!-- 이 박스를 클릭하면 아래 ul에 active클래스 추가 --> */}
                    <ul className="">
                        <li>
                            <input type="checkbox" name="" id="shipHull_1"/>
                            <label htmlFor="shipHull_1">all</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id="shipHull_2"/>
                            <label htmlFor="shipHull_2">metal</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id="shipHull_3"/>
                            <label htmlFor="shipHull_3">chemical</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id="shipHull_4"/>
                            <label htmlFor="shipHull_4">casting</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id="shipHull_5"/>
                            <label htmlFor="shipHull_5">welding material</label>
                        </li>
                    </ul>
                </div>
                {/* <!-- Ship Hull --> */}
                
                <div className="categoryBox">
                    <div>
                        <h4>Ship Fitting</h4>
                        <div>
                            <span>0/5</span>
                            <button>
                                <img src="/assets/images/redCloseBtn.png" alt="close button"/>
                            </button>
                        </div>
                    </div>
                    {/* <!-- 이 박스를 클릭하면 아래 ul에 active클래스 추가 --> */}
                    <ul>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">all</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">metal</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">chemical</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">casting</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">welding material</label>
                        </li>
                    </ul>
                </div>
                {/* <!-- Ship Fitting --> */}

                <div className="categoryBox">
                    <div>
                        <h4>Ship Engine</h4>
                        <div>
                            <span>0/5</span>
                            <button>
                                <img src="/assets/images/redCloseBtn.png" alt="close button"/>
                            </button>
                        </div>
                    </div>
                    {/* <!-- 이 박스를 클릭하면 아래 ul에 active클래스 추가 --> */}
                    <ul>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">all</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">metal</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">chemical</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">casting</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">welding material</label>
                        </li>
                    </ul>
                </div>
                {/* <!-- Ship Engine --> */}

                <div className="categoryBox">
                    <div>
                        <h4>Ship Electronic</h4>
                        <div>
                            <span>0/5</span>
                            <button>
                                <img src="/assets/images/redCloseBtn.png" alt="close button"/>
                            </button>
                        </div>
                    </div>
                    {/* <!-- 이 박스를 클릭하면 아래 ul에 active클래스 추가 --> */}
                    <ul>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">all</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">metal</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">chemical</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">casting</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id=""/>
                            <label htmlFor="">welding material</label>
                        </li>
                    </ul>
                </div>
                {/* <!-- Ship Electronic --> */}
            </div>
            : ''
            }
        </div>
        </>
    )
}

export default TypeCategoryBox;