import React, { useEffect, useRef, useState } from "react";
import PageStyles from './css/page.module.css';
import { ModelingInquiry } from "./popup/inquiry/inquiry";
import { IotInformation } from "./popup/iot/iotInfo";
import { ModelingContents } from "./popup/mp-contents/contents";
import { ModelingProduct } from "./popup/product/product";
import { ModelingSupplier } from "./popup/supplier/supplier";
export const btnList = [{idx:1,title:'제품정보'},{idx:2,title:'MP Content'},{idx:3, title:'업체정보'},{idx:4,title:'Iot 분석'},{idx:5,title:'문의하기'}]
const Modeling = () => {
    const [pageIdx, setPageIdx] = useState<number>(0);
    const popUpbtnRef = useRef<any>([]);
    const handleOpen = (list: any) => {
        if (popUpbtnRef.current[list.idx].classList.contains(PageStyles.active)) {
            popUpbtnRef.current[list.idx].classList.remove(PageStyles.active);
            setPageIdx(0);
            return;
        }
        Object.values(popUpbtnRef.current).forEach((buttonRef: any) => {
            buttonRef?.classList.remove(PageStyles.active);
        });
        popUpbtnRef.current?.[list.idx].classList.add(PageStyles.active);
        setPageIdx(list.idx)
    }
    const handleClose = () => {
        Object.values(popUpbtnRef.current).forEach((buttonRef: any) => {
            buttonRef?.classList.remove(PageStyles.active);
        });
        setPageIdx(0);
    }
    useEffect(()=>{
        if(pageIdx === 0){
            Object.values(popUpbtnRef.current).forEach((buttonRef: any) => {
                buttonRef?.classList.remove(PageStyles.active);
            });
        }
    }, [pageIdx])
    return(
        <>
        <div className={PageStyles.popUpOpen_btnBox}>
            {btnList?.map((list:{idx:number,title:string})=> (
            <button 
                className={PageStyles[`popUp_btn${list.idx}`]} 
                key={list.idx} 
                ref={(e)=>popUpbtnRef.current[list.idx]= e} 
                onClick={()=>handleOpen(list)}
            >
                <span></span>{list.title}
            </button>
            ))}
		    <button className={[PageStyles.popUp_langBtn, PageStyles.popUp_lang_ko].join(" ")}>
                <span></span>
            </button>
        </div>
        <div className={PageStyles.popUpBox}>
                {pageIdx===1?<ModelingProduct onClose={handleClose}/>:''}
                {pageIdx===2?<ModelingContents onClose={handleClose}/>:''}
                {pageIdx===3?<ModelingSupplier onClose={handleClose}/>:''}
                {pageIdx===4?<IotInformation onClose={handleClose}/>:''}
                {pageIdx===5?<ModelingInquiry onClose={handleClose}/>:''}
        </div>
        </>
    )
}

export default Modeling;