import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Paging from "../../../components/pagination/pagination";
import { getCookie } from "../../../util/cookie/cookies";
import { CatalogueView } from "../view/catalogueView";
export interface Props {catalogueList : any}
const Catalog:React.FC<Props> = ({catalogueList}) => {
    const lang = getCookie("MARINELANG");
    const {contentsType} = useParams()
    const location = useLocation()
    const [isActive, setIsActive] = useState<any>({active :false, contentId : ''})
    const handleIsOpen = (data:any) => {
        setIsActive({
            ...isActive, active : true, contentId : data?.promIdx
        })
    }
    const handleClose = () => {
        setIsActive({...isActive, active:false, contentId : ''})
    }
    
    return(
        <>
        <ul className="listType_all listType_quartered listType_c">
            {catalogueList.map((data:any) => {
                return(
                <li key={data?.promIdx} onClick={()=>handleIsOpen(data)}>
                    <div>
                        <div className="scaleImgBox">
                            {catalogueList && <img src={`https://marineplaza.org${data?.promThumnailImage}`} alt="contents_list"/>}
                        </div>

                        <div className="bottomBox">
                            <div className="contentSaveBox_circle">
                                <button className="contentSaveBtn">
                                    <span></span>
                                </button>
                                <span>콘텐츠 저장하기</span>
                            </div>
                            <p>
                                {lang === 'kr' ? 
                                <span>{data?.companyNameKr?.length <= 27 ? data?.companyNameKr : data?.companyNameKr?.slice(0,27)+"..."}</span> :
                                <span>{data?.companyNameEn?.length <= 27 ? data?.companyNameEn : data?.companyNameEn?.slice(0,27)+"..."}</span>
                                }
                                {lang==='kr' ? data?.promSubjectKr : data?.promSubjectEn}
                            </p>
                        </div>
                    </div>
                </li>
                )
            })}
        </ul>
        {isActive.active && <CatalogueView contentId={isActive.contentId} onClose={handleClose}/>}
        </>
    )
}

export default Catalog;