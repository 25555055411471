import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FileDownLoad } from "../../../../../../components/modeling/file_download";
import { useGetAssembleTroubleMutation } from "../../../../../../store/slices/modeling/productApiSlice";
import PageStyles from '../../../css/page.module.css'

export const Trouble = () => {
    const {modelingId} = useParams()
    const [getAssembleTrouble, {data:trouble,isSuccess:success}]=useGetAssembleTroubleMutation()
    const [troubleData, setTroubleData] = useState<{kr:any,en:any}>({kr:[],en:[]})
    useEffect(()=>{getAssembleTrouble({modelingId})},[])
    useEffect(()=>{
        if(success){
            setTroubleData({...troubleData, kr:trouble?.list?.filter((item:any)=>item.lang==='KR'), en:trouble?.list?.filter((item:any)=>item.lang==='EN')})
        }
    },[success])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_5, PageStyles.active].join(" ")}>
            <div className={PageStyles.troubleBox}>
                <div className={PageStyles.trouble_kr_box}>
                    <div className={PageStyles.trouble_fileBox}>
                    {troubleData?.kr?.map((trouble : {idx:string, lang:string, filename:string, url:string})=>(
                        <div className={PageStyles.catalogDown}>
                            <FileDownLoad fileName={trouble.filename} fileUrl={trouble.url}/>
                        </div>
                    ))}
                        
                    </div>
                </div>
                {/* <div className="trouble_en_box">
                    <div className="trouble_fileBox">
                    </div>
                </div> */}
            </div>
        </div>
        </>
    )
}