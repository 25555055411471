import React, { useEffect, useRef, useState } from "react";
import PageStyles from '../../css/page.module.css'
import { PopupHeader } from "../header";
import { Catalogue } from "./tab/catalogue";
import { Memo } from "./tab/memo";
import { Performance } from "./tab/performance";
import { Spec } from "./tab/spec";
import { Trouble } from "./tab/trouble";
import { Video } from "./tab/video";
export const menuList = [{idx : 1, title : "제품사양"},{idx : 2, title : "홍보영상"},{idx : 3, title : "카탈로그"},{idx : 4, title : "제품실적"},{idx : 5, title : "트러블슈팅"},{idx : 6, title : "제품메모"},{idx : 7, title : "관리자 로그"}];
export interface Props{onClose:Function}
export const ModelingProduct:React.FC<Props> = ({onClose}) => {
    const [subPageIdx, setSubPageIdx] = useState<number>(1);
    return(
        <>
        <div className={[PageStyles.popUp_1, PageStyles.active].join(" ")}>
            <PopupHeader menuList={menuList} setPageIdx={setSubPageIdx} onClose={onClose}/>
            <div className={PageStyles.contentBox}>
                {subPageIdx === 1 ? <Spec/> : ''}
                {subPageIdx === 2 ? <Video/> : ''}
                {subPageIdx === 3 ? <Catalogue/> : ''}
                {subPageIdx === 4 ? <Performance/> : ''}
                {subPageIdx === 5 ? <Trouble/> : ''}
                {subPageIdx === 6 ? <Memo/> : ''}
            </div>
        </div>
        </>
    )
}