import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Slick } from "../../../../../../components/slick/modeling/slick";
import { useGetAssemblePerformanceMutation } from "../../../../../../store/slices/modeling/productApiSlice";
import PageStyles from '../../../css/page.module.css'

export const Performance = () => {
    const {modelingId} = useParams();
    const [getAssemblePerformance, {data:performance, isSuccess:success}] = useGetAssemblePerformanceMutation()
    const [slick, setSlick] = useState<any>({isOpen:false,image:[]})
    const handleSlickOpen = (image:any) => {setSlick({...slick, isOpen:true, image : image})}
    const handleSlickClose = () => {setSlick({...slick, isOpen:false, image : []})}
    useEffect(()=>{getAssemblePerformance({modelingId})},[])

    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_4, PageStyles.active].join(" ")}>
            <ul className={PageStyles.img_viewBox} id="performance_image_view">
                {performance?.list?.map((performance:{idx:string;image_file:any;title_en:string;title_kr:string})=>(
                <li key={performance.idx} onClick={()=>handleSlickOpen(performance.image_file)}>
                    <div className={PageStyles.thumbBox}>
                        <div className={PageStyles.top}>
                            <img src={performance?.image_file[0]?.performance_image_pdsFilename} alt=""/>
                        </div>
                        <div className={PageStyles.bottom}>
                            <p>{performance?.title_kr}</p>
                        </div>
                    </div>
                </li>		
                ))}
            </ul>
            {slick?.isOpen&&
            <>
            <div className={PageStyles.product_performance_sliderBG} onClick={handleSlickClose}>
                {/* <div className={PageStyles.zoomBox} id="performance_detail_views"></div> */}
            </div> 
            <div className={PageStyles.product_performance_sliderWrap}>
                <button className={PageStyles.product_performance_closeBtn} onClick={handleSlickClose}></button>
                <Slick image={slick.image}/>
            </div>
            </>
            }

            {/* <div className={PageStyles.none_content}>
                <p>
                    <span>아직 등록된 콘텐츠가 없습니다.</span>
                    해당 정보가 필요할 경우 문의주세요.
                </p>
                <button type="button">문의하기</button>
            </div> */}
        </div>
        </>
    )
}