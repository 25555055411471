import React, { useState } from "react";
import { TagBox } from "../../../components/contents/tagBox";
import Paging from "../../../components/pagination/pagination";
import { getCookie } from "../../../util/cookie/cookies";
import VideoView from "../view/videoView";
export interface Props {videoList:any}
const Video:React.FC<Props> = ({videoList}) => {
    const lang = getCookie("MARINELANG")
    const [view , setView] = useState<any>({isActive : false, videoUrlkr : '', videoUrlEn : '', subjectKr : ''});
    const viewClose = () => {setView({isActive : false, videoUrlKr : '' , videoUrlEn : '', subjectKr : ''})}
    const handleOpenVideo = (data: any) => {
        setView({
            isActive : true, videoUrlKr : data.promVideoUrlKr, videoUrlEn : data.promVideoUrlEn, subjectKr : data.promSubjectKr
        })
    }
    return(
        <>
        <ul className="listType_all listType_3equalParts listType_b">
            {videoList?.map((data:any)=>{
                return(
                    <>
                    <li className="videoList" key={data.promIdx}>
                        {/* <span>AD</span> */}
                        <div>
                            <div className="scaleImgBox videoThumbBox" onClick={()=>handleOpenVideo(data)}>
                                <img src={`https://marineplaza.org${data.promThumnailImage}`} alt="contents_list"/>
                            </div>

                            <div className="bottomBox">
                                <div className="contentSaveBox_circle">
                                    <button className="contentSaveBtn">
                                        <span></span>
                                    </button>
                                    <span>콘텐츠 저장하기</span>
                                </div>
                                <p onClick={()=>handleOpenVideo(data)}>{lang === 'kr' ? data?.promSubjectKr : data.promSubjectEn}</p>
                                <div className="hashTagBox">
                                    <p>
                                    <TagBox type={'209'} tags={data?.promTags}/>
                                    </p>
                                </div>
                                <div className="dateBox">
                                    <div>
                                        <span className="icon" style={{backgroundColor:data?.companyBrandColor}}>
                                            <img src={`https://marineplaza.org${data.companyLogoImage}`} alt=""/>
                                        </span>
                                        {lang === 'kr' ? 
                                        <span>{data?.companyNameKr?.length <= 27 ? data?.companyNameKr : data?.companyNameKr?.slice(0,27)+"..."}</span> :
                                        <span>{data?.companyNameEn?.length <= 27 ? data?.companyNameEn : data?.companyNameEn?.slice(0,27)+"..."}</span>
                                        }
                                    </div>
                                    <span className="date">{data?.promCreationDate}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    </>
                )
            })}
        </ul>

        {view.isActive ? 
        <VideoView videoUrlkr={view.videoUrlKr} subjectKr={view.subjectKr} onClose={viewClose}/> 
        : ''}
        
        </>
    )
}

export default Video;