import React from "react";
import PageStyles from '../../../css/page.module.css'

export const Forum = () => {

    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_5, PageStyles.active].join(" ")}>					
            <div className={PageStyles.forums}>
                <table>
                    <thead>
                        <tr>
                            
                            <th>
                                <div className={PageStyles.favorite}>
                                    <input type="checkbox" name="" id="favorite_all"/>
                                    <label htmlFor="favorite_all"></label>
                                </div>
                            </th>
                            <th className={PageStyles.post}>
                                <span className={PageStyles.sortBox}>
                                    Post
                                    <span className={[PageStyles.sort, PageStyles.desc].join(" ")}></span>
                                </span>
                            </th>
                            <th>
                                <span className={PageStyles.sortBox}>
                                    Replies
                                    <span className={[PageStyles.sort, PageStyles.desc].join(" ")}></span>
                                </span>
                            </th>
                            <th>
                                <span className={PageStyles.sortBox}>
                                    Author
                                    <span className={[PageStyles.sort, PageStyles.desc].join(" ")}></span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className={PageStyles.favorite}>
                                    <input type="checkbox" name="" id="favorite"/>
                                    <label htmlFor="favorite"></label>
                                </div>
                            </td>
                            <td>
                                <span>How vessel body bears the stress?How vessel body bears the stress?How vessel body bears the stress?How vessel body bears the stress?How vessel body bears the stress?How vessel body bears the stress?How vessel body bears the stress?How vessel body bears the stress?How vessel body bears the stress?How vessel body bears the stress?</span>
                            </td>
                            <td>
                                <span>1K</span>
                            </td>
                            <td className={PageStyles.author}>
                                <div>
                                    <span></span>
                                    <div>
                                        <span>Name</span>
                                        <span>Jul 9, 2023</span>
                                    </div>

                                    <div className={PageStyles.profileThumbBox}>
                                        <div>
                                            <button className={PageStyles.closeBtn}>
                                                <i className="fa-light fa-xmark"></i>
                                            </button>
                                        </div>
                                        <div className={PageStyles.profileImgBox}>
                                            <div>
                                                <img src="/assets/images/modelingCtrl/forums_profile.png" alt="사용자 프로필 이미지"/>
                                            </div>
                                        </div>
                                        <div className={PageStyles.profileBox}>
                                            <span>Beak Jihee</span>
                                            <div>
                                                <span>Posts <span>24</span></span>
                                                <span>Replies <span>1,208</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}