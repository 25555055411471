import React from "react";

const LegalNotice = () => {

    return(
        <>
        <div className="legal_notice etc active">
            <h3>책임의 한계와 법적고지</h3>
            <div>
                <div>
                    <h4>제1조 (목적)</h4>
                    <p>
                        마린플라자(이하 “회사”)가 제공하는 웹사이트 서비스(이하 “서비스”)의 서비스를 이용하는 회원 혹은 비회원을 포함하는 서비스 이용자(이하 “이용자”)에게 제공할 때 다음과
                        같은 내용으로 그 법적인 책임의 한계를 고지합니다.
                    </p>
                </div>
                <div>
                    <h4>제2조 (서비스 운영 책임)</h4>
                    <p>
                        마린플라자 웹사이트를 이용자가 이용함에 있어, 회사는 링크, 다운로드, 광고 등을 포함하여 서비스에 포함되어 있거나, 서비스를 통해 배포 전송되거나, 서비스로 부터 얻게 되는
                        모든 정보(이하 "콘텐츠")중 서비스와 관련된 광고, 기타 정보, 이용자의 요청으로 게시된 콘텐츠의 정확성이나 신뢰성에 대해 어떠한 보증도 하지 않으며, 콘텐츠의 정확성,
                        저작권 준수 여부, 적법성 또는 도덕성에 대해 아무런 책임을 지지 않습니다.
                    </p>
                    <p>
                        웹사이트는 콘텐츠와 서비스를 "있는 그대로"제공하며, 서비스 또는 기타 콘텐츠 및 광고와 관련하여 상품성, 특정 목적에의 적합성에 대한 보증을 포함하되 이에 제한되지 않고
                        모든 명시적 또는 묵시적인 보증을 명시적으로 부인합니다. 어떠한 경우에도 서비스, 콘텐츠 및 광고와 관련하여 직접, 간접, 부수적, 징벌적, 파생적인 손해에 대해서 책임을 지지
                        않습니다.
                    </p>
                    <p>
                        본 서비스에서 이용자가 요청하여 게시된 콘텐츠 중에는 이용자의 개인적인 판단에 따라 불쾌감 또는 피해를 주는 내용이 있을 수 있으며, 본 서비스를 통하여 일부 이용자들이
                        불쾌하거나 부적절하다고 여기는 정보가 포함되어 있는 사이트로 연결될 수도 있습니다.  웹사이트 내에서 이용자가 요청하여 업로드 된 콘텐츠는 여하한 정보 및 이와 관련된
                        광고, 기타 정보 또는 제안의 결과로서 취득하게 되는 어떠한 재화와 용역에 대하여도 그것의 가치, 용도, 광고 및 기타 관계법령 준수 등에 대한 일체의 보증을 하지 않습니다.
                        이용자는 콘텐츠에 대한 신뢰 여부가 전적으로 이용자의 책임임을 인정합니다.
                    </p>
                    <p>
                        회사는 이용자가 게시 요청하는 콘텐츠와 관련하여 저작권법 등 지적재산권에 관한 법률, 전자거래기본법 및 기타 관계법령의 준수여부에 대하여 어떠한 책임도 지지 않으며,
                        오직 이용자 본인에게 책임이 있음을 알려드립니다. 어떠한 경우에도 회사는 이용자가 게시한 자료와 관련하여 직접, 간접, 부수적, 징벌적, 파생적인 손해 등 명목여부를 불문하고
                        여하한 손해에 대해서도 책임을 지지 않습니다.
                    </p>
                    <p>
                        회사는 필요한 경우 이용자가 게시 요청하는 콘텐츠가 타인의 저작권 등을 침해하였음이 확인된 경우 등에는 게시물 삭제 등의 조치를 취할 수 있습니다. 서비스를 통해 제공된
                        자료 및 정보에 대한 신뢰 여부의 책임은 사용자에게 있으며, 회사는 서비스 및 콘텐츠의 내용을 수정할 의무를 지지 않으나, 필요에 따라 개선할 수는 있습니다.
                    </p>
                    <p>
                        이용자가 서비스 내에 게시 요청한 자료의 저작권은 이용자 본인에게 있으며, 회사가 게시한 자료의 저작권은 그 출처를 따릅니다. 상기의 자료들은 저작권법에 의해 보호를 받고
                        있으며, 일부 혹은 전체의 내용을 회사나 이용자의 동의 없이 무단으로 복제, 배포, 게시를 하는 행위는 저작권법에 위배됩니다. 다만, 카탈로그, 동영상, 3D 모델링 등 회사소개와
                        관련한 자료의 경우 배포와 게시를 목적으로 하는 것에 원작자의 동의를 득한것으로 콘텐츠 자체의 게시나 배포 행위는 저작권법 위배와 관련이 없습니다.
                    </p>
                    <p>
                        회사는 필요하다고 인정되는 경우 본 책임한계와 법적고지의 내용을 인터넷산업의 상관례에 맞추어 적절한 방법으로 사전 통지 없이 수시로 변경할 수 있으며, 변경된 내용은
                        웹사이트에 게시하거나 기타의 방법으로 이용자에게 공시합니다. 이용자는 변경된 고지내용에 동의하지 않으면 서비스 이용을 중단할 수 있습니다.
                    </p>
                    <p>회사는 지적재산권을 포함한 타인의 권리를 존중하며, 이용자들도 마찬가지로 행동해 주시기를 기대합니다.</p>
                </div>
                <div>
                    <h4>제3조 (책임의 한계와 법적고지의 변경)</h4>
                    <p>
                        회사는 필요하다고 인정되는 경우 본 책임한계와 법적고지의 내용을 인터넷산업의 상관례에 맞추어 적절한 방법으로 사전 통지 없이 수시로 변경할 수 있으며, 변경된 내용은
                        웹사이트에 게시하거나 기타의 방법으로 이용자에게 공시합니다. 이용자는 변경된 고지내용에 동의하지 않으면 서비스 이용을 중단할 수 있습니다.
                    </p>
                </div>
                <div>
                    <h4>제4조 (책임의 한계와 법적고지의 효력)</h4>
                    <p>
                        본 책임한계와 법적고지 사항에서 다루고 있는 세부사항들은 관계당사자들간의 총체적인 합의 사항이며, 웹사이트의 개별 서비스에서 정하고 있는 별도의 약관, 고지사항 등과
                        상충하는 경우에는 별도의 약관 또는 고지사항을 우선 적용합니다.
                    </p>
                </div>
            </div>
        </div>
        </>
    )
}

export default LegalNotice;