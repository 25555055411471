import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../util/cookie/cookies";
export interface Props {data : any}
const Section3:React.FC<Props> = ({data}) => {
    const lang = getCookie('MARINELANG')
    const navi = useNavigate()
    const todayPickNews = data?.newsPick[0];
    const handleTransView = (idx:string)=>{
        navi(`/contents/news/207/${idx}`)
    }
    return(
        <>
        <section className="main_section main_section3 colorBG">
            <div>
                <div className="titleBox">
                    <h2>TODAY'S <span>ISSUE</span></h2>
                    <a onClick={()=>navi('/contents/207')} style={{cursor : 'pointer'}}>more view</a>
                </div>
                <div className="issueWrap">
                    <div className="todayIssueBox" onClick={()=>handleTransView(todayPickNews?.promIdx)}>
                        <div>
                            {/* <!-- 리스트 우측 상단 글씨(현재는 없어도 됨. 광고가 붙을 시 생김) --> */}
                            <div>
                                <div className="scaleImgBox">
                                    <img src={`https://marineplaza.org${todayPickNews?.promThumnailImage}`} alt="contents_list"/>
                                </div>
                                {/* <!-- hover시 커지는 이미지 박스 --> */}

                                <div className="bottomBox">
                                    <div className="contentSaveBox_circle">
                                        <button className="contentSaveBtn">
                                            <span></span>
                                        </button>
                                        <span>콘텐츠 저장하기</span>
                                        {/* <!-- hover 시 보이는 버튼, 툴팁 --> */}
                                    </div>
                                    {/* <!-- 콘텐츠저장 버튼 박스 --> */}
                                    <a href="/contents/marineNews_view.html">{todayPickNews?.promSubjectKr}</a>
                                    {/* <!-- 제목 - 말줄임처리(css) --> */}
                                    <p>
                                        {todayPickNews?.promExcerptKr?.length < 120 ? todayPickNews?.promExcerptKr : todayPickNews?.promExcerptKr?.slice(0, 120)+"..."}
                                    </p>
                                    {/* <!-- 텍스트 2줄이상일때 말줄임 --> */}
                                </div>
                                {/* <!-- 이미지 아래 흰배경 박스 --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- leftBox --> */}
                    
                    <div className="issueBox">
                        <h3>Hot Issue</h3>
                        <ul>
                            {data?.newsMostView?.map((data:any) => {
                                return(
                                    <li onClick={()=>handleTransView(data.promIdx)} key={data?.promIdx}>
                                        <a href=""></a>
                                        {/* <img src={data.promThumnailImage} alt="hot issue"/> */}
                                        <img src={`https://marineplaza.org${data.promThumnailImage}`} alt="contents_list"/>
                                        <div>
                                            <div className="main_contentSaveBox">
                                                <button className="main_contentSaveBtn">
                                                    <span></span>
                                                </button>
                                                {lang === 'kr' ? 
                                                <span>콘텐츠 저장하기</span> :
                                                <span>Save Contents</span>
                                                }
                                            </div>
                                            <h4>{data.promSubjectKr}</h4>
                                            <p>
                                                {data?.promExcerptKr?.length < 85 ? data?.promExcerptKr : data?.promExcerptKr?.slice(0, 85)+"..."}
                                            </p>
                                            {/* <!-- 두 줄 이상일 때 말줄임 처리 필요 --> */}
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    
                    <div className="issueBox">
                        <h3>Marine Pick</h3>
                        <ul>
                            {data?.newsLatest?.map((data:any)=>{
                                return(
                                <li onClick={()=>handleTransView(data.promIdx)} key={data?.promIdx}>
                                    <a href=""></a>
                                     {/* <img src={data.promThumnailImage} alt="hot issue"/> */}
                                     <img src={`https://marineplaza.org${data.promThumnailImage}`} alt="contents_list"/>
                                    <div>
                                        <div className="main_contentSaveBox">
                                            <button className="main_contentSaveBtn">
                                                <span></span>
                                            </button>
                                            {lang === 'kr' ? 
                                            <span>콘텐츠 저장하기</span> :
                                            <span>Save Contents</span>
                                            }
                                        </div>
                                        <h4>{data.promSubjectKr}</h4>
                                        <p>
                                            {data?.promExcerptKr?.length < 85 ? data?.promExcerptKr : data?.promExcerptKr?.slice(0, 85)+"..."}
                                        </p>
                                        {/* <!-- 두 줄 이상일 때 말줄임 처리 필요 --> */}
                                    </div>
                                </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Section3;