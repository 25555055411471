import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetAssembleMemoMutation } from "../../../../../../store/slices/modeling/productApiSlice";
import PageStyles from '../../../css/page.module.css'

export const Memo = () => {
    const {modelingId} = useParams()
    const [getAssembleMemo, {data:memo,isSuccess:success}]= useGetAssembleMemoMutation()
    const [memoData, setMemoData] = useState<any>([])
    useEffect(()=>{getAssembleMemo({modelingId})},[])
    useEffect(()=>{
        if(success){setMemoData(memo?.list?.filter((item:any)=>item.open_yn==='Y'))}
    }, [success])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_6, PageStyles.active].join(" ")}>
            <div className={PageStyles.memoBox}>
                <ul>
                    {memoData?.map((memo:{idx:string,last_kr:string,first_kr:string,memo_contents:string,memo_mdata:string,open_yn:string})=>{
                        return(
                        <li key={memo.idx}>
                            <div className={PageStyles.top}>
                                <div className={PageStyles.textArea}>
                                    {memo.memo_contents}
                                </div>
                            </div>
                            <div className={PageStyles.bottom}>
                                <div>
                                    <span className={PageStyles.name}>{memo.last_kr}{memo.first_kr}</span>
                                    <span className={PageStyles.date}>{memo.memo_mdata}</span>
                                </div>
                            </div>
                        </li>
                        )
                    })}
                </ul>
            </div>
        </div>
        </>
    )
}