import React from "react";
import PageStyles from '../../pages/contents/modeling/css/page.module.css'
export interface Props {title:string,name:string,placeHolder:string,onChange:Function}
export const InquiryInput:React.FC<Props> = ({title,name,placeHolder,onChange}) => {

    return(
        <>
        <li>
            <label htmlFor="">{title}</label>
            <input type="text" className={PageStyles.customInput} name={name} id="" placeholder={placeHolder} onChange={(e)=>onChange(e)}/>
        </li>
        </>
    )
}