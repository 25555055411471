import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetContentsBySupplierMutation } from "../../../../../store/slices/modeling/modelingApiSlice";
import PageStyles from '../../css/page.module.css'
import { PopupHeader } from "../header";
import { Forum } from "./tab/forum";
import { NewsAndVideo } from "./tab/newsAndVideos";
import { TechAndCatalogue } from "./tab/techAndCatalogue";
export const menuList = [{idx:1,title:'Marine News'},{idx:2,title:'Catalogue'},{idx:3,title:'Video'},{idx:4,title:'Tech.Document'},{idx:5,title:'Forums'}]
export interface Props{onClose:Function}
export const ModelingContents:React.FC<Props> = ({onClose}) => {
    const {companyId} : any = useParams()
    const [getContentsBySupplierApi, {data, isSuccess, isError}] = useGetContentsBySupplierMutation()
    const [pageIdx,setPageIdx]=useState<number>(1)
    useEffect(()=>{getContentsBySupplierApi({companyId : companyId})}, [])
    return(
        <>
        <div className={[PageStyles.mpContent_popUp, PageStyles.active].join(" ")}>

            <PopupHeader menuList={menuList} setPageIdx={setPageIdx} onClose={onClose}/>

            <div className={PageStyles.contentBox}>
                {pageIdx===1?<NewsAndVideo name={'news'} data={data?.marineNewsList}/> : ''}
                {pageIdx===2?<TechAndCatalogue name={'catalogue'} data={data?.catalogueList}/> : ''}
                {pageIdx===3?<NewsAndVideo name={'video'} data={data?.videosList}/> : ''}
                {pageIdx===4?<TechAndCatalogue name={'tech'} data={data?.techList}/> : ''}
                {pageIdx===5?<Forum/> : ''}
            </div>
        </div>
        </>
    )
}