import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TagBox } from "../../../components/contents/tagBox";
import { getCookie } from "../../../util/cookie/cookies";
export interface Props {onChange:Function, data:any}
const Section2:React.FC<Props> = ({onChange, data}) => {
    const lang = getCookie('MARINELANG')
    const navi = useNavigate()
    const isActiveRef = useRef<any[]>([]);
    const menuList = [{idx: 212, title: 'Ship Hull'}, {idx: 213, title: 'Ship Fitting'}, {idx: 214, title: 'Ship Engine'}, {idx: 215, title: 'Ship Electronic'}, {idx: 232, title: 'Ship Machinery'}];
    const [selMenu, setSelMenu] = useState<number>(212);

    const handlePic = (data:{idx:number, title:string}) => {
        setSelMenu(data.idx)
        onChange(data.idx)
        if (isActiveRef.current[data.idx].classList.contains('active') && data.idx === selMenu) {
            return;
        }
        if(isActiveRef.current[data.idx].classList.contains('active')){
            isActiveRef.current?.[data.idx].classList.remove('active');
            return;
        }
        Object.values(isActiveRef.current).forEach((isActiveRef: any) => {
            isActiveRef?.classList.remove('active');
        });
        isActiveRef.current?.[data.idx].classList.add('active');
    }

    const goModelingView = (e:any, data: any) => {
        e.preventDefault();
        const formUrl = data?.promModelingPath?.split('/')
        const urlName = formUrl[formUrl?.length - 1].split('.')[0]
        window.open(`/modeling/206/${data?.promIdx}/${data?.promModelingId}/${data?.promCompanyId}/${urlName}`, '_blank')
    }

    // init active : first List
    useLayoutEffect(()=>{
        if (isActiveRef.current[212]) {
            isActiveRef.current[212].classList.add('active');
        }
    }, [])

    return(
        <>
        <section className="main_section main_section2">
            <div className="titleBox">
                {lang === 'kr' ? 
                <h2>바이어를 위한 <span>3D 자산</span></h2>:
                <h2> <span>3D MODELING</span> FOR BUYERS</h2>
                }
                <a onClick={()=>navi(`/contents/206`)} style={{cursor:'pointer'}}>more view</a>
            </div>

            <div className="tabBox">
                <div className="tabBtnWrap">
                    <ul className="tabBtnBox">
                        {menuList?.map((data:{idx:number,title:string}) => (
                            <li className="" ref={(e)=>isActiveRef.current[data.idx]=e} key={data.idx} onClick={()=>handlePic(data)}>{data.title}</li>
                        ))}
                        
                    </ul>
                </div>
                {/* <!-- 탭 버튼 박스 --> */}
                <ul className="listType_all listType_quartered listType_a active">
                    {data?.modeling?.map((data : any) => (
                        <li key={data.promIdx}>
                            <a href="#" onClick={(e)=>goModelingView(e,data)}></a>
                            <div className="modelingNameWrap">
                                <div className="contentSaveBox">
                                    <button className="contentSaveBtn">
                                        <span></span>
                                    </button>
                                    <span>콘텐츠 저장하기</span>
                                </div>
                                {/* <!-- hover 시 보이는 버튼, 툴팁 --> */}
                                <div className="modelingNameBox">
                                    <span>{data?.companyNameEn}</span>
                                    <div className="hashTagBox">
                                        <p><TagBox tags={data?.promTags} type={'206'}/></p>
                                    </div>
                                </div>
                                {/* <!-- hover시 보이는 제목 --> */}
                            </div>
                            {/* <!-- hover시 배경색을 가지고 나타나는 박스 --> */}
                            <img src={`https://marineplaza.org${data?.promThumnailImage}`} alt="contents_list"/>
                        </li>
                    ))}
                </ul>
                {/* <!-- 탭 버튼 누를때마다 ul에 active 클래스 부여 --> */}
            </div>

        </section>
        </>
    )
}

export default Section2;