import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SupplierInfo } from "./supplier-contents/supplier-info";
import { useGetPromotionDetailMutation, useGetSupplierDetailMutation } from "../../../store/slices/contents/contentsApiSlice";
import { SupplierContents } from "./supplier-contents/supplierContents";
import { ModelingMoreUnit } from "../../../components/contents/supplier-contents/modeling-unit";
import { WidthMoreUnit } from "../../../components/contents/supplier-contents/width-unit";
import { HeightMoreUnit } from "../../../components/contents/supplier-contents/height-unit";
import { useGetSupplierContentsMoreMutation } from "../../../store/slices/contents/supplierContentsApiSlice";
import Paging from "../../../components/pagination/pagination";

const SupplierView = () => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search)
    const content = searchParam.get('content')
    const page :any = searchParam.get("page") || 1;
    const path = location.pathname.split('/')
    const navigate = useNavigate()
    const {contentsType,supplierId,tab} = useParams();
    const navi = useNavigate();
    const [getSupplierDetailApi, {data , isSuccess, isError}] = useGetSupplierDetailMutation()
    console.log(data?.list[0]?.companyId)
    const [getSupplierContentsMoreApi, {data : moreData, isSuccess : success, isError : error}] = useGetSupplierContentsMoreMutation()
    const goBack = (e:React.MouseEvent) => { e.preventDefault(); navigate('/contents/211')}
    const tabChange = (tab : string) => {
        if(tab==='I'){navigate(`/contents/supplier/${contentsType}/${supplierId}/I`)}
        else {navigate(`/contents/supplier/${contentsType}/${supplierId}/C`)}
    }
    const handlePage = (pageNum : number) => {
        navigate(`/contents/supplier/${contentsType}/${supplierId}/C?content=${content}&page=${pageNum}`)
    }
    useEffect(()=>{getSupplierDetailApi({supplierId})}, [])
    const param = {companyId : supplierId, contentsType : content, page : page, size : 6}
    useEffect(()=>{
        if(content){getSupplierContentsMoreApi(param)}
    }, [content, param.page])
    console.log(moreData)
    return(
        <>
        <div className="board_wrap supplierView_wrap">
            <h2>supplier</h2>
            <div className="top">
                <a href="" className="board_backBtn" onClick={goBack}>Back</a>
                <div>
                    <div className="dateBox">
                        <span>Company: </span>
                        <span className="companyName">{data?.list[0]?.companyNameEn.length <= 20 ? data?.list[0]?.companyNameEn : data?.list[0]?.companyNameEn?.slice(0,20)+"..."}</span>
                    </div>
                    <div className="contentSaveBox">
                        <button className="contentSaveBtn">
                            <span></span>
                        </button>
                        <span>콘텐츠 저장하기</span>
                    </div>
                </div>
            </div>

            <div className="contents">
                <div className="imgBox">
                    <img src={`https://marineplaza.org${data?.list[0]?.companyBannerImage}`} alt="contentsView"/>
                    <img src={`https://marineplaza.org${data?.list[0]?.companyLogoImage}`} alt="contentsView" className="supplier_view_logo"/>
                    
                </div>
                <div className="supplierTab_wrap">
                    <div className="supplierTab_BtnBox">
                        <button className={path[5] === 'I' ? 'active' : ''} onClick={()=>tabChange('I')}>Information</button>
                        <button className={path[5] === 'C' ? 'active' : ''} onClick={()=>tabChange('C')}>Content</button>
                    </div>
                    <div className='supplierTabBox'>
                        <div className={path[5] === 'I'? 'supplierTab_information active' : 'supplierTab_information'}>
                            <SupplierInfo supplier={data?.list[0]}/>
                        </div>

                        <div className={path[5] === 'C' ? 'supplierTab_contents active' : 'supplierTab_contents'}>
                            {content===null?<SupplierContents data={data}/> : ''}
                            {content==='206'? <ModelingMoreUnit/> : ''}
                            {content==='207'?<WidthMoreUnit name={'Marine News'} data={moreData?.list}/> :''}
                            {content==='210'?<HeightMoreUnit name={'Tech. Document'} data={moreData?.list}/> :''}
                            {content==='209'?<WidthMoreUnit name={'Videos'} data={moreData?.list}/> :''}
                            {content==='208'?<HeightMoreUnit name={'Catalogues'} data={moreData?.list}/> :''}
                            {content &&
                            <div className="pagerBox">
                                <div>
                                    <Paging page={page} size={param?.size} setPage={handlePage} totalPage={moreData?.totalCnt}/>
                                </div>
                                <button className="backBtn" onClick={()=>navigate(`/contents/supplier/${contentsType}/${supplierId}/C`)}>Back</button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
            </div>
        </div>
        </>
    )
}

export default SupplierView;