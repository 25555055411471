import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VideoView from "../../../pages/contents/view/videoView";
import { TagBox } from "../tagBox";
import { MoreBtn } from "./more-btn";
export interface Props {name : string; data : any}
export const WidthUnit:React.FC<Props> = ({name, data}) => {
    const navigate = useNavigate()
    const [viewData, setViewData] = useState<any>({
        isActive : false, videoUrlKr : '', videoUrlEn : '', videoSubjectEn : ''
    })
    const handleTransView = (list:any) => {
        if(name === 'Marine News'){navigate(`/contents/news/207/${list?.promIdx}`)}
        else {
            setViewData({
                ...viewData, isActive : true, videoUrlKr: list?.promVideoUrlKr, videoUrlEn: list?.promVideoUrlEn, videoSubjectEn : list?.promSubjectEn
            })
        } // 비디오 보여주기
    }
    const viewClose = () => {setViewData({isActive : false, videoUrlKr : '' , videoUrlEn : '', subjectKr : ''})}
    const type = name==='Marine News' ? '207' : '209'
    return(
        <>
        <div>
            {data?.length === 0 ? '':<MoreBtn name={name} data={data} displayCnt={2}/>}
            <ul className="listType_all listType_2equalParts listType_b">
                {data?.map((list:any) => (
                    <>
                    <li key={list?.promIdx}>
                        <div>
                            <div className="scaleImgBox" onClick={()=>handleTransView(list)}>
                                <img src={`https://marineplaza.org${list?.promThumnailImage}`} alt="contents_list"/>
                            </div>

                            <div className="bottomBox">
                                <div className="contentSaveBox_circle">
                                    <button className="contentSaveBtn">
                                        <span></span>
                                    </button>
                                    <span>콘텐츠 저장하기</span>
                                </div>
                                <a onClick={()=>handleTransView(list?.promIdx)}>{list?.promSubjectKr}</a>
                                <div className="hashTagBox">
                                    <p>
                                        <TagBox type={type} tags={list?.promTags}/>
                                    </p>
                                </div>
                                <div className="dateBox">
                                    <div>
                                        <span className="icon" style={{backgroundColor : list?.companyBrandColor}}>
                                            <img src={`https://marineplaza.org${list?.companyLogoImage}`} alt=""/>
                                        </span>
                                        <span>{list?.companyNameKr}</span>
                                    </div>
                                    <span className="date">{list?.promCreationDate}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    
                    </>
                ))}
            </ul>
            {viewData.isActive ? 
            <VideoView videoUrlkr={viewData.videoUrlKr} subjectKr={viewData.videoSubjectEn} onClose={viewClose}/> 
            : ''}
        </div>
        
        </>
    )
}
export interface Props2 {name:string, data: any}
export const WidthMoreUnit:React.FC<Props2> = ({name, data}) => {
    const navigate = useNavigate()
    const [viewData, setViewData] = useState<any>({
        isActive : false, videoUrlKr : '', videoUrlEn : '', videoSubjectEn : ''
    })
    const handleTransView = (list:any) => {
        
        if(name === 'Marine News'){navigate(`/contents/news/207/${list?.promIdx}`)}
        else {
            setViewData({
                ...viewData, isActive : true, videoUrlKr: list?.promVideoUrlKr, videoUrlEn: list?.promVideoUrlEn, videoSubjectEn : list?.promSubjectEn
            })
        } // 비디오 보여주기
    }
    const viewClose = () => {setViewData({isActive : false, videoUrlKr : '' , videoUrlEn : '', subjectKr : ''})}
    const type = name==='Marine News' ? '207' : '208'
    return(
        <>
        <div>
            <div>
                <h4>{name}</h4>
            </div>
            <ul className="listType_all listType_2equalParts listType_b">
                {data?.map((list:any ,index : number) => (
                <li key={list?.promIdx}>
                    <div>
                        <div className="scaleImgBox" onClick={()=>handleTransView(list)}>
                            <a></a>
                            <img src={`https://marineplaza.org${list?.promThumnailImage}`} alt="contents_list"/>
                        </div>

                        <div className="bottomBox">
                            <div className="contentSaveBox_circle">
                                <button className="contentSaveBtn">
                                    <span></span>
                                </button>
                                <span>콘텐츠 저장하기</span>
                            </div>
                            <a onClick={()=>handleTransView(list?.promSubjectEn)}>{list?.promSubjectEn}</a>
                            <div className="hashTagBox">
                                <p>
                                <TagBox type={type} tags={list?.promTags}/>
                                </p>
                            </div>
                            <div className="dateBox">
                                <div>
                                    <span className="icon" style={{backgroundColor : list?.companyBrandColor}}>
                                        <img src={`https://marinePlaza.org${list?.companyLogoImage}`} alt=""/>
                                    </span>
                                    <span>{list?.companyNameEn}</span>
                                </div>
                                <span className="date">{list?.promCreationDate}</span>
                            </div>
                        </div>
                    </div>
                </li>
                ))}
            </ul>
        {viewData.isActive ? 
        <VideoView videoUrlkr={viewData.videoUrlKr} subjectKr={viewData.videoSubjectEn} onClose={viewClose}/> 
        : ''}
        </div>
        </>
    )
}