import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
export interface Props {
    tags : any, type : string
}
export const TagBox:React.FC<Props> = ({tags, type}) => {
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search)

    const tag : any = location.hash.replace('#','')

    const navigate = useNavigate()
    const {contentsType} = useParams()
    const handleTagsList = (tag:string) => {
        // 태그를 누르면 해당 태그 가지고 있는 리스트 페이지로 이동
        const param = {contentsType: type, page: 1,size:12,sortOrder:'desc',sortColumn:'date',keyword:'', tag : tag}
        navigate(`/contents/${type}?page=1&keyword=&sortOrder=desc&sortColumn=date&tags=${tag}`)
    }
    
    return(
        <>
        
        {tags?.split(',').map((tag:any, index : number)=>(
            <a key={index} onClick={()=>handleTagsList(tag)}>{tag}</a>
        ))}
        
        </>
    )
}