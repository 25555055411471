import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import YoutubePlayer from "../../../components/youtube/react-youtube";

// import '../../../components/slick/image_video_slick/image_video_slick.css';
export interface Props {data:any; responsive:boolean}
const Section4:React.FC<Props> = ({data,responsive}) => {
    const navi = useNavigate()
    const sliderRef = useRef<any>();
    const picActiveRef = useRef<any>([]);
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [videoNewsInfo, setVideoNewsInfo] = useState<any>({videoUrl:'',videoCompany:'',videoSubject:'', tags : []})
    const [selectedIdx, setSelectedIdx] = useState<number>(1);

    const videoOption = {
        height: "390",
        width: "640",
        playerVars: {
            autoplay: 0,
        },
    };

    // slick setting
    const setting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,  // 한 번에 보여지는 갯수
        slidesToScroll: 1, // 한 번에 스크롤되는 슬라이드 수
        vertical: responsive === true ? false : true,
        // centerMode: true,
    }
    // prev , next Btn handler
    const handleControl = (direction : string) => {
        if (sliderRef.current) {
            if (direction === 'up') {
              sliderRef.current.slickPrev();
            } else if (direction === 'down') {
              sliderRef.current.slickNext();
            }
        }
    }
    // Slider Up & Down
    const handleSlideChange = (idx : number) => {
        console.log(idx)
        const selectedData = data.videoNews[idx];
        console.log(selectedData)
        if(selectedData){setVideoNewsInfo({...videoNewsInfo, videoUrl : selectedData.promVideoUrlKr, 
        videoSubject : selectedData.promSubjectKr,videoCompany:selectedData.companyNameEn,tags:selectedData.promTags.split(',')})}
    }
    // click Event handler
    const selPic = (data : any, index : number) => {
        console.log(data.promIdx)
        setSelectedIdx(data.promIdx);
        setVideoUrl(data.promVideoUrlKr)
        setVideoNewsInfo({...videoNewsInfo, videoUrl : data.promVideoUrlKr, 
        videoSubject : data.promSubjectKr, videoCompany : data.companyNameEn, tags:data.promTags.split(',')})
        if(sliderRef.current){
            sliderRef.current.slickGoTo(index);
        }
    }
    
    useEffect(()=>{
        if(data?.videoNews?.length > 0){
            const videoData = data.videoNews[0];
            setVideoNewsInfo({...videoNewsInfo, videoUrl : videoData.promVideoUrlKr,
            videoSubject : videoData.promSubjectKr, videoCompany : videoData.companyNameEn, tags:videoData.promTags.split(',')})
        }
    }, [data.videoNews])
    return(
        <>
        <section className="main_section main_section4">
            <div className="titleBox">
                <h2>MARINE <span>VIDEO</span></h2>
                <a onClick={()=>navi(`/contents/209`)} style={{cursor:'pointer'}}>more view</a>
            </div>
            <div className="videoWrap">
                <div className="videoBox">
                    <div className="video">
                        <div>
                            {videoNewsInfo.videoUrl &&
                            <YoutubePlayer url={videoNewsInfo.videoUrl}/>
                            }
                        </div>
                    </div>

                    <h3>
                        {videoNewsInfo.videoSubject}
                        <span>{videoNewsInfo.videoCompany}</span>
                    </h3>
                    <div className="videoBox_bottom">
                        <div className="hashTagBox">
                            {videoNewsInfo.tags.map((tag:any, index :number)=>(
                                <a key={index} onClick={()=>navi(`/contents/209?page=1&keyword=&sortOrder=desc&sortColumn=date&tags=${tag}`)}>
                                    {tag}
                                </a>
                            ))}
                        </div>
                        
                        <div className="main_contentSaveBox">
                            <button className="main_contentSaveBtn">
                                <span></span>
                            </button>
                            <span>콘텐츠 저장하기</span>
                        </div>
                    </div>
                </div>

                <div className="videoListBox">
                    <ul className="videoList">
                        <Slider {...setting} ref={sliderRef} afterChange={handleSlideChange}>
                            {data?.videoNews?.map((data : any, index : number) => (
                                // <li className="active"
                                <li className={data.promIdx === selectedIdx ? 'active' : ''}
                                    ref={(e)=>picActiveRef.current[data.promIdx]=e}
                                    key={data.promIdx} onClick={()=>selPic(data, index)}
                                >
                                    <div className="videoBox_sub">
                                        <span className="check"></span>
                                        <div className="video">
                                            <img src={`https://marineplaza.org${data.promThumnailImage}`} alt="thumbnail"/>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </Slider>
                        
                    </ul>

                    <div className="video_arrowBox">
                        <button className="arrow_up" onClick={()=>handleControl('up')}>
                            <span></span>
                        </button>
                        <button className="arrow_down" onClick={()=>handleControl('down')}>
                            <span></span>
                        </button>
                    </div>
                </div>
                
            </div>
        </section>
        </>
    )
}

export default Section4;


